<template>
  <TheLoader v-if="loading" />
  <ErrorText v-if="!loading && error" textAlign='center' :showBtn="false" :error="error"/>

  <WomanDoctor v-if="!loading && selectedDiagnosis?.length === 0 && !error" :isChat="false" :subtitle="'Врачи еще не поставили вам диагнозов'"/>
  <div v-else>
    <div class="diagnosis__date-selector">
      <span v-for="year in years" :key="year" :class="{ diagnosis__selectedYear: selectedYear === year }"
        @click="selectedYear = year">{{ year }}</span>
    </div>

    <div>
      <div class="diagnosis__item" v-for="(item, index) in selectedDiagnosis" :key="item.date + index">
        <div>{{ formatDateToRod(item.date, selectedYear !== 'все время') }}</div>
        <div>
          <p v-for="desc in item.items" :key="desc.title">{{ desc.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, toRefs, watch } from 'vue'
import { getPatientDiagnosis } from '@/api/medcard/getPatientDiagnosis.js'
import { useUserStore } from '@/stores/UserStore'
import { formatDateToRod } from '@/utilits/formatDateFunctions.js'
import TheLoader from '@/components/ui/TheLoader.vue'
import WomanDoctor from '../chat/WomanDoctor.vue'
import ErrorText from '@/components/ui/ErrorText.vue'

export default {
  name: 'MedcardDiagnosis',
  components: { TheLoader, WomanDoctor, ErrorText },
  setup () {
    const loading = ref(null)
    const error = ref(null)

    const userStore = useUserStore()
    const { getUserId } = toRefs(userStore)
    const diagnosisData = ref(null)
    const selectedYear = ref(null)

    const years = computed(() => {
      if (diagnosisData.value?.length) {
        const onlyYears = diagnosisData.value.map((d) => {
          const date = new Date(d.date)
          return date.getFullYear()
        })

        const uniqueYears = [...new Set(onlyYears)]

        if (uniqueYears.length > 4) uniqueYears.slice(0, 4)

        uniqueYears.sort((a, b) => b - a)

        uniqueYears.push('все время')

        return uniqueYears
      } else {
        return []
      }
    })

    const selectedDiagnosis = computed(() => {
      if (years.value?.length) {
        if (selectedYear.value === 'все время') {
          return diagnosisData.value.toSorted((a, b) => {
            const dateA = new Date(a.date)
            const dateB = new Date(b.date)
            return dateB - dateA
          })
        } else {
          return diagnosisData.value.filter(d => {
            const date = new Date(d.date)
            return date.getFullYear() === selectedYear.value
          }).toSorted((a, b) => {
            const dateA = new Date(a.date)
            const dateB = new Date(b.date)
            return dateB - dateA
          })
        }
      } else {
        return []
      }
    })

    const fetchData = async () => {
      try {
        loading.value = true
        error.value = null
        diagnosisData.value = await getPatientDiagnosis()
      } catch (err) {
        if (err.response.data.message === 'Список диагнозов пуст.') {
          return
        }
        error.value = err
      } finally {
        loading.value = false
      }
    }

    onMounted(async () => {
      await fetchData()
    })

    watch(getUserId, async () => {
      if (getUserId.value) {
        await fetchData()
      }
    })

    watch(years, () => {
      if (years.value?.length) {
        selectedYear.value = years.value[0]
      }
    })

    return {
      error,
      loading,
      years,
      selectedYear,
      selectedDiagnosis,
      formatDateToRod
    }
  }
}
</script>

<style scoped lang="scss">
.diagnosis__date-selector {
  display: flex;
  margin-bottom: 16px;
  gap: 32px;

  span {
    cursor: pointer;
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: $blue-light;

    &.diagnosis__selectedYear {
      font-weight: 700;
      color: $blue;
    }
  }
}

.diagnosis__item {
  display: flex;
  gap: 10px;

  font-size: 13px;
  line-height: 18px;
  text-align: left;
  margin-bottom: 12px;

  div:nth-child(1) {
    font-weight: 700;
    color: $blue;
    min-width: 80px;
  }

  div:nth-child(2) {
    font-weight: 400;
    color: $black;
  }
}
</style>
