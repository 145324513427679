<template>
  <Teleport to="body">
    <Transition name="overlay">
      <div v-if="isDrawerOpen" class="drawer__overlay" @click="closeDrawer">

      </div>
    </Transition>
  </Teleport>

  <Teleport to="body">
    <Transition name="wrapper" appear>
          <div
            ref="drawerWrapper"
            v-touch-options="{ dragFrequency: 1 }"
            v-touch:drag.once="dragHandler"
            v-touch:swipe="(dir, e) => swipeHandler(dir, e)"
            v-touch:press="getStartPos"
            v-touch:release="getEndPos"
            v-if="isDrawerOpen"
            class="drawer__wrapper">
            <div class="drawer__content">
              <div class="gray-ribbon"></div>
              <div class="appointment-menu__title">
                <p v-if="drawerTitle">{{ drawerTitle }}</p>
                <p v-if="drawerSubtitle">{{ drawerSubtitle }}</p>
              </div>
              <MenuPatientList v-if="drawerTypeOpened === 'patients'"/>
              <MenuButtonList v-if="drawerTypeOpened === 'Запись к врачу'" :buttons="mobileMenuButtonsDoctor" />
            </div>
          </div>
        </Transition>
  </Teleport>
</template>

<script>
import { useDrawerStore } from '@/stores/DrawerStore'
import { onMounted, ref } from 'vue'
import MenuPatientList from '../pages/menu/MenuPatientList.vue'
import { storeToRefs } from 'pinia'
import { mobileMenuButtonsDoctor } from '@/constants/mobileMenuButtons'
import MenuButtonList from '../pages/menu/MenuButtonList.vue'
export default {
  name: 'BottomDrawer',
  components: { MenuPatientList, MenuButtonList },
  setup () {
    // нужна плавная анимация для закрытия шторки
    const drawerStore = useDrawerStore()
    const { closeDrawer } = drawerStore
    const { drawerTitle, drawerSubtitle, isDrawerOpen, drawerTypeOpened } = storeToRefs(drawerStore)

    const drawerWrapper = ref(null)
    const startPos = ref(null)
    const endPos = ref(null)
    const direction = ref(null)
    const drawerHeight = ref(null)

    const swipeHandler = (dir, e) => {
      const offsetPxs = endPos.value - startPos.value
      direction.value = dir
      console.log('offsetPxs', offsetPxs)
      if (
        dir === 'bottom' &&
        offsetPxs > 70 &&
        e.target.className === 'drawer__content'
      ) {
        closeDrawer()
      }
    }
    const dragHandler = (e) => {
      const touch = e.changedTouches[0]
      const targetElement = e.currentTarget
      const clientY = touch.clientY
      const startY = startPos.value
      const deltaY = clientY - startY

      // console.log('deltaY', deltaY)
      if (deltaY > -100) {
        const translateY = Math.min(deltaY * 0.8, window.innerHeight * 0.6)
        targetElement.style.transform = `translateY(${translateY}px)`
      }
    }
    const getStartPos = (e) => {
      startPos.value = e.touches[0].clientY
      // console.log('startPos.value', startPos.value)
    }
    const getEndPos = (e) => {
      endPos.value = e.changedTouches[0].clientY
      // console.log('endPos.valuee', endPos.value)
      direction.value = null
      drawerWrapper.value.style.transform = 'translateY(0)'
      drawerWrapper.value.style.transform = ''
    }

    onMounted(() => {
      if (drawerWrapper.value) {
        drawerHeight.value = drawerWrapper.value.offsetHeight
      }
    })

    return {
      drawerWrapper,
      isDrawerOpen,
      drawerTitle,
      drawerSubtitle,
      drawerTypeOpened,
      mobileMenuButtonsDoctor,
      swipeHandler,
      dragHandler,
      getStartPos,
      getEndPos,
      closeDrawer
    }
  }
}
</script>

<style scoped lang="scss">
.drawer__overlay {
  position: fixed;
  background: #00285680;
  backdrop-filter: blur(1px);
  overscroll-behavior: none;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 201;
  overflow-y: hidden;
  overflow-x: hidden;
  touch-action: none;
}

.drawer__wrapper {
  width: 100%;
  height: fit-content;
  z-index: 202;
  position: fixed;
  bottom: 0;
  left: 0;
  overscroll-behavior: none;
  // background: #00285680;
  // backdrop-filter: blur(1px);
  touch-action: none;
  transition: all 0.1s ease;
}

.drawer__wrapper::before {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 90%;
  left: 0;
  z-index: 9999;
  background: #FFF;
  touch-action: none;
}

.drawer__content {
  touch-action: none;
  z-index: 203;
  padding: 34px 16px;
  position: relative;
  max-height: calc(100vh - 80px);
  width: 100%;
  height: fit-content;
  overflow-y: auto;
  box-shadow: 0 5px 15px 0 #00000026;
  background: #ffffff;
  overscroll-behavior: none;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.gray-ribbon {
  position: absolute;
  top: 8px;

  width: 40px;
  height: 4px;
  background-color: #e4e4e4;
  border-radius: 12px;

  transform: translateX(-50%);
  left: 50%;
}

.appointment-menu__title {
  margin-bottom: 16px;

  > p:nth-of-type(1) {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #002856;
  }

  > p:nth-of-type(2) {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #7f8da9;
  }
}

.wrapper-enter-active,
.wrapper-leave-active {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  // transition: bottom 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.wrapper-enter-from {
  transform: translateY(100%);
  // bottom: 30%;
}

.wrapper-enter-to {
  transform: 0;
  // bottom: 0;
}

.wrapper-leave-from {
  transform: 0;
  // bottom: 0;
}

.wrapper-leave-to {
  transform: translateY(100%);
  // bottom: 29%;
}

.overlay-enter-active,
.overlay-leave-active {
  transition: opacity 0.3s ease;
}

.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
}
</style>
