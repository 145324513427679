<script>
import CustomButton from '@/components/ui/buttons/CustomButton'
import AccordionComponent from '../../ui/AccordionComponent.vue'
import ProgramCheckboxList from './ProgramCheckboxList.vue'
import { useAppInfoStore } from '@/stores/AppInfoStore.js'
import { reactive, ref, watch } from 'vue'

export default {
  components: { CustomButton, AccordionComponent, ProgramCheckboxList },
  emits: ['checkupSignup', 'resetError', 'resetCheckup'],
  props: {
    selectedProgramGroups: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const appStore = useAppInfoStore()
    const totalPrice = ref(0)
    const selectedPrograms = ref(null)
    const localProgramGroups = reactive(
      props.selectedProgramGroups.map((p) => ({
        title: p.title,
        id: p.id,
        items: p.items.map((i) => ({
          id: i.id,
          price: i.price,
          title: i.title,
          isCrossed: i.isCrossed,
          isSelected: !i.isCrossed
        }))
      }))
    )

    const selectAll = (id) => {
      localProgramGroups.forEach((pg) => {
        if (pg.id === id) {
          pg.items.forEach((i) => {
            if (!i.isCrossed) {
              i.isSelected = true
            }
          })
        }
      })
    }
    const unSelectAll = (id) => {
      localProgramGroups.forEach((pg) => {
        if (pg.id === id) {
          pg.items.forEach((i) => {
            if (!i.isCrossed) {
              i.isSelected = false
            }
          })
        }
      })
    }

    const getDiscountObj = (sum) => {
      const sortedDiscounts = appStore.appInfo.checkup_discount.toSorted(
        (a, b) => a.min - b.min
      )
      let discountObj = { discount: 0, sum }
      sortedDiscounts.forEach((d, idx, arr) => {
        // если следующий дискаунт подходит для суммы
        const isSmallerThanNext = arr[idx + 1] ? sum < arr[idx + 1].min : true
        if (sum >= d.min && isSmallerThanNext) {
          const factor = 1 - d.discount / 100
          discountObj = { discount: d.discount, sum: sum * factor }
        }
      })

      return discountObj
    }

    const numberWithSpaces = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }

    watch(
      () => localProgramGroups,
      () => {
        const prices = []
        emit('resetError')
        selectedPrograms.value = localProgramGroups.reduce((acc, pg) => {
          const selectedItems = pg.items.filter((i) => i.isSelected)
          if (selectedItems.length > 0) {
            selectedItems.forEach((i2) => {
              prices.push(i2.price)
            })
            acc.push({
              group_id: pg.id,
              id: selectedItems.map((i) => i.id)
            })
          }
          return acc
        }, [])

        totalPrice.value = prices.reduce((acc, p) => acc + p, 0)
      },
      { deep: true, immediate: true }
    )

    return {
      localProgramGroups,
      totalPrice,
      selectAll,
      unSelectAll,
      getDiscountObj,
      numberWithSpaces,
      selectedPrograms
    }
  }
}
</script>

<template>
  <div class="program-sign-up">
    <div class="program-sign-up__ready">
      <img src="@/assets/uploads/icon/common/succsess-schedule.svg" alt="" />
      <p class="bold-text">Готово!</p>
    </div>

    <img
      class="program-sign-up__img"
      src="@/assets/uploads/images/program/three-doctors.png"
      alt="" />

    <div class="program-sign-up__desc">
      <p class="bold-text">
        Мы собрали все необходимое для вас на одной странице.
      </p>
      <p>Вы можете откорректировать состав программ</p>
    </div>

    <div class="programm-sign-up__accordion-list">
      <AccordionComponent
        v-for="(programGroup, index) in localProgramGroups"
        :key="programGroup.id"
        :title="programGroup.title">
        <p v-if="!programGroup.items.length">Все услуги из этого чек-апа уже включены в другие. Выберите подходящие из других чек-апов.</p>
        <div v-else>
          <div class="programm-sign-up__accordion-list__btns-wrapper">
          <button @click.prevent="selectAll(programGroup.id)">
            Выбрать все
          </button>
          <button @click.prevent="unSelectAll(programGroup.id)">
            Сбросить все
          </button>
        </div>
        <ProgramCheckboxList
          :modelValue="programGroup.items"
          @updateSelectedPrograms="localProgramGroups[index].items = $event" />
        </div>
      </AccordionComponent>
    </div>

    <div class="program-sign-up__price">
      <div
        v-if="totalPrice !== getDiscountObj(totalPrice).sum"
        class="program-sign-up__price-top">
        <img
          src="@/assets/uploads/icon/common/percent-icon.svg"
          alt="percent" />
        <p>
          - {{ getDiscountObj(totalPrice).discount }}% на услуги при заказе
          чек-апа
        </p>
      </div>

      <div v-if="totalPrice" class="program-sign-up__price-total">
        <p class="program-sign-up__price-total__discount">
          Общая стоимость: {{ numberWithSpaces(getDiscountObj(totalPrice).sum / 100) }} ₽
        </p>
        <p
          class="program-sign-up__price-total__full"
          v-if="totalPrice !== getDiscountObj(totalPrice).sum">
          {{ numberWithSpaces(totalPrice / 100) }} ₽
        </p>
      </div>

      <p>
        Предложение не является офертой, о финальной стоимости вас
        проконсультируют в колл-центре
      </p>
    </div>

    <CustomButton @click.prevent="$emit('checkupSignup', selectedPrograms)">
      Записаться на чек-ап
    </CustomButton>
    <CustomButton transparent @click.prevent="$emit('resetCheckup')">
      Подобрать заново
    </CustomButton>
    <p class="program-sign-up__tip">
      Вы можете скорректировать состав чек-апа с лечащим врачом
    </p>
  </div>
</template>

<style lang="scss" scoped>
.bold-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.program-sign-up {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.program-sign-up__ready {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 32px;

  > img {
    width: 24px;
    height: 24px;
  }
  > p {
    margin-top: 2px;
  }
}

.program-sign-up__img {
  margin-bottom: 32px;
}

.program-sign-up__desc {
  margin-bottom: 32px;
  > p:first-child {
    text-align: center;
    margin-bottom: 12px;
  }
  > p:last-child {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.programm-sign-up__accordion-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.program-sign-up__price {
  border-radius: 6px;
  background-color: #e4fce6;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 27px;
}

.program-sign-up__price-top {
  display: flex;
  align-items: center;
  img {
    width: 16px;
    margin-right: 8px;
  }
  p {
    margin-top: 2px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.program-sign-up__price-total {
  display: flex;

  .program-sign-up__price-total__discount {
    font-family: Circe;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-right: 8px;
  }

  .program-sign-up__price-total__full {
    font-family: Circe;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    text-decoration-line: line-through;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #7f8da9;
  }
}
.program-sign-up__tip {
  padding: 0 8px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.programm-sign-up__accordion-list__btns-wrapper {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  button {
    border: none;
    border-radius: 8px;
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  button:first-child {
    background-color: #bac7de;
  }
  button:last-child {
    background-color: #e1e8f4;
  }
}
</style>
