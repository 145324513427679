<template>
  <div class="datepicker-gray">
      <VueDatePicker
        :day-names="['Пон', 'Вт', 'Ср', 'Чт', 'Пят', 'Субб', 'Вскр']"
        :preventMinMaxNavigation="true"
        :clearable="false"
        :minDate="minDate"
        :maxDate="maxDate"
        :allowedDates="allowedDates"
        :enableTimePicker="false"
        :autoApply="true"
        hide-offset-dates
        modelType="yyyy-MM-dd"
        month-name-format="long"
        format="dd/MM/yyyy"
        v-model="currentDateModel"
        locale="ru-RU"
        :placeholder="placeholder"
      >
        <template #input-icon>
          <img
            class="input-slot-image"
            src="@/assets/uploads/icon/calendar/calendar-icon-light-blue.svg"
            alt="calendar"
          />
        </template>
        <template #clear-icon="{ clear }">
          <img class="input-slot-image" src="/logo.png" @click="clear" />
        </template>
      </VueDatePicker>
    </div>
</template>

<script>
import { computed, toRefs } from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker'

export default {
  name: 'DatepickerGray',
  emits: ['update:currentDay'],
  components: { VueDatePicker },
  props: {
    currentDay: [String, Object],
    allowedDates: Array,
    placeholder: {
      type: String,
      required: false,
      default: '_._.__'
    },
    isMaxToday: {
      type: Boolean,
      required: false,
      default: false
    },
    isMinToday: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup (props, { emit }) {
    const { currentDay, isMaxToday, isMinToday } = toRefs(props)

    const minDate = computed(() => {
      if (isMinToday.value) return new Date()
      else return null
    })

    const maxDate = computed(() => {
      if (isMaxToday.value) return new Date()
      else return null
    })

    const currentDateModel = computed({
      get () {
        return currentDay.value
      },

      set (value) {
        emit('update:currentDay', value)
      }
    })

    return {
      minDate,
      maxDate,
      currentDateModel
    }
  }
}
</script>

<style scoped lang="scss">

.input-slot-image {
  max-width: 12px;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 0;
  top: 0;
}
</style>

<style lang="scss">
// Стили для библиотеки

.datepicker-gray {
  --dp-font-family: Circe-Regular, sans-serif;

  .datepicker {
    color: unset;
  }

  .dp__calendar_item {
    margin: 2px;
  }

  .dp__input_wrap {
    height: 44px;
  }

  .dp__input {
    padding: 15px 10px;
    border-radius: 8px;
    border: 1px solid #bac7de;
    font-size: 14px;
  }

  .dp__input::placeholder {
    color: $blue-light;
  }

  .dp__input_icon {
    left: auto;
    right: 10px;
    top: 16px;
  }

  .dp__inner_nav {
    background: transparent;
    color: #757575;
  }

  .dp__calendar {
  }

  .dp__month_year_row {
    margin-bottom: 4px;
  }

  .dp__calendar_item {
  }

  .dp__today {
    background-color: #bac7de;
    color: #000;
    border: 0 !important;
  }

  .dp__month_year_select {
    font-weight: 700;
    font-size: 20px;
    color: #323232;
  }

  .dp__pointer {
    font-size: 14px;
    font-weight: 350;
    line-height: 14px;
    text-align: left;
    color: $blue;
  }

  .dp__cell_inner {
    color: #000;
    border-radius: 50%;
    // height: 48px;
    // width: 48px;
  }

  .dp__active_date {
    border: 1px solid #002856;
    background: #fff;
  }

  .dp__calendar .dp__cell_inner.dp__today.dp__active_date {
    background-color: #bac7de !important;
    border: 1px solid #002856 !important;
  }

  .dp__calendar_header_item {
    font-weight: 400;
    font-size: 12px;
    color: #666666;

    // width: 48px;
  }

  .dp__month_year_wrap {
    justify-content: center;
    // gap: 15px;
  }

  .dp__outer_menu_wrap {
    border-radius: 6px;
    box-shadow: 0px 10px 20px 0px rgba(51, 51, 51, 0.15);
  }

  .dp__menu {
    border: unset;
  }

  .dp__menu_inner {
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  }
  .dp__arrow_bottom{
    z-index: 1;
  }

  .dp__month_year_select {
    flex: 0;
  }

  .dp__today {
    color: #fff !important;
  }

  .dp__calendar {
    padding: 0;
  }

  .dp__calendar_header_separator {
    width: 100%;
    height: 0;
    background: var(--dp-border-color);
  }

  .dp__cell_inner {
    border-radius: 50%;
    // height: 48px;
    // width: 48px;
    // height: 32px;
    // width: 32px;
  }

  .dp__calendar_row {
    margin: 0;
  }

  .dp__cell_disabled {
        color: #c0c4cc;
    }
}
</style>
