import { authenticatedApiCall } from '../authenticatedApiCall'

export const getTelemedPaymentLink = async (paymentData) => {
  const url = 'v2/pay/event-telemed/'
  const params = {
    is_phone: paymentData.isPhone,
    is_mir: paymentData.isMir,
    reserve_id: paymentData.reserve_id
  }

  const response = await authenticatedApiCall({ url, params })

  if (response.data.status !== 'ok') {
    throw new Error(
      response.data.message ?? 'Произошла ошибка, попробуйте позже'
    )
  }

  return response.data.data
}
