<template>
  <div class="arrow-title-wrapper" >
    <div class="container desktop-container">
      <div class="arrow-title">
        <div class="arrow-title__back">
          <img @click="handleGoBack" class="arrow-title__img"
            src="@/assets/uploads/icon/common/arrow-back.svg" alt="стрелка" />
        </div>
        <h3 class="arrow-title__title" @click="handleGoBack">{{ title }}</h3>
        <div class="arrow-title__icons">
          <IconInCircle width="32" height="32" bgColor="#E1E8F4" @click="openDrawer({type: 'patients', title: 'Выбор пациента'})">
            <img src="@/assets/uploads/icon/common/person.svg" alt="человек" />
          </IconInCircle>
          <NotificationBell />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconInCircle from '@/components/ui/IconInCircle.vue'
import NotificationBell from '@/components/layout/header/NotificationBell.vue'
import { useMenuStore } from '@/stores/MenuStore'
import { usePhoneLink } from '@/composables/usePhoneLink'
import { useDrawerStore } from '@/stores/DrawerStore'
import { useRouter } from 'vue-router'
import { toRefs } from 'vue'
export default {
  name: 'ArrowTitle',
  components: { IconInCircle, NotificationBell },
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    darkTitle: {
      type: Boolean,
      default: false,
      required: false
    },
    isApp: {
      type: Boolean,
      required: false,
      default: false
    },
    extraBack: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const { extraBack, title } = toRefs(props)

    const menuStore = useMenuStore()
    const { closeMobileMenu } = menuStore
    const drawerStore = useDrawerStore()
    const { openDrawer } = drawerStore

    const router = useRouter()

    const { phone: phoneLink } = usePhoneLink(
      'ios_header_phone',
      'android_header_phone',
      'header_phone',
      '74950214565'
    )

    const handleGoBack = () => {
      if (title.value === 'Еще') {
        closeMobileMenu()
      } else {
        router.go(extraBack.value ? -2 : -1)
      }
    }

    return {
      openDrawer,
      handleGoBack,
      phoneLink
    }
  }
}
</script>

<style lang="scss" scoped>
.arrow-title-wrapper {
  position: fixed;
  z-index: 100;
  width: 100%;

  top: 0;

  transition: all 0.2s linear;
  background: #eef2f8;

  @media (max-width: 991px) {
    background: #ffffff;
  }

  @include desktop {
    position: static;
  }
}

.arrow-title--light {
  background: #eef2f8;

  @media (max-width: 991px) {
    background: #082b53;
  }
}

@media (max-width: 991px) {
  .title--fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    padding: 10px 0 15px 0 !important;
    left: 0;

    &.arrow-title--light {
      z-index: 110;
      border-radius: 9px 9px 0 0;

      .arrow-title__img {
        filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg) brightness(102%) contrast(103%);
      }
    }

    h3 {
      color: #082b53;
    }

    .arrow-title__img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
    }

    .arrow-title {
      margin-bottom: 0;
    }
  }
}

.arrow-title {
  height: 42px;

  margin: 1px 0;
  display: flex;

  align-items: center;
  gap: 10px;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #082b53;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    justify-self: center;

    @include desktop {
      color: #002856;
      font-size: 22px;
    }
  }

  &__img {
    filter: brightness(0) saturate(100%) invert(12%) sepia(41%) saturate(3404%) hue-rotate(196deg) brightness(89%) contrast(95%);
  }

  @include desktop {
    &__img {
      filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg) brightness(102%) contrast(103%);
    }
  }

  &--dark {

    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #ffffff;
    }

    img {
      filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg) brightness(102%) contrast(103%);
    }
  }
}

.arrow-title__back {
  cursor: pointer;
}

.arrow-title__icons {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-left: auto;

  @include desktop {
    display: none;
  }
}

.arrow-title__modal {
  display: none;
  @include tablet {
    display: block;
  }
}

.desktop-container {
  @include desktop {
    padding: 0 !important;
  }
}
</style>
