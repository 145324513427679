import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'

export const authBrowser = async () => {
  const platform = window?.device?.platform.toLowerCase()
  if (platform === 'ios' || platform === 'android') {
    return
  }

  const userStore = useUserStore()
  const { userTokenStorage } = storeToRefs(userStore)
  if (userTokenStorage.value) {
    userStore.setUserToken(userTokenStorage.value)
    await userStore.setUser(userTokenStorage.value)
  }
}
