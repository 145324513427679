
import { authenticatedApiCall } from '../authenticatedApiCall'

export const getProgramsCheckup = async (patientInfo) => {
  const url = 'v6/checkup/section/'
  const params = {
    gender_id: patientInfo.genderId,
    type_id: patientInfo.typeId,
    age: patientInfo.age
  }

  const response = await authenticatedApiCall({ url, params })

  if (response.data.status !== 'ok') {
    throw new Error(
      response.data.message ?? 'Произошла ошибка, попробуйте позже'
    )
  }

  return response.data.data
}
