<script>
import SectionWrapperNew from '@/components/ui/SectionWrapperNew.vue'
import MenuTilesList from '@/components/pages/menu/MenuTilesList.vue'
import MenuButtonList from '@/components/pages/menu/MenuButtonList.vue'
import MenuBottom from '@/components/pages/menu/MenuBottom.vue'
import { mobileMenuButtonsApp } from '@/constants/mobileMenuButtons.js'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import { useMenuStore } from '@/stores/MenuStore'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
export default {
  name: 'MainMenuView',
  components: {
    SectionWrapperNew,
    ArrowTitle,
    MenuTilesList,
    MenuButtonList,
    MenuBottom
  },
  setup () {
    const menuWrapper = ref(null)
    const startPos = ref(null)
    const endPos = ref(null)
    const direction = ref(null)
    const menuStore = useMenuStore()
    const { closeMobileMenu } = menuStore
    const { isMobileMenuOpen } = storeToRefs(menuStore)
    const swipeHandler = (dir, e) => {
      const offsetPxs = endPos.value - startPos.value
      direction.value = dir
      if (dir === 'right' && offsetPxs > 130) {
        closeMobileMenu()
      }
    }
    const dragHandler = (e) => {
      if (direction.value === 'right') {
        // const targetElement = e.currentTarget
        // const clientX = e.changedTouches[0].clientX
        // const viewportWidth = window.innerWidth
        // Рассчитываем процент смещения
        // const offsetPercentage = (clientX / viewportWidth) * 100
        // console.log(clientX)
        // const translateX = Math.min(offsetPercentage - 100, 0)
        // targetElement.style.transform = `translateX(${(clientX / 10) + 30}px)`
      }
    }

    const getStartPos = (e) => {
      // console.log('start pos', e.touches[0].clientX)
      startPos.value = e.touches[0].clientX
    }
    const getEndPos = (e) => {
      // console.log('end pos', e.changedTouches[0].clientX)
      endPos.value = e.changedTouches[0].clientX
      direction.value = null
    }

    return {
      mobileMenuButtonsApp,
      isMobileMenuOpen,
      swipeHandler,
      menuWrapper,
      getStartPos,
      getEndPos,
      dragHandler
    }
  }
}
</script>

<template>
  <div
    ref="menuWrapper"
    v-touch:drag.once="dragHandler"
    v-touch:swipe="(dir, e) => swipeHandler(dir, e)"
    v-touch:press="getStartPos"
    v-touch:release="getEndPos"
    class="menu-wrapper"
    :class="{ show: isMobileMenuOpen }">
    <div class="content-wrapper">
      <ArrowTitle title="Еще" />
      <SectionWrapperNew>
        <MenuTilesList />

        <MenuButtonList :buttons="mobileMenuButtonsApp" />

        <MenuBottom />
      </SectionWrapperNew>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.menu-wrapper {
  position: absolute;
  display: block;
  height: 100vh;
  width: 100%;
  transform: translateX(100%);
  z-index: 10000;
  transition: all 0.3s ease;
  overscroll-behavior: none;
  overflow-y: scroll;
  overflow-x: hidden;

  @include desktop {
    display: none;
  }
}

.content-wrapper {
  padding-top: 42px;
  height: fit-content;
  padding-bottom: 42px;
}

.show {
  transform: translateX(0);
}

.sub {
  position: sticky;
  top: 0;
}
</style>
