<template>
  <div v-if="!loading" class="form">
    <div class="register-new">
      <FormInput
        :validError="v$.lastName.$error"
        :errorMsg="errorMsg"
        v-model="userInfo.lastName"
        name-id="register-new-last-name"
        label-title="Фамилия"
      />

      <FormInput
        :validError="v$.firstName.$error"
        :errorMsg="errorMsg"
        v-model="userInfo.firstName"
        name-id="register-new-first-name"
        label-title="Имя"
      />

      <FormInput
        v-model="userInfo.middleName"
        name-id="register-new-middle-name"
        label-title="Отчество"
      />

      <div class="register-new__radio">
        <h5 class="register-new__label">Пол</h5>
        <div class="register-new__radio-wrapper">
          <FormRadio label="Мужчина" :value="1" v-model="userInfo.gender" />
          <FormRadio label="Женщина" :value="2" v-model="userInfo.gender" />
        </div>
      </div>

      <div class="register-new__birthday">
        <h5 class="register-new__label">Дата рождения</h5>
        <DatepickerGray
          v-model:currentDay="userInfo.birthday"
          :isInline="false"
          :isMaxToday="true"
          :class="{
            'validation-error': v$.birthday.$error
          }"
        />
        <div
          v-if="v$.birthday.$error"
          class="error help-block help-block-error"
        >
          Необходимо заполнить поле. Пример: 01.01.2000
        </div>
      </div>

      <FormInput
        disabled
        v-model="userInfo.phone"
        name-id="register-new-phone"
        label-title="Телефон"
      />

      <FormInput
        :validError="v$.email.$error"
        :errorMsg="errorMsg"
        v-model="userInfo.email"
        name-id="register-new-email"
        label-title="E-mail"
      />

      <FormInputCheckbox
        id="register-checkbox"
        v-model="userInfo.isTerms"
        label="Я согласен на"
        linkLabel="обработку моих персональных данных"
        href="https://www.k31.ru/info/soglasie/"
        :validError="v$.isTerms.$error"
      />

      <FormInputCheckbox
        id="profile-rules"
        v-model="userInfo.rules"
        label="Я ознакомлен с"
        linkLabel="правилами внутреннего распорядка"
        href="https://www.k31.ru/upload/doc/useterms.pdf"
        :validError="v$.rules.$error"
      />
    </div>

    <div
      v-if="showOtpForm"
      class="form-sms__group"
      :class="{ 'form-group--error': v$.code.$error || requestError }"
    >
      <OtpForm
        v-model="userInfo.code"
        :digit-count="6"
        title="Код из смс"
        :error="v$.code.$error || requestError"
        @is-completed="requestError = false"
      />

      <div v-if="v$.code.$error" class="error help-block">
        Необходимо ввести смс код
      </div>
    </div>

    <CustomButton
      @click="registerUser"
      tag="button"
      type="button"
      margin-bottom="0"
    >
      Создать личный кабинет
    </CustomButton>
  </div>

  <TheLoader v-if="loading" />

  <div v-if="requestError" class="error help-block help-block-error">
    {{ requestErrorMsg }}
  </div>

  <div v-if="responseErrorMsg" class="error help-block help-block-error">
    {{ responseErrorMsg }}
  </div>

  <p class="register-new__text">У меня уже есть Личный кабинет К+31</p>
  <CustomButton white tag="router-link" to="/auth/phone">
    Войти под другим номером
  </CustomButton>
</template>

<script>
import { computed, inject, reactive, ref, watchEffect } from 'vue'

import { required, email, sameAs } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import 'vue-datepicker-next/index.css'
import 'vue-datepicker-next/locale/ru'

import DatepickerGray from '@/components/ui/datepicker/DatepickerGray.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import FormInput from '@/components/ui/form/FormInput.vue'
import FormRadio from '@/components/ui/form/FormRadio.vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { useAuthUser } from '@/composables/useAuthUser'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { showFingerPrint } from '@/utilits/showFingerPrint'
import FormInputCheckbox from '@/components/ui/form/FormInputCheckbox.vue'
import OtpForm from '@/components/pages/auth/OtpForm.vue'

export default {
  name: 'RegisterNewView',
  components: {
    OtpForm,
    FormInputCheckbox,
    FormRadio,
    FormInput,
    CustomButton,
    TheLoader,
    DatepickerGray
  },

  setup () {
    const userStore = useUserStore()
    const { userSmsCode, userPhone, userToken } = storeToRefs(userStore)
    const router = useRouter()
    const loading = ref(false)
    const requestError = ref(false)
    const responseErrorMsg = ref('')
    const requestErrorMsg = ref('Ошибка регистрации')
    const showOtpForm = ref(false)
    const userInfo = reactive({
      lastName: null,
      firstName: null,
      middleName: null,
      birthday: null,
      phone: userPhone.value,
      code: userSmsCode.value,
      email: null,
      rules: null,
      isTerms: null,
      gender: 1,
      userID: null
    })
    const rules = {
      lastName: { required },
      firstName: { required },
      gender: { required },
      birthday: { required },
      code: { required },
      isTerms: { sameAs: sameAs(true) },
      rules: { sameAs: sameAs(true) },
      email: { required, email }
    }
    const v$ = useVuelidate(rules, userInfo)
    const toast = inject('toast')

    const showHelloToaster = () => {
      toast.success('Добро пожаловать!', {
        position: 'top-right',
        type: 'success'
      })
    }

    const errorMsg = computed(() => {
      return 'Необходимо заполнить поле.'
    })

    const isPhone = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform === 'ios' || platform === 'android'
    })

    const registerUser = async () => {
      v$.value.$touch()
      if (v$.value.$error) {
        return
      }
      loading.value = true
      requestError.value = false

      const userData = {
        last_name: userInfo.lastName,
        first_name: userInfo.firstName,
        middle_name: userInfo.middleName,
        gender_id: userInfo.gender,
        birthday: userInfo.birthday,
        username: userInfo.phone,
        email: userInfo.email,
        is_terms: userInfo.isTerms ? 1 : 0,
        code: userInfo.code
      }

      try {
        const response = await axios.post(
          '/v2/element/user/simple-signup/',
          userData
        )

        if (response.data.status === 'error') {
          requestError.value = true
          requestErrorMsg.value = response.data.message

          if (response.data.error_code === 'incorrect_code') {
            showOtpForm.value = true
            await getUserSmsCode()
          }
        }

        if (response.data.status === 'ok') {
          showOtpForm.value = false
          responseErrorMsg.value = ''
          requestError.value = false
          await useAuthUser(userPhone.value, userSmsCode.value)

          if (isPhone.value) {
            await router.push({ name: 'auth-pin-code' })
          } else {
            await router.push({ name: 'home' })
            showHelloToaster()
          }
        }
      } catch (err) {
        requestError.value = true
        requestErrorMsg.value = `Возникла  ошибка. Пожалуйста, попробуйте позже. ERR: ${err.message}`
      } finally {
        loading.value = false
      }

      if (userToken.value) {
        showFingerPrint(userToken.value)
      }
    }

    const getUserSmsCode = async () => {
      try {
        const response = await axios.post('/v2/element/user/request-otp', {
          phone: userInfo.phone
        })

        if (response.data.status === 'ok') {
          responseErrorMsg.value = false
        } else {
          responseErrorMsg.value = response.data.message ?? 'Произошла ошибка'
        }
      } catch (e) {
        responseErrorMsg.value = 'Произошла ошибка'
      }
    }

    watchEffect(() => {
      console.log('user birthday', userInfo.birthday)
    })

    return {
      loading,
      registerUser,
      v$,
      requestError,
      errorMsg,
      userInfo,
      requestErrorMsg,
      showOtpForm,
      responseErrorMsg
    }
  }
}
</script>

<style scoped lang="scss">
.form-sms__group {
  margin-bottom: 15px;
}

.register-new {
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  @include desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-start: 1;
    & > div:nth-child(-n + 4) {
      grid-column: 1;
    }
    & > div:nth-child(n + 5) {
      grid-column: 2;
      grid-row: auto;
    }
    & > div:nth-child(6) {
      grid-row: 2 !important;
    }
    & > div:nth-child(7) {
      grid-row: 3 !important;
    }
    & > div:nth-child(8) {
      grid-column: 1 / 3 !important;
    }
    & > div:nth-child(9) {
      grid-column: 1 / 3 !important;
    }
    & button {
      grid-column: 1 / 3;
    }
  }

  &__label {
    margin-bottom: 5px;
  }

  &__radio-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  &__rules {
    display: flex;
    align-items: center;
    gap: 15px;
    color: black;
    margin-bottom: 5px;

    & a {
      color: $blue;
      text-decoration: underline;
    }
  }
}

.register-new__birthday {
  grid-row: 1 !important;
}
</style>

<style>
.dp__input_wrap {
  height: 46px;
}

.dp__pointer {
  height: 100%;
  font-size: 20px;
  color: #111111;
  border: 1px solid #f4f4f4;
}

.mx-datepicker.login.text-input {
  width: 100%;
}

.mx-datepicker .mx-input {
  margin-bottom: 5px;
  background: #fff;
  border: 1px solid #bac7de;
  border-radius: 5px;
  padding: 12px;
  width: 100%;
  line-height: 1;
  font-size: 14px;
  height: 100%;
}

.validation-error .mx-input {
  border-color: #ff1f00;
}

.validation-error .mx-input::placeholder {
  color: #ff1f00;
}

.help-block.error {
  color: #ff1f00;
}
</style>
