<script>
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { usePhoneLink } from '@/composables/usePhoneLink'
import IconInCircle from '@/components/ui/IconInCircle.vue'
import NotificationBell from '@/components/layout/header/NotificationBell.vue'
import { useRouter } from 'vue-router'
import { useDrawerStore } from '@/stores/DrawerStore'
export default {
  name: 'UserBar',
  components: { IconInCircle, NotificationBell },
  setup () {
    const userStore = useUserStore()
    const { user, userLoading } = storeToRefs(userStore)
    const drawerStore = useDrawerStore()
    const { openDrawer } = drawerStore

    const router = useRouter()
    const userColor = (icon) => {
      const iconColors = {
        man: '#D1F9EF',
        woman: '#FBCDCD',
        boy: '#F5FBCD',
        girl: '#F5E6FC',
        ufo: '#E4FCE6'
      }

      return {
        backgroundColor: iconColors[icon] || ''
      }
    }

    const goToLogin = () => {
      router.push({ name: 'auth-phone' })
    }

    const { phone: phoneLink } = usePhoneLink(
      'ios_header_phone',
      'android_header_phone',
      'header_phone',
      '74950214565'
    )

    return { userColor, openDrawer, user, phoneLink, goToLogin, userLoading }
  }
}
</script>

<template>
  <div class="user-bar__wrapper">
    <div>
      <div class="skeleton-user" v-if="userLoading"></div>
      <div v-else>
        <div v-if="user && user" class="user-bar__profile">
        <div v-if="user.photo" @click="openDrawer({type: 'patients', title: 'Выбор пациента'})" class="user-bar__user-icon-profile">
          <img :src="user.photo" alt="фото пользователя" />
        </div>
        <div v-else class="user-bar__user-icon" @click="openDrawer({type: 'patients', title: 'Выбор пациента'})" :style="userColor(user.icon)">
          <img :src="require(`@/assets/uploads/icon/profile/${user.icon}.svg`)" alt="фото пользователя" />
        </div>

        <div class="user-bar__user-name">
          <span>Добрый день!</span>
          <span>{{ user.first_name }}</span>
        </div>
      </div>
      <button v-if="!user" class="header__login-btn" @click="goToLogin">
          вход/регистрация
      </button>
      </div>

    </div>
    <div class="user-bar__other-icons">
      <NotificationBell />
      <a :href="`tel:+${phoneLink}`">
        <IconInCircle width="32" height="32" bgColor="#E1E8F4">
          <img src="@/assets/uploads/images/header/phone.svg" alt="иконка телефона" />
        </IconInCircle>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-bar__user-icon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  @include desktop {
    height: 40px;
    width: 40px;
  }
}

.user-bar__user-icon-profile {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;

  @include desktop {
    height: 40px;
    width: 40px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.user-bar__profile {
  display: flex;
  gap: 12px;
}

.user-bar__wrapper {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.user-bar__user-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;

  }
}

.user-bar__other-icons {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-left: auto;
}

.header__login-btn {
  border: none;
  border-radius: 30px;
  background: #E1E8F5;
  color: #002856;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  padding: 10px 11px;
  white-space: nowrap;
  transition: 0.2s;

  &:hover {
    background: #e1e8f4;
  }

  @include desktop {
    font-size: 14px;
    padding: 12px 20px;
  }

}

.skeleton-user {
  width: 145px;
    height: 32px;
    border-radius: 30px;
    background: #e0e0e0;
    animation: skeleton-loading 1.3s infinite;
}

@keyframes skeleton-loading {
    0% {
      background-color: #d3d3d3;
    }
    50% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #d3d3d3;
    }
  }
</style>
