<script>
import { ref } from 'vue'
import { uploadFilesTelemed } from '@/api/appointement/uploadFilesTelemed.js'
import TheText from '@/components/ui/typography/TheText.vue'

export default {
  name: 'TelemedUpload',
  components: { TheText },
  props: {
    reserveId: {
      type: String || Number,
      required: true
    },
    isHome: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const uploadStatus = ref(null)
    const uploadMessage = ref(null)
    const loading = ref(null)

    const loadFile = async (f) => {
      if (f.length === 0) {
        return
      }

      const file = f[0]

      if (!(file instanceof File)) {
        return
      }

      const formData = new FormData()
      formData.append('fileInput', file, file.name)

      uploadStatus.value = null
      loading.value = true

      try {
        uploadMessage.value = await uploadFilesTelemed(props.reserveId, formData)
      } catch (err) {
        uploadStatus.value = false
        uploadMessage.value = err
      } finally {
        loading.value = false
      }
    }

    return {
      uploadStatus,
      uploadMessage,
      loadFile
    }
  }
}
</script>

<template>
      <div class="reserve-schedule__button">
        <div class="files__loader" :class="{'files__loader--sm': isHome }">
          <input
            type="file"
            accept="image/*,application/pdf"
            ref="input"
            @change="loadFile($event.target.files)"
          />
          <p>
            <img
              class="files__upload-icon"
              src="@/assets/uploads/icon/common/plus-file.svg"
              alt="иконка"
            />
            <span>Прикрепить файлы</span>
          </p>
        </div>
        <TheText
          :style="{marginTop: 8 + 'px'}"
          fontSize="12px"
          text-align="center"
          margin-bottom="8px"
          :color="uploadStatus === false ? 'red' : '#082B53'"
        >
         {{ uploadMessage || (isHome ? '' : 'Документы, которые необходимо показать доктору перед приемом')}}
        </TheText>
      </div>
</template>

<style lang="scss" scoped>
.reserve-schedule_error {
  margin-bottom: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: $red;
  font-size: 14px;

  & h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
  }
}

.stage-three__price {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $blue;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}

.schedule__checkbox {
  margin-bottom: 15px;
}

.reserve-schedule {
  &__wrapper {
    width: 100%;
    margin-bottom: 30px;
    display: inline-flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  }

  &__top {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }

  &__error-text {
    color: #222;
    font-size: 18px;
    margin-bottom: 20px;
  }

  &__hint {
    max-width: 450px;
    margin-bottom: 10px;
  }

  &__error {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: #be1a1a;

    & span {
      position: absolute;
      left: 6px;
      top: -4px;
      color: white;
      transform: rotate(45deg);
      font-size: 30px;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #002856;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

    & svg {
      width: 16px;
      height: 11px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #062e4f;
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 15px;
  }

  &__date {
    gap: 5px;
    color: #222;
    display: flex;
    align-items: center;
    line-height: 1;

    & h5 {
      font-weight: 700;
      font-size: 16px;
    }

    & p {
      font-size: 14px;
    }
  }

  &__utc {
    color: $blue;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.1px;
  }

  &__doctor-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
    text-align: start;
  }

  &__picture {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    & img {
      max-width: 120px;
    }
  }

  &__description {
  }

  &__subtitle {
    margin-bottom: 8px;
    color: #002856;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  &__position {
    margin-bottom: 8px;
    color: #7f8da9;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  &__branch {
    margin-bottom: 8px;
    display: inline-flex;
    gap: 8px;
    align-items: center;

    & svg {
      width: 25px;
      height: 25px;
      color: #002856;
    }

    & h6 {
      color: #222;
      font-size: 14px;
      line-height: 120%;
    }
  }

  &__address {
    color: #7f8da9;
    line-height: 18px;
  }

  &__bottom {
  }

  &__another {
    //display: block;
    font-size: 16px;
    font-weight: 400;
  }

  &__argee {
    color: $blue;
    text-align: center;
    font-size: 11px;
    line-height: 16px;
  }

  &__buttons {
    max-width: 100%;
    display: flex;
    gap: 15px;
    align-items: center;

    @include tablets-md {
      flex-direction: column;
    }
  }

  &__button {
    flex: 1 1 auto;
    width: 100%;
    align-self: start;
  }
}

.schedule-modal__link {
  max-width: 600px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.schedule-modal__calendar {
  width: 20px;
  height: 20px;
}
.doctor__favorite-icon {
  height: 20px;
  width: 20px;
}

.doctor__picture {
  width:  100%;
  height: 100%;
  border-radius: 50%;
}
.doctor__picture-wrapper {
  max-width: 120px;
}

.files__loader {
  position: relative;
  cursor: pointer;
  max-width: 100%;;
  background: #fff;
  border-radius: 500px;
  @include tablets-md {
    background-color: #ffffff00;
  }

  &:active p {
    background: #e1e8f4;
    text-decoration: none;
    color: #062e4f;
  }

  &:hover p {
    background: #e1e8f4;
    text-decoration: none;
    color: #062e4f;
  }

  input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 46px;
    opacity: 0;
  }

  p {
    height: 42px;
    border: 1px solid #002856;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    color: #002856;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    border-radius: 30px;
    background: transparent;
    padding: 5px;
    transition: all 0.3s ease-in-out;
  }
}

.files__upload-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.upload-message--error {
  color: rgb(255, 0, 0)
}

.modalPaymentIsOpen {

  top: 0;
  opacity: 1;
  height: 100%;

}

.files__loader--sm {
  max-height: 34px;

  >input {
    max-height: 34px;
  }

  >p {
    max-height: 34px;
  }
}
</style>
