import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

export const useMenuStore = defineStore('menu', () => {
  const isMobileMenuOpen = ref(false)
  const route = useRoute()

  const openMobileMenu = () => {
    if (route.name === 'home' && window?.StatusBar) {
      window?.StatusBar?.backgroundColorByHexString('#FFF')
    }
    isMobileMenuOpen.value = true
    document.body.style.overflowY = 'hidden'
  }
  const closeMobileMenu = () => {
    if (route.name === 'home' && window?.StatusBar) {
      window?.StatusBar?.backgroundColorByHexString('#C993F2')
    }

    isMobileMenuOpen.value = false
    document.body.style.overflowY = ''
  }

  const menuIsActive = ref(false)
  const menuHeight = ref(1600)

  const setMenuHeight = (height) => {
    menuHeight.value = height
  }

  const closeMenu = () => {
    menuIsActive.value = false
  }

  const toggleMenu = () => {
    menuIsActive.value = !menuIsActive.value
  }

  return {
    menuIsActive,
    isMobileMenuOpen,
    openMobileMenu,
    closeMobileMenu,
    closeMenu,
    toggleMenu,
    setMenuHeight,
    menuHeight
  }
})
