<template>
  <div class="referral__date-selector">
    <span v-for="time in timeSpan" :key="time" :class="{ referral__selectedTimespan: selectedTimespan === time }"
      @click="selectTimespan(time)">{{ time }}</span>
    <img @click="showDatepicker" src="@/assets/uploads/icon/calendar/calendar-icon-light-blue.svg" alt="calendar" :class="{datepickerShown: isDatepickerOpened}">
  </div>

  <TheLoader v-if="loading" />
  <ErrorText v-if="!loading && error" textAlign="center" :showBtn="false" :error="error" />

  <WomanDoctor v-if="!loading && selectedRefferals.length === 0 && !error" :isChat="false"
    :subtitle="'У вас еще нет направлений'" />

  <div v-else>
    <Transition name="fade">
      <DatepickerInline v-if="isDatepickerOpened && !loading && !error && !selectedTimespan" v-model:currentDay="currentDay" :allowedDates="allowedDates" :preventMinMaxNavigation="false" :noMaxMinDates="true"/>
    </Transition>

    <div class="referral__items-wrapper">
      <div @click="openReferral(item)" class="referral__item" v-for="(item, index) in selectedRefferals"
        :key="item.code + index">
        <p>{{ formatDateToRod(item.start_at) + ' ' + 'г' }}</p>
        <p>{{ item.title }}</p>
        <p>{{ item.category_title }}</p>
      </div>
    </div>
  </div>

  <ModalReferral @modalClose="modalIsActive = false" :modalIsActive="modalIsActive" :referralData="selectedReferral" :bannerInfo="appInfo.alert_event_plan"/>

</template>

<script>
import { computed, onMounted, ref, toRefs, watch } from 'vue'
import { getPatientReferrals } from '@/api/medcard/getPatientReferrals.js'
import { useUserStore } from '@/stores/UserStore'
import { formatDateToRod } from '@/utilits/formatDateFunctions.js'
import TheLoader from '@/components/ui/TheLoader.vue'
import WomanDoctor from '@/components/pages/chat/WomanDoctor.vue'
import ErrorText from '@/components/ui/ErrorText.vue'
import ModalReferral from '@/components/pages/med-card/ModalReferral.vue'
import DatepickerInline from '@/components/ui/datepicker/DatepickerInline.vue'
import { useAppInfoStore } from '@/stores/AppInfoStore'

export default {
  name: 'MedcardReferrals',
  components: { TheLoader, WomanDoctor, ErrorText, ModalReferral, DatepickerInline },
  setup () {
    const timeSpan = ['Все', 'Активные', 'Архивные']

    const loading = ref(null)
    const error = ref(null)

    const appInfoStore = useAppInfoStore()
    const { appInfo } = appInfoStore
    const userStore = useUserStore()
    const { getUserId } = toRefs(userStore)
    const referralData = ref(null)
    const selectedTimespan = ref('Все')
    const isDatepickerOpened = ref(false)
    const selectedReferral = ref(null)
    const modalIsActive = ref(false)
    const currentDay = ref('')

    const selectedRefferals = computed(() => {
      if (referralData.value?.length) {
        if (selectedTimespan.value === 'Все') {
          return referralData.value
        } else if (selectedTimespan.value === 'Активные') {
          return referralData.value.filter(r => r.is_completed)
        } else if (currentDay.value) {
          return referralData.value.filter(r => r.start_at === currentDay.value)
        } else {
          return referralData.value.filter(r => !r.is_completed)
        }
      } else {
        return []
      }
    })

    const allowedDates = computed(() => {
      if (referralData.value?.length) {
        const dates = new Set(referralData.value.map(r => r.start_at))
        return [...dates]
      } else {
        return []
      }
    })

    const fetchData = async () => {
      try {
        loading.value = true
        error.value = null
        referralData.value = await getPatientReferrals()
      } catch (err) {
        if (err.response.data.message === 'Список диагнозов пуст.') {
          return
        }
        error.value = err
      } finally {
        loading.value = false
      }
    }

    const openReferral = (referral) => {
      modalIsActive.value = true
      selectedReferral.value = referral
      window.scrollTo({
        top: 0
      })
    }

    const showDatepicker = () => {
      if (isDatepickerOpened.value) {
        isDatepickerOpened.value = false
        selectedTimespan.value = 'Все'
      } else {
        isDatepickerOpened.value = true
        selectedTimespan.value = null
        currentDay.value = allowedDates.value[0]
      }
    }

    const selectTimespan = (time) => {
      isDatepickerOpened.value = false
      currentDay.value = null
      selectedTimespan.value = time
    }

    onMounted(async () => {
      await fetchData()
    })

    watch(getUserId, async () => {
      if (getUserId.value) {
        await fetchData()
      }
    }, { immediate: false })

    watch(allowedDates, () => {
      currentDay.value = allowedDates.value[0]
    })

    return {
      appInfo,
      error,
      loading,
      timeSpan,
      selectedTimespan,
      selectedRefferals,
      modalIsActive,
      formatDateToRod,
      openReferral,
      selectedReferral,
      isDatepickerOpened,
      currentDay,
      allowedDates,
      showDatepicker,
      selectTimespan
    }
  }
}
</script>

<style scoped lang="scss">
.referral__date-selector {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 32px;

  img {
    cursor: pointer;
    width: 24px;
    height: 24px;
    align-self: center;
    margin-left: auto;
  }

  span {
    cursor: pointer;
    font-family: Circe;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: $blue-light;

    &.referral__selectedTimespan {
      font-weight: 700;
      color: $blue;
    }
  }
}

.referral__item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0 5px 15px 0 #00000026;
  padding: 12px 16px;
  margin-bottom: 16px;
  background-color: #FFF;

  text-align: left;

  p:nth-child(1) {
    color: $blue;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.01em;
  }

  p:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $black;
  }

  p:nth-child(3) {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: $blue-grey;
  }
}

.datepickerShown {
  filter: brightness(0) saturate(100%) invert(14%) sepia(29%) saturate(4269%) hue-rotate(194deg) brightness(90%) contrast(105%);
}

.referral__items-wrapper {
  margin-top: 16px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
