<script>
import TextBannerWarning from '@/components/Common/TextBannerWarning.vue'
import ModalWithArrow from '@/components/modals/ModalWithArrow.vue'
import { formatDateToRod, getDayWord } from '@/utilits/formatDateFunctions.js'
import { computed } from 'vue'

export default {
  components: { ModalWithArrow, TextBannerWarning },
  props: {
    modalIsActive: Boolean,
    referralData: Object,
    bannerInfo: Object
  },
  emits: ['modalClose'],
  setup (props) {
    const isBanner = computed(() => {
      if (props.bannerInfo) {
        return Object.values(props.bannerInfo).some(k => !!k)
      } else return false
    })
    return {
      formatDateToRod,
      getDayWord,
      isBanner
    }
  }
}

</script>

<template>
    <ModalWithArrow title="Направление" :modalIsActive="modalIsActive" @modalClose="$emit('modalClose')">
        <div v-if="referralData" class="referral">
            <div class="referral__title">
                <h5>{{ referralData.category_title }}</h5>
                <p>{{ referralData.title }}</p>
            </div>

            <div class="referral__doctor-card">
                <div class="referral__doctor-img-wrapper">
                    <img src="@/assets/uploads/icon/profile/doctor-icon.svg" alt="доктор" />
                </div>

                <div class="referral__doctor-name">
                    <p>{{ referralData.doctor_name }}</p>
                    <p>{{ referralData.doctor_position }}</p>
                </div>

                <div class="referrall__date">
                    <p>{{ formatDateToRod(referralData.start_at) + ' ' + 'г' }}</p>
                </div>
            </div>

            <div class="referral__title">
                <h5>Длительность действия:</h5>
                <p>{{ getDayWord(referralData.duration) }}</p>
            </div>

            <TextBannerWarning v-if="isBanner" :htmlTags="bannerInfo.text" />

            <div class="referral__title">
                <h5>Дата окончания:</h5>
                <p>{{ formatDateToRod(referralData.end_at) }}</p>
            </div>

            <div class="referral__time-warning">
                <img src="@/assets/uploads/icon/common/blue-warning.svg" alt="важно" />

                <p>Указано местное время клиники (UTC+3)</p>
            </div>
        </div>
    </ModalWithArrow>
</template>

<style lang="scss" scoped>
.referral__doctor-img-wrapper {
    min-width: 44px;
    min-height: 44px;
    background-color: #E1E8F4;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        min-width: 28px;
    }
}

.referral__title {
    margin-bottom: 12px;

    h5 {
        font-family: Circe;
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        text-align: left;
        color: $blue;
        margin-bottom: 6px;

    }

    p {
        font-family: Circe;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: $black;
        padding-left: 16px;
    }
}

.referral__doctor-card {

    display: flex;
    align-items: flex-start;
    padding: 12px 0;
    border-top: 1px solid #E1E8F4;
    border-bottom: 1px solid #E1E8F4;
    margin-bottom: 12px;
}

.referral__doctor-img-wrapper {
    margin-right: 16px;
}

.referral__doctor-name {
    p:nth-child(1) {
        font-family: Circe;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: $blue;
        max-width: 170px;
    }

    p:nth-child(2) {
        font-family: Circe;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        color: $blue-grey;
    }
}

.referrall__date {
    margin-left: auto;
    font-family: Circe;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;

}

.referral__time-warning {
    display: flex;
    gap: 12px;

    p {
        font-family: Circe;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        color: $blue;
    }
}
</style>
