<script>
import SectionWrapperNew from '../components/ui/SectionWrapperNew.vue'
import MenuTilesList from '../components/pages/menu/MenuTilesList.vue'
import MenuButtonList from '../components/pages/menu/MenuButtonList.vue'
import MenuBottom from '../components/pages/menu/MenuBottom.vue'
import { mobileMenuButtonsApp } from '@/constants/mobileMenuButtons.js'
import ArrowTitle from '../components/ui/typography/ArrowTitle.vue'
import { onActivated } from 'vue'
export default {
  name: 'MainMenuView',
  components: { SectionWrapperNew, ArrowTitle, MenuTilesList, MenuButtonList, MenuBottom },
  setup () {
    onActivated(() => {
      window.scrollTo({
        top: 0,
        left: 0
      })
    })
    return { mobileMenuButtonsApp }
  }
}
</script>

<template>
    <ArrowTitle title="Еще" />
    <SectionWrapperNew>

      <MenuTilesList />

      <MenuButtonList :buttons="mobileMenuButtonsApp"/>

      <MenuBottom />

    </SectionWrapperNew>
</template>

<style lang="scss" scoped>

.sub {
  position: sticky;
  top: 0;
}

</style>
