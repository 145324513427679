<script>
import IconInCircle from '@/components/ui/IconInCircle.vue'
import CustomButton from '@/components/ui/buttons/CustomButton'
import FormRadio from '@/components/ui/form/FormRadio'
import TheLoader from '@/components/ui/TheLoader.vue'
import { ref, watch } from 'vue'
import ProgramCheckboxList from '@/components/pages/programs/ProgramCheckboxList'

export default {
  components: {
    IconInCircle,
    CustomButton,
    FormRadio,
    ProgramCheckboxList,
    TheLoader
  },
  props: {
    typeId: Number || null,
    programAssignment: Number || null,
    programGroups: Array || null,
    loading: Boolean
  },
  emits: [
    'assignProgram',
    'selectTypeId',
    'updateSelectedPrograms',
    'setGender',
    'setAge',
    'goToNextStage'
  ],
  setup (props, { emit }) {
    const test = ref(false)
    const localGender = ref(null)
    const localAge = ref(null)

    watch(localAge, () => {
      emit('setAge', localAge.value)
    })

    watch(localGender, () => {
      emit('setGender', Number(localGender.value))
    })
    return {
      test,
      localGender,
      localAge
    }
  }
}
</script>

<template>
  <div class="program-selector">
    <div class="question-list">
      <div class="question-block">
        <h3>Для кого</h3>
        <ul>
          <li>
            <button
              @click.prevent="$emit('assignProgram', 0)"
              class="btn-icon"
              :class="{ active: programAssignment === 0 }">
              <IconInCircle bg-color="#E1E8F4" width="64" height="64">
                <img
                  class="question-block__icon"
                  src="@/assets/uploads/icon/icons-in-circle/man-woman-icon-in-circle.svg" />
              </IconInCircle>
              <span class="question-block__text">Для себя</span>
            </button>
          </li>
          <li>
            <button
              @click.prevent="$emit('assignProgram', 1)"
              class="btn-icon"
              :class="{ active: programAssignment === 1 }">
              <IconInCircle bg-color="#E1E8F4" width="64" height="64">
                <img
                  class="question-block__icon"
                  src="@/assets/uploads/icon/icons-in-circle/family-icon-in-circle.svg" />
              </IconInCircle>
              <span class="question-block__text">Для семьи</span>
            </button>
          </li>
        </ul>
      </div>
      <div class="question-block">
        <h3>Тип программы</h3>
        <ul>
          <li>
            <button
              @click.prevent="$emit('selectTypeId', 1)"
              class="btn-icon"
              :class="{ active: typeId === 1 }">
              <IconInCircle bg-color="#E1E8F4" width="64" height="64">
                <img
                  class="question-block__icon"
                  src="@/assets/uploads/icon/icons-in-circle/365-calendar-icon-in-circle.svg" />
              </IconInCircle>
              <span class="question-block__text">Основные</span>
            </button>
          </li>
          <li>
            <button
              @click.prevent="$emit('selectTypeId', 2)"
              class="btn-icon"
              :class="{ active: typeId === 2 }">
              <IconInCircle bg-color="#E1E8F4" width="64" height="64">
                <img
                  class="question-block__icon"
                  src="@/assets/uploads/icon/icons-in-circle/diamond-icon-in-circle.svg" />
              </IconInCircle>
              <span class="question-block__text">Премиум</span>
            </button>
          </li>
        </ul>
      </div>
      <div v-if="programAssignment === 1" class="question-block">
        <h3>Пол</h3>
        <div class="question-block__radio">
          <FormRadio
            color="#002856"
            name="gender"
            label="Мужской"
            :value="1"
            v-model="localGender" />
          <FormRadio
            color="#002856"
            name="gender"
            label="Женский"
            :value="2"
            v-model="localGender" />
        </div>
      </div>
      <div v-if="programAssignment === 1" class="question-block">
        <h3>Возраст</h3>
        <div class="question-block__radio">
          <FormRadio
            color="#002856"
            name="age"
            :label="localGender === 1 ? '18-45' : '18-40'"
            :value="18"
            v-model="localAge" />
          <FormRadio
            color="#002856"
            name="age"
            :label="localGender === 1 ? '45-60' : '40-65'"
            :value="localGender === 1 ? 46 : 41"
            v-model="localAge" />
          <FormRadio
            color="#002856"
            name="age"
            label="старше 65"
            :value="66"
            v-model="localAge" />
        </div>
      </div>
      <TheLoader v-if="loading">Загрузка</TheLoader>
      <div v-if="!loading && programGroups" class="question-block">
        <h3>Ваши пожелания</h3>
        <ProgramCheckboxList
          class="question-block__checkbox-list"
          :modelValue="programGroups"
          @updateSelectedPrograms="$emit('updateSelectedPrograms', $event)" />
        <CustomButton @click.prevent="$emit('goToNextStage')">
          Составить индивидуальный чек-ап
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.question-block {
  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #002856;
    margin-bottom: 10px;
  }
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    li {
      .btn-icon {
        padding: 16px 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      span {
        font-size: 12px;
        font-weight: 700;
        line-height: 13px;
        color: #002856;
        margin-top: 12px;
      }
      button {
        border: none;
        padding: 0;
        width: 100%;
        box-shadow: 0px 5px 15px 0px #0000001a;
        border-radius: 8px;
        overflow: hidden;
        background: #ffffff;
        &.active {
          outline: 2px solid #002856;
        }
      }
    }
  }
}

.question-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.question-block__radio {
  display: flex;
  gap: 40px;

  span {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.question-block__checkbox-list {
  margin-bottom: 32px;
}
</style>

<style lang="scss">
.question-block__radio {
  display: flex;
  gap: 40px;

  span {
    font-family: Circe;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
</style>
