import { useToast } from 'vue-toastification'
import CustomToast from '@/components/ui/CustomToast.vue'
const toast = useToast()

const toastOptions = {
  position: 'bottom-center',
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.38,
  timeout: 2000,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: false,
  rtl: false,
  toastClassName: 'custom-toast'
}

export const showToast = ({ title, text, icon }) => {
  toast.success(
    {
      component: CustomToast,
      props: {
        title,
        text,
        icon
      }
    },
    toastOptions
  )
}
