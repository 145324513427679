<template>
  <div class="keypad">
    <button v-for="button in keypadButtons" :key="button.value" @click.prevent="$emit('changePin', button.value)"
      :class="{ 'backspaceWhite': pinLength, 'backspaceDim': (!pinLength && !isBiometryActive) || isPinSetup, 'faceIdIcon': (!pinLength && isBiometryActive && isBiometryAvailable && !isPinSetup) }">
      {{ button.label }}
    </button>
  </div>
</template>
<script>
import { useBiometry } from '@/composables/useBiometry'

export default {
  name: 'CellPhoneKeypad',
  emits: ['changePin'],
  props: {
    isLogin: {
      type: Boolean,
      default: false
    },
    pinLength: {
      type: Number,
      required: true
    },
    isPinSetup: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const { isBiometryActive, isBiometryAvailable } = useBiometry()

    const keypadButtons = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: '6' },
      { label: '7', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: props.isLogin ? 'Войти без авторизации' : '', value: '' },
      { label: '0', value: '0' },
      { label: '', value: -1 }
    ]

    return {
      keypadButtons,
      isBiometryActive,
      isBiometryAvailable
    }
  }
}
</script>
<style scoped lang="scss">
.keypad {
  margin-top: 30px;
  padding: 0 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 40px;

  button {
    all: unset;
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.02em;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #002856;

    &:last-child {
      margin-right: 10px;
      font-weight: 100;
      font-size: 33px;
      background-position: center;
      background-repeat: no-repeat;
      transition: all 0.3s ease;

    }

    &:nth-child(10) {
      margin-left: 20px;
      font-size: 13px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.02em;
      text-align: left;
    }
  }
}

.backspaceDim {
  &:last-child {
    background-image: url("@/assets/uploads/icon/common/backspace-icon.svg") !important;
    background-size: auto !important;
    opacity: 0.4 !important;

    filter: brightness(0) saturate(100%) invert(11%) sepia(69%) saturate(2443%) hue-rotate(197deg) brightness(95%) contrast(105%);
  }
}

.backspaceWhite {
  &:last-child {
    background-image: url("@/assets/uploads/icon/common/backspace-icon.svg") !important;
    background-size: auto !important;
    opacity: 1 !important;

    filter: brightness(0) saturate(100%) invert(11%) sepia(69%) saturate(2443%) hue-rotate(197deg) brightness(95%) contrast(105%);
  }
}

.faceIdIcon {
  &:last-child {
    background-image: url("@/assets/uploads/icon/profile/face-id-icon-white.svg");
    background-size: contain !important;

    filter: brightness(0) saturate(100%) invert(11%) sepia(69%) saturate(2443%) hue-rotate(197deg) brightness(95%) contrast(105%);

  }
}
</style>
