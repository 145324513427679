<template>
  <div class="service-dropdown">
    <a
      @click.prevent="accordionOpen = !accordionOpen"
      class="service-dropdown__top"
    >
      <h5 class="service-dropdown__subtitle">
        {{ title }}
      </h5>

      <img
        class="service-dropdown__img"
        :class="{ open: accordionOpen }"
        src="@/assets/uploads/icon/common/arrow-back.svg"
        alt="стрелочка для открытия выпадающего текста"
      />
    </a>

    <div class="service-dropdown__content">
      <div class="service-dropdown__accordion" :class="{ open: accordionOpen }">
        <div class="service-dropdown__text">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'AccordionComponent',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    content: {
      type: String,
      required: false,
      default: ''
    }
  },

  setup () {
    const accordionOpen = ref(false)

    return {
      accordionOpen
    }
  }
}
</script>

<style scoped lang="scss">
.service-dropdown {
  margin-bottom: 6px;
  padding: 16px;

  box-shadow: 0 5px 15px 0#00000026;
  border-radius: 6px;
  background: #FFF;
}

.service-dropdown__top {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.service-dropdown__subtitle {
  color: $blue;
font-size: 16px;
font-weight: 700;
line-height: 20px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}

.service-dropdown__img {
  transform: rotate(-90deg);
  width: 9px;
  height: 14px;
  transition: transform 0.3s ease-in-out;
  filter: brightness(0) saturate(100%) invert(14%) sepia(16%) saturate(5720%) hue-rotate(187deg) brightness(102%) contrast(110%);
  &.open {
    transform: rotate(90deg);
  }
}

.service-dropdown__content {
  overflow: hidden;
  position: relative;

  & ul {
    padding-left: 16px;
  }
}

.service-dropdown__accordion {
  opacity: 0;
  position: absolute;
  transform: translateY(-120%);
  padding-top: 8px;
  transition: all 0.7s ease-in-out;

  &.open {
    opacity: 1;
    position: relative;
    transform: translateY(0);
  }
}
</style>
