<template>
  <component
    :is="tag"
    class="fixed-btn"
    :href="tag === 'a' ? href : null"
    :target="tag === 'a' ? target : ''"
  >
    <div class="fixed-btn__content-wrapper">
      <slot name="img"></slot>

      <slot name="text"></slot>
    </div>
  </component>
</template>

<script>
export default {
  name: 'FixedButton',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    tag: {
      type: String,
      required: false,
      default: 'a'
    },
    href: {
      type: String,
      default: '',
      required: false
    },
    target: {
      type: String,
      default: '',
      required: false
    }
  }
}
</script>

<style lang="scss">
.fixed-btn {
  display: block;
  @include tablet {
    display: none;
  }

  position: fixed;
  z-index: 1000;
  bottom: 93px;
  right: 14px;

  color: $blue;
  padding: 16px;
  box-shadow: 0 5px 15px 0 #0000001a;
  background: #fff;
  width: fit-content;
  border-radius: 30px;

  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
}

.fixed-btn__content-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
