<template>
  <ArrowTitle :is-app="isApp" title="Беременность"></ArrowTitle>
  <SectionWrapper stretched>
    <TheLoader v-if="loading"/>
    <div v-else class="pregnancy">
      <nav class="pregnancy__menubar">
        <button @click="isModalOpen = true" class="pregnancy__menubar-btn">
          <img
            src="@/assets/uploads/icon/common/settings.svg"
            alt="настройки"
          />
          <span>Настройки</span>
        </button>
      </nav>

      <PregnancyWeeksSwiper
        @selectWeek="selectWeek"
        :selectedWeek="selectedWeek"
        :currentWeek="currentWeek"
      />

      <PregnancyBanner
        :title="formatDate(birthday)"
        v-if="bannersControl.birthday"
        @closeBanner="bannersControl.birthday = false"
      />

      <PregnancyStories :stories="selectedWeekStories"/>

      <PregnancyInfoButtons
        @selectInfoSection="selectInfoSection"
        :buttons="pregnancyInfoButtons"
        :selectedInfoSectionId="selectedInfoSectionId"
      />

      <PregnancyInfoAboutMom
        v-if="selectedInfoSectionId === 0"
        :timeSinceConception="timeSinceConception"
        :timeUntillBirth="timeUntillBirth"
        :progressBarPercent="progressBarPercent"
        :text="selectedWeekInfo?.you"
        :doctors="doctorsList"
      />
      <PregnancyInfoRecommendations v-if="selectedInfoSectionId === 1" :text="selectedWeekInfo?.rec" :programms="selectedWeekInfo?.program"/>
      <PregnancyInfoAboutBaby v-if="selectedInfoSectionId === 2" :text="selectedWeekInfo?.baby"/>

      <PregnancySettingsModal @closeModal="isModalOpen = false" :isModalOpen="isModalOpen" :getUserId="getUserId" :userToken="userToken"/>
    </div>
  </SectionWrapper>
</template>

<script>
import PregnancyBanner from '@/components/pages/calendar/pregnancy/PregnancyBanner.vue'
import PregnancyStories from '@/components/pages/calendar/pregnancy/PregnancyStories.vue'
import PregnancyInfoButtons from '@/components/pages/calendar/pregnancy/PregnancyInfoButtons.vue'
import PregnancyInfoAboutMom from '@/components/pages/calendar/pregnancy/PregnancyInfoAboutMom.vue'
import PregnancyInfoRecommendations from '@/components/pages/calendar/pregnancy/PregnancyInfoRecommendations.vue'
import PregnancyInfoAboutBaby from '@/components/pages/calendar/pregnancy/PregnancyInfoAboutBaby.vue'
import PregnancySettingsModal from '@/components/pages/calendar/pregnancy/PregnancySettingsModal.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import PregnancyWeeksSwiper from '@/components/pages/calendar/pregnancy/PregnancyWeeksSwiper.vue'
import { computed, onActivated, onDeactivated, ref, watch, watchEffect } from 'vue'
import { useDateStore } from '@/stores/date'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
// import { getFilialInfo } from '@/api/getFilialInfo'
import router from '@/router'
import axios from 'axios'

export default {
  name: 'PregnancyCalendarView',
  components: {
    PregnancyBanner,
    SectionWrapper,
    ArrowTitle,
    TheLoader,
    PregnancyWeeksSwiper,
    PregnancyStories,
    PregnancyInfoButtons,
    PregnancyInfoAboutMom,
    PregnancyInfoRecommendations,
    PregnancyInfoAboutBaby,
    PregnancySettingsModal
  },
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup () {
    const loading = ref(false)
    const errorMsg = ref('')
    const isFormOpen = ref(false)
    const isModalOpen = ref(false)
    const conception = ref(null)
    const selectedWeek = ref(1)
    const currentWeek = ref(1)
    const selectedInfoSectionId = ref(0)
    const pregnancyInfoData = ref(null)
    const doctorsList = ref(null)
    const allStories = ref(null)
    const today = new Date()
    const oneDay = 24 * 60 * 60 * 1000

    const { dictMonthRod } = useDateStore()
    const userStore = useUserStore()
    const { getUserId, userToken, user } = storeToRefs(userStore)

    const bannersControl = ref({ birthday: true, medsCalendar: true })

    const pregnancyInfoButtons = computed(() => {
      if (!selectedWeekInfo.value?.rec) {
        return [
          { title: 'О ВАС', id: 0 },
          { title: 'ВАШ МАЛЫШ', id: 2 }
        ]
      }
      return [
        { title: 'О ВАС', id: 0 },
        { title: 'РЕКОМЕНДАЦИИ', id: 1 },
        { title: 'ВАШ МАЛЫШ', id: 2 }
      ]
    })

    const birthday = computed(() => {
      if (conception.value) {
        return new Date(
          new Date(conception.value).getTime() +
            280 * 24 * 60 * 60 * 1000
        )
      } else {
        return null
      }
    })

    const timeSinceConception = computed(() => {
      const daysSinceConception = Math.floor(
        Math.abs(new Date(conception.value).getTime() - today.getTime()) /
          oneDay
      )

      const weeksSinceConception = Math.floor(daysSinceConception / 7)

      const remainingDays = daysSinceConception % 7

      if (weeksSinceConception && remainingDays) {
        return `${weeksSinceConception} ${mapWeeksToRus(weeksSinceConception)} и ${remainingDays} ${mapDaysToRus(
          remainingDays
        )}`
      } else if (!weeksSinceConception && remainingDays) {
        return `${remainingDays} ${mapDaysToRus(remainingDays)}`
      } else if (weeksSinceConception && !remainingDays) {
        return `${weeksSinceConception} ${mapWeeksToRus(weeksSinceConception)}`
      } else return '1 день'
    })

    const timeUntillBirth = computed(() => {
      const daysUntillBirth = Math.floor(
        Math.abs(today.getTime() - new Date(birthday.value).getTime()) /
          oneDay
      )
      return `До родов примерно ${daysUntillBirth} ${mapDaysToRus(daysUntillBirth)}`
    })

    const progressBarPercent = computed(() => {
      const conceptionDate = new Date(conception.value)

      const totalDuration = birthday.value - conceptionDate
      const elapsedDuration = today - conceptionDate

      const percentageElapsed = (elapsedDuration / totalDuration) * 100

      return percentageElapsed.toFixed(2)
    })

    const selectedWeekInfo = computed(() => {
      if (pregnancyInfoData.value) {
        return pregnancyInfoData.value[selectedWeek.value - 1]
      } return null
    })

    const selectedWeekStories = computed(() => {
      if (allStories.value) {
        return pregnancyInfoData.value[selectedWeek.value - 1].stories.concat(allStories.value)
      } else {
        return null
      }
    })

    const getPregnancyInfo = async () => {
      try {
        const response = await axios.get(
          `v6/events/pregnant/?user_id=${getUserId.value}`,
          {
            headers: {
              Authorization: `Bearer ${userToken.value}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        if (response.status === 200) {
          return { weeks: response.data.data.weeks, stories: response.data.data.stories }
        } else {
          console.log('FAIL', response)
        }
      } catch (err) {
        throw new Error()
      }
    }

    const getDoctorsInDepartment = async () => {
      try {
        const response = await axios.get(
          '/v1/service/department-doctor/?department_id=24',
          {
            headers: {
              Authorization: `Bearer ${userToken.value}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        if (response.status === 200) {
          doctorsList.value = response.data.data
        }
      } catch (err) {
      }
    }

    const fetchComponentData = async () => {
      loading.value = true
      const userConception = user.value.pregnant_at
      if (userConception === null) {
        router.push({ name: 'calendar-pregnancy-settings' })
      } {
        const { weeks, stories } = await getPregnancyInfo()

        conception.value = userConception

        const weekNumber = getWeekNumber(userConception)
        selectedWeek.value = weekNumber > 0 ? weekNumber : 1
        currentWeek.value = weekNumber > 0 ? weekNumber : 1

        pregnancyInfoData.value = weeks
        allStories.value = stories

        await getDoctorsInDepartment()
        loading.value = false
      }
    }

    // const getPregnancyStories = async () => {
    //   try {
    //     const response = await axios.get(
    //       `/v6/events/pregnant/?user_id=${getUserId.value}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${userToken.value}`,
    //           'Content-Type': 'multipart/form-data'
    //         }
    //       }
    //     )
    //     console.log('STORIES RESPONSE', response)
    //     if (response.status === 200) {
    //       stories.value = response.data.data
    //     }
    //   } catch (err) {
    //   }
    // }

    const mapDaysToRus = (days) => {
      if (String(days).at(-1) === '1') return 'день'
      if (String(days).at(-1) < '5' && days !== 0) return 'дня'
      return 'дней'
    }

    const mapWeeksToRus = (days) => {
      if (String(days).at(-1) === '1') return 'неделя'
      if (String(days).at(-1) < '5') return 'недели'
      return 'недель'
    }

    const selectInfoSection = (id) => {
      selectedInfoSectionId.value = id
    }

    const selectWeek = (week) => {
      selectedWeek.value = week
    }

    const getWeekNumber = (conception) => {
      const daysSinceConception = Math.floor(
        Math.abs(new Date(conception).getTime() - today.getTime()) /
          oneDay
      )
      return Math.floor(daysSinceConception / 7)
    }

    const formatDate = (dateString) => {
      if (!dateString) return ''
      const date = new Date(dateString)

      const day = date.getDate()
      const month = date.getMonth()
      const year = date.getFullYear()

      const monthName = dictMonthRod[month]
      const formattedDate = `${day} ${monthName.toLowerCase()} ${year}`
      return formattedDate
    }

    watch(
      () => selectedWeekInfo.value,
      () => {
        if (!selectedWeekInfo.value?.rec) {
          selectedInfoSectionId.value = 0
        }
      }
    )

    watch(user, async () => {
      if (user) {
        await fetchComponentData()
      }
    })

    onActivated(async () => {
      await fetchComponentData()
    })

    onDeactivated(() => {
      isModalOpen.value = false
      loading.value = false
    })

    watchEffect(() => {
      // console.log('SELECTED WEEK OF PREGNANCY', selectedWeek.value)
    })

    return {
      bannersControl,
      birthday,
      pregnancyInfoButtons,
      selectedInfoSectionId,
      loading,
      isFormOpen,
      errorMsg,
      conception,
      currentWeek,
      selectedWeek,
      timeSinceConception,
      timeUntillBirth,
      progressBarPercent,
      selectedWeekInfo,
      doctorsList,
      isModalOpen,
      getUserId,
      userToken,
      selectInfoSection,
      formatDate,
      selectWeek,
      selectedWeekStories
    }
  }
}
</script>

<style scoped lang="scss">
.pregnancy {
  padding-left: 2px;
  padding-right: 2px;
}
.pregnancy__menubar {
  display: flex;
  justify-content: end;
  margin-bottom: 24px;
}
.pregnancy__menubar-btn {
  display: flex;
  all: unset;
  img {
    margin-top: 1px;
  }
  span {
    cursor: pointer;
    text-decoration: underline;
  }
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #002856;
}

.pregnancy-banner {
  height: 80px !important;
}
</style>
