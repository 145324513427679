
import { authenticatedApiCall } from '../authenticatedApiCall'

export const getFinancePaymentLink = async (paymentData) => {
  const url = 'v2/pay/element/'
  const params = {
    is_phone: paymentData.isPhone,
    is_mir: paymentData.isMir,
    reserve_id: paymentData.reserve_id
  }
  const data = {
    legal_entity_id: paymentData.legalEntityId,
    summ: paymentData.summ
  }

  const response = await authenticatedApiCall({ url, params, data, method: 'post' })

  if (response.data.status !== 'ok') {
    throw new Error(
      response.data.message ?? 'Произошла ошибка, попробуйте позже'
    )
  }

  return response.data.data
}
