<template>
    <div class="calendar-inline" :key="componentKey">
      <div class="calendar-inline__calendar">
        <VueDatePicker
          :day-names="['Пон', 'Вт', 'Ср', 'Чт', 'Пят', 'Субб', 'Вскр']"
          :preventMinMaxNavigation="preventMinMaxNavigation"
          :clearable="false"
          :minDate="minDate"
          :maxDate="maxDate"
          :allowedDates="allowedDates"
          :enableTimePicker="false"
          :autoApply="true"
          :inline="true"
          month-name-format="long"
          hide-offset-dates
          modelType="yyyy-MM-dd"
          format="dd/MM/yyyy"
          v-model="currentDateModel"
          locale="ru-RU"
        >
       </VueDatePicker>
      </div>
    </div>
  </template>

<script>
import { computed, ref, toRefs, watch } from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker'

export default {
  name: 'DatepickerInline',
  emits: ['update:currentDay'],
  components: { VueDatePicker },
  props: {
    currentDay: String,
    allowedDates: Array,
    isMaxToday: Boolean,
    noMaxMinDates: {
      type: Boolean,
      required: false,
      default: false
    },
    preventMinMaxNavigation: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  setup (props, { emit }) {
    const { currentDay, allowedDates, noMaxMinDates } = toRefs(props)
    const componentKey = ref(0)

    const minDate = computed(() => {
      if (noMaxMinDates.value) return null
      if (!allowedDates.value) return null
      return allowedDates.value[0]
    })

    const maxDate = computed(() => {
      if (noMaxMinDates.value) return null
      if (!allowedDates.value) return null
      return allowedDates.value[allowedDates.value.length - 1]
    })

    const forceRender = () => {
      componentKey.value += 1
    }

    const currentDateModel = computed({
      get () {
        return currentDay.value
      },

      set (value) {
        emit('update:currentDay', value)
      }
    })

    watch(allowedDates, () => {
      forceRender()
    })

    return {
      minDate,
      maxDate,
      currentDateModel,
      componentKey
    }
  }
}
</script>

  <style scoped lang="scss">
  .calendar-inline {
    margin-bottom: 40px;
    padding: 0;
    position: static;
    overflow-y: inherit;
    overflow: inherit;

    @include tablets-md {
      margin-bottom: 0;
    }

    &__title {
      margin-bottom: 10px;
      color: #002856;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .input-slot-image {
    max-width: 12px;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 0;
    top: 0;
  }
  </style>

  <style lang="scss">
  // Стили для библиотеки
  .calendar-inline {
    --dp-font-family: Circe-Regular, sans-serif;

    .dp__input_wrap {
      height: 44px;
    }

    .dp__input {
      padding: 15px 10px;
      border-radius: 8px;
      border: 1px solid #bac7de;
      font-size: 14px;
    }

    .dp__input::placeholder {
      color: $blue-light;
    }

    .dp__input_icon {
      left: auto;
      right: 10px;
      top: 16px;
    }

    .modal-schedule__content {
      padding: 90px 170px;
    }

    .dp__inner_nav {
      background: transparent;
      color: #757575;
    }

    .dp__calendar {
      padding: 20px;
    }

    .dp__month_year_row {
      padding: 35px 20px;
    }

    .dp__calendar_item {
      margin: 5px;
    }

    .dp__today {
      background-color: #bac7de;
      color: #000;
      border: 0 !important;
    }

    .dp__month_year_select {
      font-weight: 700;
      font-size: 20px;
      color: #323232;
    }

    .dp__pointer {
      font-size: 14px;
      font-weight: 350;
      line-height: 14px;
      text-align: left;
      color: $blue;
    }

    .dp__cell_inner {
      border-radius: 50%;
      height: 48px;
      width: 48px;
    }

    .dp__active_date {
      border: 1px solid #002856;
      background: #fff;
    }

    .dp__calendar .dp__cell_inner.dp__today.dp__active_date {
      background-color: #bac7de !important;
      border: 1px solid #002856 !important;
    }

    .dp__calendar_header_item {
      font-weight: 400;
      font-size: 12px;
      width: 48px;

      color: #666666;
    }

    .dp__month_year_wrap {
      justify-content: center;
      gap: 15px;
    }

    .dp__outer_menu_wrap {
      border-radius: 6px;
      box-shadow: 0px 10px 20px 0px rgba(51, 51, 51, 0.15);
    }

    .dp__menu {
      border: unset;
    }

    .dp__month_year_select {
      flex: 0;
    }

    .dp__today {
      color: #fff !important;
    }

    .dp__calendar {
      padding: 0;
    }

    .dp__calendar_header_separator {
      width: 100%;
      height: 0;
      background: var(--dp-border-color);
    }

    .dp__calendar_header_item {
      font-size: 16px;
    }

    .dp__cell_inner {
      border-radius: 50%;
      height: 48px;
      width: 48px;
    }

    @media (max-width: 900px) {
      .dp__cell_inner {
        height: 32px;
        width: 32px;
      }

      .dp__calendar_item {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @media (max-width: 575px) {
      .dp__calendar_item {
        margin: 5px;
      }
    }
  }
  </style>
