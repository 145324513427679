<template>
    <Teleport to="body">
      <Transition name="overlay">
        <div v-if="isModalOpen" class="waiting-list-modal__overlay">
          <Transition name="wrapper" appear>
            <div v-if="isModalOpen" class="waiting-list-modal__wrapper">
              <div class="waiting-list-modal__content">
                <img
                  v-if="!noCloseBtn"
                  @click="$emit('closeModal')"
                  class="waiting-list-modal__exit-btn"
                  src="@/assets/uploads/icon/common/exit-cross.svg"
                  alt="выйти"
                />
                <slot></slot>
              </div>
            </div>
          </Transition>
        </div>
      </Transition>
    </Teleport>
  </template>

<script>

export default {
  name: 'ModalBlueBgWrapper',
  emits: ['closeModal'],
  props: {
    isModalOpen: Boolean,
    noCloseBtn: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup () {
    return {

    }
  }
}
</script>

  <style scoped lang="scss">
  .waiting-list-modal__overlay {
    position: fixed;
    background: #00285680;
    backdrop-filter: blur(1px);
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 201;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .waiting-list-modal__wrapper {
    z-index: 202;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    left: 50%;
    transition: left 0.3s;
  }
  .waiting-list-modal__content {
    position: relative;
    max-height: calc(100vh - 80px);
    width: 342px;
    overflow-y: auto;
    box-shadow: 0 5px 15px 0 #00000026;
    background: #ffffff;
    border-radius: 6px;
    padding-top: 24px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .waiing-list-modal__header {
    text-align: center;
    color: #002856;

    h1 {
      font-size: 20px;
      font-weight: 700;
      line-height: 22px;
    }
    p {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.02em;
    }
  }
  .waiting-list-modal__exit-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    filter: brightness(0) saturate(100%);
    cursor: pointer;
  }

  .waiting-list-modal__form {
    margin-top: 24px;
    margin-bottom: 16px;
    padding-left: 29px;
    padding-right: 29px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .waiting-list-modal__form-item {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 350;
    line-height: 14px;
    text-align: left;
    label {
      color: #002856;
    }
    input {
      color: #002856;
      border: 1px solid #bac7de;
      border-radius: 9px;
      padding-left: 13px;
      height: 40px;
    }
    small {
      font-size: 12px;
      color: #ed3319;
    }
  }
  .waiting-list-modal__form-datepicker {
    width: 100%;
    border-radius: 9px;
  }
  .waiting-list-modal__form-toggle-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  .waiting-list-modal__form-select {
    width: 100%;
    appearance: none;
    font-size: 14px;
    font-weight: 350;
    line-height: 14px;
    text-align: left;
    color: #002856;
    border: 1px solid #bac7de;
    border-radius: 9px;
    padding-left: 13px;
    height: 40px;

    &:focus-visible {
      outline: none;
    }
  }
  .arrow {
    position: relative;
    width: 100%;
  }
  .arrow:before {
    position: absolute;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-image: url('~@/assets/uploads/icon/common/arrow-down.svg');
    background-repeat: no-repeat;
    top: 50%;
    right: 7px;
    background-position: center;
    transform: translateY(-50%);
  }
  .arrow--disabled:before {
    filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(0%) hue-rotate(194deg) brightness(83%) contrast(88%);
  }

  .checkbox-wrapper {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #002856;
    small {
      font-size: 12px;
      color: #ed3319;
    }
  }

  .overlay-enter-active,
  .overlay-leave-active {
    transition: opacity 0.3s ease;
  }

  .overlay-enter-from,
  .overlay-leave-to {
    opacity: 0;
  }

  .wrapper-enter-active,
  .wrapper-leave-active {
    transition: transform 0.4s ease;
  }

  .wrapper-enter-from {
    transform: translateX(400%);
  }
  .wrapper-enter-to {
    transform: 0;
  }

  .wrapper-leave-from {
    transform: 0;
  }

  .wrapper-leave-to {
    transform: translateX(400%);
  }
  </style>
  <style>
  .waiting-list-modal__form-datepicker .mx-input-wrapper {
    height: 40px;
    input {
      border-radius: 9px;
    }
  }
  .ndfl-checkbox .checkbox__rules {
    margin-bottom: 6px;
  }
  .waiting-list-modal__form-select--disabled {
    color: #c9c9c9 !important;
    border-color: #c9c9c9 !important;
  }
  </style>
