
import { authenticatedApiCall } from '../authenticatedApiCall'

export const postProgramCheckup = async (checkupInfo) => {
  const url = 'v6/checkup/send/'
  const data = {
    gender_id: checkupInfo.genderId,
    type_id: checkupInfo.typeId,
    age: checkupInfo.age,
    checkup: checkupInfo.checkup
  }

  const response = await authenticatedApiCall({ url, data, method: 'post' })

  if (response.data.status !== 'ok') {
    throw new Error(
      response.data.message ?? 'Произошла ошибка, попробуйте позже'
    )
  }

  return response.data.data
}
