import axios from 'axios'

export const getUserToken = async (phone, code) => {
  const deviceUUID = window?.device?.uuid
    ? new URLSearchParams({
      device_uuid: window?.device?.uuid
    }).toString()
    : ''

  const response = await axios.get('/v1/auth/login?' + deviceUUID, {
    headers: {
      Authorization: `Basic ${btoa(phone + ':' + code)}`,
      'Cache-Control': 'no-cache'
    }
  })

  if (response.data.status === 'ok') {
    return response.data.data.token
  } else {
    const errorText =
    response.data?.message ?? 'Нет доступа к серверу. Попробуйте позже.'
    throw new Error(errorText)
  }
}
