<template>
  <div class="password-input">
    <input
        ref="input"
        v-model="model"
        :maxlength="digitCount"
        type="text"
        aria-describedby="password-description"
        autocomplete="one-time-code"
        readonly
        inputmode="numeric"
        pattern="[0-9]*"
        :autofocus="isAutofocus"
    />
    <div class="circle-container" :class="{'setPinError': error, 'loginPinError': error && digitCount <= 6}" aria-hidden="true" role="presentation">
      <div
          v-for="(digit, index) in digitCount"
          :key="digit"
          class="circle"
          :class="{filled: isValueFilled(index)}"
      >
      </div>
    </div>
  </div>
</template>

<script>

import { computed, onMounted, ref, toRefs, watch } from 'vue'

export default {
  emits: ['isCompleted', 'update:modelValue'],
  name: 'OTPSingleInput',
  props: {
    digitCount: {
      type: Number,
      required: false,
      default: 4
    },
    modelValue: {
      type: String,
      required: false,
      default: null
    },
    isAutofocus: {
      type: Boolean,
      required: false,
      default: true
    },
    error: Boolean
  },
  setup (props, { emit }) {
    const { modelValue, digitCount, isAutofocus } = toRefs(props)
    const input = ref(null)
    const model = computed({
      get () {
        return modelValue.value
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })

    const modelToArray = computed(() => {
      return modelValue.value.split('')
    })

    const isValueFilled = (index) => {
      return !!modelToArray.value[index] ?? false
    }

    watch(modelValue, () => {
      input.value.focus()
      if (!modelValue.value) return
      if (modelValue.value.length === digitCount.value) {
        emit('isCompleted')
      }
    })

    onMounted(() => {
      if (isAutofocus.value) {
        input.value.focus()
      }
    })

    return {
      model,
      modelToArray,
      isValueFilled,
      input
    }
  }
}
</script>
<style scoped lang="scss">
.password-input {
  position: relative;
  display: flex;
  align-items: center;

}

.password-input input {
  opacity: 0;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.circle-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 25px;
  padding: 0 60px;
}

.circle {
  width: 16px;
  height: 16px;
  background: #EEF1F3;
  border-radius: 100%;

  @include desktop {
    background: #E1E8F4 ;
  }
}

.circle.filled {
  width: 16px;
  height: 16px;
  background-color: #F1842C;
  border-radius: 100%;
}

.setPinError {
  :nth-last-child(-n+6) {
    border: 1px solid #FF1F00;
    background-color: #FFFFFF80;
  }
}

.loginPinError {
  div {
    border: 1px solid #FF1F00;
    background-color: #FFFFFF80;
  }
}
</style>
