import axios from 'axios'

export const getIsPin = async (phone) => {
  const response = await axios.get('/v6/element/user/is-pin', {
    params: {
      phone: phone,
      device_uuid: window?.device?.uuid
    }
  })
  if (response.data.status === 'ok') {
    return response.data.data.pin
  } else {
    throw new Error()
  }
}
