<template>
  <div class="steps">
    <ul class="steps__list" :class="{'two-stages': stages?.length === 2}">
      <li
        class="steps__item"
        v-for="(stage, ix) of stages"
        :key="stage"
        :class="{ active: currentStage >= ix + 1 }"
      >
        <div
          class="steps__line"
          :class="{ disabled: ix === 0, small: isTelemed }"
        ></div>
        <div class="steps__wrapper" :class="{ disabled: ix === 0 }">
          <div class="steps__circle">
            <div class="steps__number">
              {{ ix + 1 }}
            </div>
          </div>
          <h3
            v-if="currentStage === ix + 1"
            class="steps__title"
            v-html="stage"
          ></h3>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue'

export default {
  name: 'ScheduleStages',
  props: {
    currentStage: {
      type: Number,
      required: true
    },
    isTelemed: {
      type: Boolean,
      required: false,
      default: false
    },
    isTelemedFinalStage: {
      type: Boolean,
      required: false,
      default: false
    },
    isProgram: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const { isTelemed, isTelemedFinalStage, isProgram } = toRefs(props)
    const stages = computed(() => {
      if (isTelemed.value) {
        if (isTelemedFinalStage.value) {
          return [
            `Выбор направления
          <br>
           или врача`,
            'Выбор даты, врача и времени',
            'Оплата записи'
          ]
        }
        return [
          `Выбор направления
          <br>
           или врача`,
          'Выбор даты, врача и времени',
          'Подтвердите запись'
        ]
      }

      if (isProgram.value) {
        return [
        `Мои
        <br>
         предпочтения`,
        'Готовый чек-ап'
        ]
      }

      return [
        `Выбор направления
        <br>
         или врача`,
        'Выбор даты, врача и времени',
        'Подтверждение записи'
      ]
    })

    return {
      stages
    }
  }
}
</script>

<style scoped lang="scss">
.steps {
  margin-bottom: 25px;

  &__list {
    padding: 5px 0;
    display: flex;
    align-items: center;
    gap: 20px;

    @include phone-xs {
      gap: 8px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__line {
    min-width: 32px;
    height: 1px;
    flex: 1 1 auto;
    background: #bac7de;

    &.small {
      min-width: 16px;
    }

    @include tablets-md {
    }

    &.disabled {
      display: none;
    }
  }

  &__item.active &__circle {
    background: #002856;
  }

  &__item.active &__title {
    color: #002856;
  }

  &__item.active &__line {
    background: #002856;
  }

  &__item.active &__wrapper:not(&__wrapper.disabled) {
    animation: large-to-small 0.5s ease-in-out;
  }

  &__circle,
  &__title,
  &__line {
    transition: all 0.3s ease-in-out;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    transition: all 0.3s ease-in-out;

    @include tablets-md {
      //align-items: start;
    }

    @include phone-xs {
      align-items: center;
    }
  }

  &__circle {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 48px;
    width: 40px;
    height: 40px;
    background: #bac7de;
  }

  &__number {
    color: #fff;
    text-align: center;
    font-size: 24px;
    line-height: normal;
  }

  &__title {
    //flex: 1 0 auto;
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;
    color: #bac7de;

    @include tablets-md {
      font-size: 16px;
    }

    @include phone-xs {
      font-size: 14px;
      max-width: 124px;
    }
  }
}

@keyframes large-to-small {
  0% {
    transform: scale(100%);
  }

  25% {
    transform: scale(102%);
  }

  50% {
    transform: scale(104%);
  }

  75% {
    transform: scale(102%);
  }

  100% {
    transform: scale(100%);
  }
}

.two-stages {
  justify-content: space-between;
}

// .steps__wrapper.disabled {
//   opacity: 0.3;
// }

</style>
