<script>
export default {
  props: {
    bgColor: String,
    text: String
  }
}
</script>

<template>
  <div class="question-card"># {{ text }}</div>
</template>

<style lang="scss" scoped>
.question-card {
  background-color: v-bind(bgColor);
  border-radius: 8px;
  padding: 16px 24px 16px 24px;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;

  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  cursor: pointer;
}
</style>
