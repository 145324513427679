import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'

export const authDevelopment = async () => {
  if (process.env.NODE_ENV !== 'development') return

  const userStore = useUserStore()
  const { userTokenStorage } = storeToRefs(userStore)

  if (userTokenStorage.value) {
    userStore.setUserToken(userTokenStorage.value)
    await userStore.setUser(userTokenStorage.value)
  }
}
