<script>
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { useMenuStore } from '@/stores/MenuStore'
import { useUserStore } from '@/stores/UserStore'
import { useDrawerStore } from '@/stores/DrawerStore'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
export default {
  components: { CustomButton },
  name: 'MenuPatientList',
  setup () {
    const menuStore = useMenuStore()
    const { closeMobileMenu } = menuStore
    const drawerStore = useDrawerStore()
    const { closeDrawer } = drawerStore
    const userStore = useUserStore()
    const { changeUserToFamilyMember } = userStore
    const router = useRouter()
    const { user, userPrimaryId } = storeToRefs(userStore)
    const iconColors = {
      man: '#D1F9EF',
      woman: '#FBCDCD',
      boy: '#F5FBCD',
      girl: '#F5E6FC',
      ufo: '#E4FCE6'
    }

    const userColor = (icon) => {
      return {
        backgroundColor: iconColors[icon] || ''
      }
    }

    const changePatient = (card) => {
      changeUserToFamilyMember(card)
      closeMobileMenu()
      closeDrawer()
    }

    const addNewPatient = () => {
      closeMobileMenu()
      router.push({ path: '/profile/family' })
      closeDrawer()
    }

    return { userColor, changePatient, addNewPatient, user, userPrimaryId }
  }
}
</script>

<template>
  <ul class="header__user-list">
    <li
      v-for="card in user.family_cards"
      :key="card.id"
      @click="changePatient(card)"
      :class="{ current: card.id === user.id }">
      <div v-if="card.photo" class="header__user-icon-profile">
        <img :src="card.photo" alt="фото пользователя" />
      </div>

      <div v-else class="header__user-icon" :style="userColor(card.icon)">
        <img
          :src="require(`@/assets/uploads/icon/profile/${card.icon}.svg`)"
          alt="фото пользователя" />
      </div>

      <div class="header__user-name">
        <p>{{ card.first_name }} {{ card.last_name }}</p>
        <span v-if="card.card">
          <span v-if="card.id === userPrimaryId">Основной профиль.</span>
          № {{ card.card }}
        </span>
      </div>
    </li>

    <CustomButton transparent @click.prevent="addNewPatient">
      <template #img>
        <img
          src="@/assets/uploads/icon/common/simple-plus-icon.svg"
          alt="plus" />
      </template>
      Добавить пациента
    </CustomButton>
  </ul>
</template>

<style lang="scss" scoped>
.header__user-list {
  list-style-type: none;

  li {
    padding: 16px 0;
    border-bottom: 1px solid #e1e8f4;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
.header__user-icon-profile {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;
  @include desktop {
    height: 40px;
    width: 40px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.header__user-icon {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  overflow: hidden;
  @include desktop {
    height: 40px;
    width: 40px;
  }
}

.header__user-list {
  li {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  .header__user-name {
    p {
      color: #002856;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
    }

    span {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #002856;
    }
  }
}

.current {
  > div:first-of-type {
    border: 1px solid #f1842c;
  }
}
</style>
