<script>
import { useUserStore } from '@/stores/UserStore'
import {
  mobileMenuButtons,
  mobileMenuButtonsDoctor
} from '../../../constants/mobileMenuButtons'
import MenuSmallTile from './MenuSmallTile.vue'
import MenuWideTile from './MenuWideTile.vue'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useAppInfoStore } from '@/stores/AppInfoStore'
export default {
  name: 'MenuTilesList',
  components: { MenuSmallTile, MenuWideTile },
  setup () {
    const userStore = useUserStore()
    const { isAdmin } = storeToRefs(userStore)

    const appInfoStore = useAppInfoStore()
    const { appInfo } = storeToRefs(appInfoStore)

    const mobileMenuButtonsFiltererd = computed(() => {
      return mobileMenuButtons.filter((b) => {
        if (b.title === 'Ассистент') {
          if (isAdmin.value) return true
          return appInfo.value?.is_ai_assist
        } else {
          return true
        }
      })
    })

    return {
      mobileMenuButtonsDoctor,
      mobileMenuButtonsFiltererd,
      mobileMenuButtons

    }
  }
}
</script>

<template>
  <div class="tiles-wrapper">
    <template v-for="tile in mobileMenuButtonsFiltererd" :key="tile.smallIconName">
      <MenuWideTile
        v-if="tile.isWide"
        :to="tile.to"
        :smallIconName="tile.smallIconName"
        :bgColor="tile.bgColor"
        :title="tile.title"
        :subtitle="tile.subtitle"
        :bigIconName="tile.bigIconName" />

      <MenuSmallTile
        v-if="!tile.isWide"
        :to="tile.to"
        :smallIconName="tile.smallIconName"
        :bgColor="tile.bgColor"
        :title="tile.title"
        :subtitle="tile.subtitle" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.tiles-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-bottom: 24px;
}
</style>
