<script>
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ErrorText from '@/components/ui/ErrorText.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import { computed, inject, onActivated, ref, watch } from 'vue'
import GlucoseList from '@/components/pages/calendar/glucose/GlucoseList.vue'
import RangeDate from '@/components/ui/form/RangeDate.vue'
// import GlucoseFilters from '@/components/pages/calendar/glucose/GlucoseFilters.vue'
import FixedButton from '@/components/ui/buttons/FixedButton.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { authApi } from '@/api/authApi'
import { useUserGlucoseStore } from '@/stores/UserGlucoseStore'
import router from '@/router'
export default {
  name: 'GlucoseCalendarView',
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  components: {
    FixedButton,
    ArrowTitle,
    TheLoader,
    ErrorText,
    SectionWrapper,
    RangeDate,
    GlucoseList,
    CustomButton
    // GlucoseFilters
  },

  setup () {
    // MAGIC NUMBERS !!!

    // status_id -
    // 1 - Внести измерения / Пропустить
    // 5 - Измерено / Отменить
    // 8 - Пропущено / Внести данные

    // type_id -
    // 1 - уровень сахара
    // 2 - еда
    // 3 - активность

    const userGlucoseStore = useUserGlucoseStore()
    const userStore = useUserStore()
    const { getUserId } = storeToRefs(userStore)
    const { setMeasureUnit, setSetup } = userGlucoseStore
    const { glucoseSetup } = storeToRefs(userGlucoseStore)
    const dayjs = inject('dayjs')
    const today = dayjs().format('YYYY-MM-DD')
    // const dayAfterOneWeak = dayjs().add(7, 'day').format('YYYY-MM-DD')
    const loading = ref(true)
    const error = ref('')
    const rangeDates = ref([today, today])
    const errorMsg = ref('')
    const selectPressureFilter = ref('list')
    const glucoseList = ref([])

    const pdfLink = computed(() => {
      if (!glucoseSetup.value) return ''
      const parth = 'https://lk.k31.ru/api/v6/events/diabet/export/'
      const hash = glucoseSetup.value.hash
      const params = new URLSearchParams({
        user_id: getUserId.value,
        date_from: rangeDates.value[0],
        date_to: rangeDates.value[1],
        hash: hash
      }).toString()

      return `${parth}?${params}`
    })

    const getUserGlucose = async () => {
      if (getUserId.value) {
        const params = new URLSearchParams({
          user_id: getUserId.value,
          date_from: rangeDates.value[0],
          date_to: rangeDates.value[1]
        }).toString()
        try {
          loading.value = true
          const [err, response] = await authApi(`/v6/events/diabet/?${params}`)

          if (err) {
            glucoseList.value = []
            error.value = err
            return
          }
          if (response.status === 'ok') {
            error.value = ''
            if (response.data.setup) {
              setMeasureUnit(response.data.setup.unit_id)
              const isScheduled = response.data.setup.times.length ? '1' : ''
              setSetup(response.data.setup, isScheduled)
              glucoseList.value = response.data.list
            } else {
              router.push('calendar-glucose-welcome')
            }
          }
        } catch (err) {
          error.value = err
        } finally {
          loading.value = false
        }
      }
    }

    const updateGlucose = async () => {
      await getUserGlucose()
    }

    watch(getUserId, async () => {
      await getUserGlucose()
    })

    watch(rangeDates, async () => {
      await getUserGlucose()
    })

    onActivated(async () => {
      await getUserGlucose()
    })

    return {
      loading,
      errorMsg,
      glucoseList,
      selectPressureFilter,
      rangeDates,
      error,
      glucoseSetup,
      pdfLink,
      updateGlucose
    }
  }
}
</script>

<template>
  <ArrowTitle title="Данные и измерения" :is-app="isApp" />
  <SectionWrapper stretched>
    <TheLoader v-if="loading" />
    <ErrorText v-if="error && !loading" :error="error" :show-btn="false" />
    <div v-if="!loading" class="glucose">

    <FixedButton
      to="calendar-glucose-menu"
      tag="router-link"
    >
      <template #text>Добавить</template>
      <template #img>
        <img src="@/assets/uploads/icon/common/simple-plus-icon.svg" alt="plus" />
      </template>
    </FixedButton>

      <div class="glucose__wrapper">
        <custom-button
          to="calendar-glucose-menu"
          tag="router-link"
          class="calendar-glucose__add-btn"
          marginBottom="0"
          small
          white
        >
          <template #default> добавить Новое измерение</template>
          <template #img>
            <img
              src="@/assets/uploads/icon/common/plus-rounded.svg"
              alt="plus"
            />
          </template>
        </custom-button>

        <div class="glucose-settings">
          <a :href="pdfLink" target="_blank" class="glucose-settings-pdf">
            <img
              src="@/assets/uploads/icon/common/pdf-download.svg"
              alt="иконка"
            />
            <span> Скачать в pdf </span>
          </a>
          <button
            @click="$router.push('calendar-glucose-settings')"
            class="glucose-settings-btn"
            type="button"
          >
            <img
              src="@/assets/uploads/icon/common/settings.svg"
              alt="настройки"
            />
            <span> Настройки </span>
          </button>
        </div>
        <!-- <GlucoseFilters
          v-model:filter="selectPressureFilter"
        /> -->

        <RangeDate v-model:dates="rangeDates" />

        <GlucoseList
          :glucoseList="glucoseList"
          @updateGlucose="updateGlucose"
          @openAddForm="isAddMeasurmentOpen = true"
        />

        <p v-if="glucoseList.length === 0">В этих датах у Вас нет измерений.</p>
      </div>
    </div>

  </SectionWrapper>
</template>

<style scoped lang="scss">
.glucose__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.glucose-settings-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #002856;
}
.glucose-settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.glucose-settings-pdf {
  display: flex;
  align-items: center;
  gap: 5px;
  background: unset;
  border: unset;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.glucose-settings-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  background: unset;
  border: unset;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.calendar-glucose__add-btn {
  display: none;

  @include tablet {
    display: block;
  }

}
</style>
