import axios from 'axios'

// code - как пин, так и смс
export const loginUser = async (phone, code) => {
  const response = await axios.post('/v6/element/user/auth-otp', {
    phone,
    code,
    device_uuid: window?.device?.uuid
  })

  return response
}
