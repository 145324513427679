<template>
  <a v-if="url && !pathName" :href="url" target="_blank" class="menu__link">
    <img
      class="menu__img"
      :class="{ locked: locked }"
      :src="require(`@/assets/uploads/images/sidebar/${imgName}.svg`)"
      :alt="title"
    />
    <h6 class="menu__subtitle" :class="{ locked: locked }">
      {{ title }}
    </h6>
    <img
      v-if="locked"
      src="@/assets/uploads/images/sidebar/padlock2.svg"
      alt="закрыто"
    />
  </a>
  <router-link
    v-if="!whatsApp && !url"
    :to="pathName"
    class="menu__link"
    :class="{ 'router-link-active': $route.path === secondActivePath }"
  >
    <img
      class="menu__img"
      :class="{ locked: locked }"
      :src="require(`@/assets/uploads/images/sidebar/${imgName}.svg`)"
      :alt="title"
    />
    <h6 class="menu__subtitle" :class="{ locked: locked }">
      {{ title }}
    </h6>
    <img
      v-if="locked"
      src="@/assets/uploads/images/sidebar/padlock2.svg"
      alt="закрыто"
    />
  </router-link>
  <a
    v-if="whatsApp"
    href="https://api.whatsapp.com/send?phone=79162591131"
    target="_blank"
    class="menu__link"
    @click="sendMetrika"
  >
    <img
      class="menu__img"
      :class="{ locked: locked, 'no-filter': whatsApp }"
      :src="require(`@/assets/uploads/images/sidebar/${imgName}.svg`)"
      :alt="title"
    />
    <h6 class="menu__subtitle" :class="{ locked: locked }">
      {{ title }}
    </h6>
    <img
      v-if="locked"
      src="@/assets/uploads/images/sidebar/padlock2.svg"
      alt="закрыто"
    />
  </a>
</template>

<script>
import { useYandexMetrikaStore } from '@/stores/YandexMetrikaStore'

export default {
  name: 'MenuItem',
  props: {
    title: {
      type: String,
      require: true
    },
    imgName: {
      type: String,
      require: true
    },
    pathName: {
      type: String,
      require: false,
      default: '#'
    },
    locked: {
      type: Boolean,
      required: false,
      default: false
    },
    whatsApp: {
      type: Boolean,
      required: false,
      default: false
    },
    url: {
      type: String,
      required: false,
      default: ''
    },
    secondActivePath: {
      type: String,
      required: false,
      default: ''
    }
  },

  setup (props) {
    const metrikaStore = useYandexMetrikaStore()
    const sendMetrika = () => {
      metrikaStore.sendGoal('whatsapp')
    }

    return {
      sendMetrika
    }
  }
}
</script>

<style scoped lang="scss">
.menu__link {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 14px;
  padding: 10px 15px;

  @include desktop {
    box-shadow: none;
    border-radius: 0;
    padding: 10px 15px;
    &.router-link-active {
      border-radius: 6px;
      background: #e1e8f4;
      padding: 10px 15px;

      h6 {
        background: #e1e8f4;
      }
    }
  }

  & img {
  }
}

.menu__subtitle {
  margin-bottom: 0;
  background-color: #ffffff;
  color: $blue;
  font-family: Circe;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.32px;
  margin-right: auto;

  &.locked {
    color: $blue-light;
  }
}

.menu__img {
  filter: invert(11%) sepia(24%) saturate(7018%) hue-rotate(200deg)
    brightness(94%) contrast(101%);
  width: 20px;
  height: 20px;

  &.locked {
    filter: invert(100%) sepia(79%) saturate(1852%) hue-rotate(177deg)
      brightness(89%) contrast(96%);
  }

  &.no-filter {
    filter: unset;
  }
}
</style>
