export const gosuslugiListner = (endpoint) => {
  const promise = new Promise(function (resolve) {
    function redirectToGosuslugi (event) {
      window.removeEventListener('message', redirectToGosuslugi)
      resolve(event)
    }

    window.addEventListener('message', redirectToGosuslugi)
  })

  window.open(endpoint, 'oauth:k31', 'location=no,toolbar=yes')

  return promise
}
