<script>
export default {
  name: 'BannerItem',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    color: {
      type: String,
      required: false,
      default: ''
    },
    img: {
      type: String,
      required: false,
      default: ''
    },
    action: {
      type: String,
      required: false,
      default: ''
    },
    textAsButton: {
      type: Boolean,
      required: false,
      default: false
    },
    isActionExternal: {
      type: Boolean,
      required: false,
      default: false
    },
    type: String
  },
  setup (props) {
    const colors = { blue: '#BAC7DE', pink: '#F5E6FC', purple: '#FBCDCD', orange: '#FBE1CD', red: '#FBCDCD', yellow: '#F5FBCD', green: '#D1F9EF' }
    const bannerColor = colors[props.color]

    return {
      bannerColor
    }
  }
}
</script>

<template>
  <div  class="banner">
    <component :is="isActionExternal ? 'a' : 'router-link'" :to="action" :href="action" :target="isActionExternal ? '_blank' : null">
    <div :style="{backgroundColor: bannerColor}" class="banner__wrapper">
      <div class="banner-text">
          <h4 class="banner-text__title">{{ title }}</h4>
          <div class="banner-text__desc-wrapper">
            <img
              v-if="subtitle === 'Имеется задолженность'"
              class="finance-info-img"
              src="@/assets/uploads/icon/common/payment-warning.svg"
              alt="важно"
            />
          <p class="banner-text__desc" :class="{'banner-text__desc--btn': textAsButton}">
            {{ subtitle }}
          </p>
          </div>
      </div>
      <div class="banner__picture">
        <img class="banner__picture-img" :src="img" alt="картинка баннера">
      </div>
    </div>
  </component>
  </div>
</template>

<style scoped lang="scss">

.banner__wrapper {
  color: #002856;
  height: 109px;
  display: flex;
  border-radius: 6px;
  border: 2px solid #00000000;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.5px #F1842C;

}

.banner-text {
  padding-top: 12px;
  padding-left: 10px;
  position: relative;
  width: 55%;
  flex: 1;
}

.banner-text__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  margin-bottom: 8px;
}

.banner-text__desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  vertical-align: baseline;
}

.banner__picture {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

    padding-top: 5px;
    overflow: hidden;
}

.banner__picture-img {

}

.banner-text__desc-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}
.finance-info-img {

}

.banner-text__desc--btn {
  background-color: #FFF;
  border-radius: 16px;
  padding: 8px 12px;
}
</style>
