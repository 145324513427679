import { defineStore, storeToRefs } from 'pinia'
import { ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useMenuStore } from './MenuStore'

export const useDrawerStore = defineStore('bottomDrawer', () => {
  const route = useRoute()
  const menuStore = useMenuStore()
  const { isMobileMenuOpen } = storeToRefs(menuStore)

  const isDrawerOpen = ref(false)
  const drawerTypeOpened = ref('')
  const drawerTitle = ref('')
  const drawerSubtitle = ref('')

  const updateStatusBarColor = () => {
    const statusBar = window?.StatusBar
    if (!statusBar) return

    if (route.name === 'home') {
      if (isMobileMenuOpen.value) {
        statusBar.backgroundColorByHexString('#7F93AA')
      } else {
        statusBar.backgroundColorByHexString('#625FA5')
      }
    } else {
      statusBar.backgroundColorByHexString('#7F93AA')
    }
  }

  const resetStatusBarColor = () => {
    const statusBar = window?.StatusBar
    if (!statusBar) return

    if (route.name === 'home') {
      if (isMobileMenuOpen.value) {
        statusBar.backgroundColorByHexString('#FFF')
      } else {
        statusBar.backgroundColorByHexString('#C993F2')
      }
    } else {
      statusBar.backgroundColorByHexString('#FFF')
    }
  }

  const openDrawer = (drawerData) => {
    updateStatusBarColor()
    isDrawerOpen.value = true
    document.body.style.overflowY = 'hidden'

    drawerTypeOpened.value = drawerData.type
    drawerTitle.value = drawerData.title
    drawerSubtitle.value = drawerData?.subtitle
  }

  const closeDrawer = () => {
    resetStatusBarColor()
    isDrawerOpen.value = false
    document.body.style.overflowY = ''

    drawerTypeOpened.value = ''
    drawerTitle.value = ''
    drawerSubtitle.value = ''
  }

  watchEffect(() => {
  })

  return {
    isDrawerOpen,
    drawerTypeOpened,
    drawerTitle,
    drawerSubtitle,
    openDrawer,
    closeDrawer
  }
})
