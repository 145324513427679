<template>
    <div class="checkbox-list">
      <div v-for="item in proxyArray" :key="item.id" class="checkbox-item">
        <FormInputCheckbox
          v-model="item.isSelected"
          :id="item.id"
          :label="item.title"
          :disabled="item.isCrossed"
        />
      </div>
    </div>
  </template>

<script>
import { ref, watch } from 'vue'
import FormInputCheckbox from '@/components/ui/form/FormInputCheckbox'

export default {
  name: 'ProgramCheckboxList',
  emits: ['updateSelectedPrograms'],
  components: { FormInputCheckbox },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const proxyArray = ref(props.modelValue)

    watch(
      proxyArray,
      () => {
        emit('updateSelectedPrograms', proxyArray.value)
      }
    )

    return {
      proxyArray
    }
  }
}
</script>

  <style scoped>
  .checkbox-list {
    display: flex;
    flex-direction: column;
  }
  .checkbox-item {
    margin-bottom: 10px;

  }
  </style>
