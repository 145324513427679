import { ref } from 'vue'

const disableBackup = true
const description = 'Для работы с личным кабинетом надо пройти аутентификацию'
const isBiometryAvailable = ref(true)
const isBiometryActive = ref(null)

export const useBiometry = () => {
  const platform = window?.device?.platform.toLowerCase()

  const setBiometry = (value) => {
    if (value) {
      window.localStorage.setItem('is_biometry_active', 'true')
    } else {
      window.localStorage.setItem('is_biometry_active', 'false')
    }
    isBiometryActive.value = value
  }

  const validateBiometricAccess = () => {
    window?.Fingerprint.isAvailable(
      () => {
        isBiometryAvailable.value = true
      },
      () => {
        isBiometryAvailable.value = false
      }
    )
  }

  const initializeBiometry = async (token) => {
    if (platform === 'ios') {
      return new Promise((resolve, reject) => {
        window?.Fingerprint.show({
          description,
          disableBackup
        }, () => {
          window?.Fingerprint?.registerBiometricSecret(
            {
              description,
              secret: token,
              invalidateOnEnrollment: true,
              disableBackup
            },
            () => {
              console.log('REGISTER BIOMETRY SUCCESS')

              setBiometry(true)
              resolve(true)
            },
            () => {
              const err = false
              setBiometry(false)
              reject(err)
            }
          )
        }, (err) => {
          setBiometry(false)
          reject(err)
        })
      })
    } else if (platform === 'android') {
      return new Promise((resolve, reject) => {
        window?.Fingerprint?.registerBiometricSecret(
          {
            description,
            secret: token,
            invalidateOnEnrollment: true,
            disableBackup
          },
          () => {
            console.log('REGISTER BIOMETRY SUCCESS')
            setBiometry(true)
            resolve(true)
          },
          () => {
            setBiometry(false)
            const err = false
            reject(err)
          }
        )
      })
    }
  }

  const getTokenFromBiometry = () => {
    return new Promise((resolve, reject) => {
      window.Fingerprint.loadBiometricSecret(
        {
          description,
          disableBackup
        },
        (token) => {
          resolve(token)
        },
        () => {
          const err = false
          reject(err)
        }
      )
    })
  }

  return {
    isBiometryAvailable,
    isBiometryActive,
    setBiometry,
    validateBiometricAccess,
    initializeBiometry,
    getTokenFromBiometry
  }
}
