<script>
import { computed, onActivated, onMounted, onUnmounted, ref } from 'vue'
import footstepsIcon from '@/assets/uploads/icon/common/footstep-icon.svg'
import walkedPathIcon from '@/assets/uploads/icon/common/walked-path-icon.svg'
import caloriesIcon from '@/assets/uploads/icon/common/calories-icon.svg'
import { getHealth } from '@/utilits/getHealth.js'
import { postLog } from '@/api/postLog'
import { useRouter } from 'vue-router'

export default {
  name: 'ActivityBanner',
  setup () {
    const steps = ref('-')
    const distance = ref('-')
    const calories = ref('-')
    const isRotating = ref(false)
    const today = new Date()
    const queryInterval = ref(false)
    const router = useRouter()

    const checkIfDataIsInvalid = (data) => {
      if (data.value === undefined || data.value === null) {
        return true
      }
      if (data.startDate.getTime() !== today.setHours(0, 0, 0, 0)) {
        return true
      }
    }

    const getSteps = (steps) => {
      return checkIfDataIsInvalid(steps) ? '-' : Math.round(steps.value)
    }

    const getDistance = (distance) => {
      return checkIfDataIsInvalid(distance) ? '-' : Math.floor((distance.value / 1000) * 10) / 10 + ' ' + 'км'
    }

    const getCalories = (calories) => {
      return checkIfDataIsInvalid(calories) ? '-' : Math.round(calories.value)
    }

    const goToActivityCalendar = () => {
      router.push({ name: 'calendar-activity' })
    }

    const queryHealthData = async () => {
      isRotating.value = true
      setTimeout(() => {
        isRotating.value = false
      }, 1500)

      steps.value = await getHealth('steps')
      distance.value = await getHealth('distance')
      calories.value = await getHealth('calories')

      postLog({ m: 'Banner', staps: steps, distance: distance, calories: calories })
    }

    const data = computed(() => {
      return [
        {
          img: footstepsIcon,
          text: 'Шагов за день',
          data: getSteps(steps.value?.at(-1))
        },
        {
          img: walkedPathIcon,
          text: 'Пройдено',
          data: getDistance(distance.value?.at(-1))
        },
        {
          img: caloriesIcon,
          text: 'Потрачено ккал',
          data: getCalories(calories.value?.at(-1))
        }
      ]
    })

    onActivated(() => {
      queryHealthData()
    })

    onMounted(async () => {
      queryInterval.value = setInterval(async () => {
        await queryHealthData()
      }, 12000)

      queryHealthData()
    })

    onUnmounted(() => {
      clearInterval(queryInterval.value)
    })

    return {
      data,
      isRotating,
      queryHealthData,
      goToActivityCalendar
    }
  }
}
</script>

<template>
  <div class="banner">
    <div :style="{ backgroundColor: '#F1842C' }" class="banner__wrapper">
      <div @click="goToActivityCalendar" class="banner-text">
        <h4 class="banner-text__title">Моя активность</h4>
        <div class="banner-text__desc-wrapper">
          <div v-for="item in data" :key="item.text" class="banner-text__data-item">
            <img class="banner-text__icon" :src="item.img" alt="Активности">
            <span class="banner-text__text">{{ item.text }}</span>
            <span class="banner-text__data">{{ item.data }}</span>
          </div>
        </div>
      </div>
      <div class="banner__picture">
        <img
          class="banner__picture-img"
          src="@/assets/uploads/icon/common/activity-banner-logo.svg"
          alt="картинка баннера"
        />
      </div>
      <img @click.stop="queryHealthData" class="banner__refresh-icon" :class="{ 'rotate-animation': isRotating }" src="@/assets/uploads/icon/common/reload.svg" alt="обновить">
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner {
  margin-bottom: 18px;
}

.banner__wrapper {
  cursor: pointer;
  position: relative;
  color: #002856;
  height: 109px;
  display: flex;
  border-radius: 6px;
  border: 2px solid #00000000;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.5px #f1842c;
}

.banner-text {
  padding-top: 12px;
  padding-left: 10px;
  position: relative;
  width: 55%;
  flex: 1;
  z-index: 1;
}

.banner-text__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  margin-bottom: 8px;
}

.banner-text__desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  vertical-align: baseline;
}

.banner__picture {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.banner__picture-img {
}

.banner-text__desc-wrapper {
  display: flex;
  gap: 2px;
  justify-content: space-between;
  @media (min-width: 410px) {
    gap: 24px;
    justify-content: left;
  }

}
.finance-info-img {
}
.banner-text__data-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #002856
}
.banner-text__icon {
  width: 12px;
  height: 12px;
}
.banner-text__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}

.banner-text__data {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
}

.banner__refresh-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 5px;
  top: 2px;
  z-index: 2;
  filter: brightness(0) saturate(100%) invert(10%) sepia(62%) saturate(2692%) hue-rotate(198deg) brightness(98%) contrast(102%);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
.rotate-animation {
  transform-origin: 55% 50%;
  animation: rotation 0.5s infinite linear;
}
</style>
