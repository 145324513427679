<template>
    <div class="reminder" v-if="!isClosed">
        <img src="@/assets/uploads/icon/common/blue-warning.svg" alt="важно" />
        <div>
            <span v-show="title">{{ title }}</span>
            <span v-show="text">{{ text }}</span>
            <div class="reminder__vhtml-wrapper" v-show="htmlTags" v-html="htmlTags"></div>
        </div>
        <img @click="isClosed = true" v-if="canClose" class="reminder-close" src="@/assets/uploads/icon/common/exit-cross.svg" alt="закрыть">
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'TextBannerWarning',
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    htmlTags: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    canClose: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup () {
    const isClosed = ref(false)
    return {
      isClosed
    }
  }
}
</script>

<style scoped lang="scss">
.reminder {
    position: relative;
    align-self: center;
    padding: 16px 14px;
    border-radius: 5px;
    width: 100%;
    min-height: 40px;
    background-color: #E1E8F4;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;

    img {
        align-self: flex-start;
    }

    div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-right: 5px;

        span:nth-child(1) {
            color: #002856;
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            text-align: left;
        }

        span:nth-child(2) {
            color: #002856;
            padding-right: 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
        }

    }
}

.reminder-close {
    position: absolute;
    right: 5px;
    top: 9px;
    width: 12px;
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(11%) sepia(48%) saturate(2942%) hue-rotate(196deg) brightness(103%) contrast(103%);
}

</style>

<style lang="scss">
.reminder__vhtml-wrapper {

          p {
            padding-right: 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
          }
        }
</style>
