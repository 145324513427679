import { defineStore } from 'pinia'
// это надо убрать, зачем в pinia хранить список дат, который не меняется?
export const useDateStore = defineStore('date', () => {
  const dictMonthRod = {
    0: 'Января',
    1: 'Февраля',
    2: 'Марта',
    3: 'Апреля',
    4: 'Мая',
    5: 'Июня',
    6: 'Июля',
    7: 'Августа',
    8: 'Сентября',
    9: 'Октября',
    10: 'Ноября',
    11: 'Декабря'
  }

  const dictMonth = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь'
  }

  const dayOfWeek = {
    0: 'Вс',
    1: 'Пн',
    2: 'Вт',
    3: 'Ср',
    4: 'Чт',
    5: 'Пт',
    6: 'Сб'
  }

  const dayOfWeekFull = {
    0: 'Воскресенье',
    1: 'Понедельник',
    2: 'Вторник',
    3: 'Среда',
    4: 'Четверг',
    5: 'Пятница',
    6: 'Суббота'
  }

  const dayOfWeekFullRod = {
    0: 'Воскресенье',
    1: 'Понедельник',
    2: 'Вторник',
    3: 'Среду',
    4: 'Четверг',
    5: 'Пятницу',
    6: 'Субботу'
  }

  return {
    dictMonth,
    dictMonthRod,
    dayOfWeek,
    dayOfWeekFull,
    dayOfWeekFullRod
  }
})
