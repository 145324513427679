<template>
  <div class="chat-woman-doctor">
    <div class="chat-woman-doctor__header">
      <img
        class="chat-woman-doctor__header-woman"
        src="@/assets/uploads/images/payment/doctor-woman.svg"
      />
      <img
        class="chat-woman-doctor__header-background"
        src="@/assets/uploads/images/calendar/pregnancy/background.svg"
      />
    </div>
    <div v-if="isChat" class="chat-woman-doctor__text">
      <span>{{ text.title }}</span>
      <span>{{ text.subtitle }}</span>
    </div>
    <div v-else class="chat-woman-doctor__text">
      <span>{{ title }}</span>
      <span>{{ subtitle }}</span>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue'

export default {
  props: {
    isSubscribtionOver: Boolean,
    isDoctorChat: {
      type: Boolean,
      default: true,
      required: false
    },
    isChat: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props) {
    const { isSubscribtionOver } = toRefs(props)
    const text = computed(() => {
      if (isSubscribtionOver.value) {
        return {
          title: 'Врач пока что не доступен для чата',
          subtitle:
            'Вы сможете написать ему позже, согласно времени начала чата'
        }
      } else {
        return {
          title: `${props.isDoctorChat ? 'Врач' : 'Пациент'} доступен для чата`,
          subtitle: 'Вы можете начать диалог'
        }
      }
    })

    return {
      text
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-woman-doctor {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40%;
}
.chat-woman-doctor__header {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 32px;
}

.chat-woman-doctor__header-background {
  max-width: none;
  position: absolute;
  top: 50%;
  transform: translateY(-10%);
  z-index: 0;
}

.chat-woman-doctor__header-woman {
  z-index: 1;
}

.chat-woman-doctor__text {
  display: flex;
  flex-direction: column;
  align-items: center;

  span:first-child {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-bottom: 6px;
  }
  span:last-child {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }
}
</style>
