<script>
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useAppInfoStore } from '@/stores/AppInfoStore'
import { getPlatform } from '@/utilits/getPlatform'
import { showToast } from '@/utilits/showToast'

export default {
  name: 'MenuBottom',
  setup () {
    const appStore = useAppInfoStore()
    const { appInfo } = storeToRefs(appStore)
    const platform = getPlatform()
    const appVersionIos = process.env.VUE_APP_IOS_VERSION
    const appVersionAndroid = process.env.VUE_APP_ANDROID_VERSION
    const isCopied = ref(false)
    const copyContent = async (message) => {
      try {
        await navigator.clipboard.writeText(message)
        isCopied.value = true

        showToast({ title: 'Версия приложения скопирована', icon: 'success' })
      } catch (err) {
        throw new Error(err)
      }
    }
    return { copyContent, appInfo, platform, appVersionAndroid, appVersionIos }
  }
}
</script>

<template>
  <div class="menu-bottom">
    <div
    class="menu__version">
    <p v-if="platform === 'ios'">
      Версия приложения {{ appVersionIos }} {{ platform }}
    </p>
    <p v-if="platform === 'android'">
      Версия приложения {{ appVersionAndroid }} {{ platform }}
    </p>
    <div
      v-if="(platform === 'ios' || platform === 'android')"
      class="menu__version-copy"
      @click="copyContent(`${platform === 'ios' ? appVersionIos : appVersionAndroid} ${platform}`)">
      <img src="@/assets/uploads/icon/copy.svg" alt="знак ссылки" />
    </div>
  </div>

  <a
      href="https://www.k31.ru/upload/doc/useterms.pdf"
      target="_blank"
      class="user-terms">
      Пользовательское соглашение
    </a>
  </div>

</template>

<style lang="scss" scoped>
.menu-bottom {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.menu__version {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  p {
    color: #7f8da9;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

.menu__version-copy {
  cursor: pointer;
}

.user-terms {
  color: #7f8da9;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-decoration: underline;
}

.modal-text__wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
