<template>
  <ArrowTitle :is-app="isApp" title="Медицинская карта"></ArrowTitle>
  <SectionWrapper
    stretched
    :class="{
      hidden: reviewModalIsOpen,
      'appointment-wrapper--modal-open': reviewModalIsOpen
    }"
  >
    <TheLoader v-show="loading" />

    <div v-if="!loading && medTabs && currentTab">
      <TheTabs
        :isApp="isApp"
        :tabs="medTabs"
        @changeTab="changeTab"
        :currentTab="currentTab"
      />

      <ul
        class="appointment-list"
        v-if="!fixedTabs.includes(currentTab?.title)"
      >
        <AppointmentHistoryListItem
          v-for="appointment in currentTab.items"
          :appointment="appointment"
          :key="appointment.id"
          :date="appointment.date"
          :doctorName="appointment?.name ?? appointment.doctor_name"
          :doctorPhoto="appointment?.photo"
          :type="currentTab.title"
          :isNps="appointment.is_nps"
          :doctorsData="doctorsData"
          @rate-selected="openReviewModal"
          @updateDoctors="updateDoctors"
        />

        <div
          v-if="
            (!currentTab?.items || currentTab?.items?.length === 0) && !error
          "
        >
          <CustomButton white tag="router-link" to="/online-appointment">
            выбрать специалиста
          </CustomButton>
          <WomanDoctor class="woman-doctor" :subtitle="'Нет доступных данных'" :isChat="false" />
        </div>

        <div v-if="error">
          <TheText textAlign="center" marginBottom="20px">
            {{ errorMsg }}
          </TheText>
          <CustomButton white tag="router-link" to="/online-appointment">
            выбрать специалиста
          </CustomButton>
        </div>
      </ul>

      <ul class="appointment-list" v-if="currentTab.title === 'Анализы'">
        <AnalyzesHistoryItem
          v-for="item in currentTab.items"
          :key="item.file_id"
          :date="item.date"
          :title="item.title"
          :loadLink="item.url"
          :fileName="item.file_name"
          :description="item.description"
          type="Анализы"
        />

        <div
          v-if="
            (!currentTab?.items || currentTab?.items?.length === 0) && !error
          "
        >
          <WomanDoctor :subtitle="'Нет доступных данных'" :isChat="false" />
          <CustomButton white tag="router-link" to="/online-appointment">
            выбрать специалиста
          </CustomButton>
        </div>

        <div v-if="error">
          <TheText textAlign="center" marginBottom="20px">
            {{ errorMsg }}
          </TheText>
          <CustomButton white tag="router-link" to="/online-appointment">
            выбрать специалиста
          </CustomButton>
        </div>
      </ul>

      <ul v-if="currentTab.title === 'Диагнозы'">
        <MedcardDiagnosis />
      </ul>

      <ul v-if="currentTab.title === 'Направления'">
        <MedcardReferrals />
      </ul>
    </div>
    <ModalWithArrow
      @modalClose="reviewModalIsOpen = false"
      :modal-is-active="reviewModalIsOpen"
      title="Оставить отзыв"
    >
      <AppointmentReviewModal
        :score="selectedRate"
        :appointment="selectedAppointment"
        :doctor-info="selectedAppointmentDoctorInfo"
        @close-modal="closeModals"
        :isOpen="reviewModalIsOpen"
      />
    </ModalWithArrow>
  </SectionWrapper>
</template>

<script>
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import TheTabs from '@/components/pages/TheTabs.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useUserStore } from '@/stores/UserStore'
import axios from 'axios'
import AppointmentHistoryListItem from '@/components/pages/med-card/AppointmentHistoryListItem.vue'
import TheText from '@/components/ui/typography/TheText.vue'
import AnalyzesHistoryItem from '@/components/pages/med-card/AnalyzesHistoryItem.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { storeToRefs } from 'pinia'
import { onActivated, ref, watch } from 'vue'
import { authApi } from '@/api/authApi'
import { postLog } from '@/api/postLog'
import WomanDoctor from '@/components/pages/chat/WomanDoctor.vue'
import ModalWithArrow from '@/components/modals/ModalWithArrow.vue'
import AppointmentReviewModal from '@/components/pages/med-card/AppointmentReviewModal.vue'
import MedcardDiagnosis from '@/components/pages/med-card/MedcardDiagnosis.vue'
import MedcardReferrals from '@/components/pages/med-card/MedcardReferrals.vue'

export default {
  name: 'MedCardView',
  components: {
    ModalWithArrow,
    WomanDoctor,
    CustomButton,
    AnalyzesHistoryItem,
    TheText,
    AppointmentHistoryListItem,
    TheLoader,
    TheTabs,
    SectionWrapper,
    ArrowTitle,
    AppointmentReviewModal,
    MedcardReferrals,
    MedcardDiagnosis
  },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup () {
    const fixedTabs = ['Анализы', 'Диагнозы', 'Направления']
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const loading = ref(true)
    const error = ref(false)
    const errorMsg = ref('У вас пока нет истории приемов, запишитесь к врачу.')
    const currentTab = ref([])
    const medTabs = ref([])
    const reviewModalIsOpen = ref(false)
    const selectedRate = ref(null)
    const selectedAppointmentDoctorInfo = ref(null)
    const doctorsData = ref(null)
    const selectedAppointment = ref({
      time: null,
      date: null,
      doctorImg: null,
      type: null
    })
    const changeTab = (tab) => {
      currentTab.value = tab
    }
    const closeModals = () => {
      reviewModalIsOpen.value = false
    }
    const openReviewModal = (value) => {
      selectedRate.value = value.score
      selectedAppointmentDoctorInfo.value = value.appointment
      selectedAppointment.value.time = value.time
      selectedAppointment.value.id = value.appointment.id
      selectedAppointment.value.date = value.date
      selectedAppointment.value.doctorImg = value.doctorImg
      selectedAppointment.value.type = value.type
      reviewModalIsOpen.value = true
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
    const getMedCardInfo = (path) => {
      return axios(path, {
        headers: {
          Authorization: `Bearer ${userToken.value}`
        }
      })
    }

    const setExtraInfoToDoctors = async () => {
      try {
        loading.value = true
        const doctorsAppointments = medTabs.value.find(
          (tab) => tab.title === 'Приемы'
        )
        if (!doctorsAppointments) return

        const exDoctorIds = doctorsAppointments.items
          .filter((item) => item.ex_doctor_id)
          .map((item) => item.ex_doctor_id)
          .join(':')

        const [error, doctorInfo] = await authApi(
          `/v1/service/doctor/?ex_id=${exDoctorIds}`
        )
        doctorsData.value = doctorInfo.data
        if (error) return
        if (doctorInfo?.data.length !== 0) {
          doctorInfo.data.forEach((doctor) => {
            doctorsAppointments.items
              .filter(
                (doctorAppointment) =>
                  parseInt(doctorAppointment.ex_doctor_id) ===
                  parseInt(doctor.ex_id)
              )
              .forEach((doctorAppointment) => {
                doctorAppointment.name = doctor.name
                doctorAppointment.photo = doctor.photo
                doctorAppointment.is_site = true
                doctorAppointment.is_favorite = doctor.is_favorite
              })
          })
        }
      } catch (error) {
        postLog(error)
      } finally {
        loading.value = false
      }
    }

    const getTabs = async (id) => {
      try {
        loading.value = true
        error.value = false

        const response = await Promise.all([
          getMedCardInfo(`/v2/element/patient-history/group/?user_id=${id}`),
          getMedCardInfo(`/v2/element/patient-history/files/?user_id=${id}`)
        ])
        medTabs.value = []
        if (response[0].data.status !== 'ok') {
          error.value = true
          errorMsg.value =
        response[0].data?.message ||
        'У вас пока нет истории приемов, запишитесь к врачу.'
        }

        if (response[0].data.status === 'ok') {
          medTabs.value.push(...response[0].data.data)

        // Прием на первое место
        }
        medTabs.value.sort((tabA, tabB) => {
          if (tabA.title === 'Приемы') return -1
          if (tabB.title === 'Приемы') return 1
          return tabA.title.localeCompare(tabB.title)
        })

        await setExtraInfoToDoctors()

        if (
          response[1].data.status === 'ok' &&
        response[1].data.data.length !== 0
        ) {
          if (!medTabs.value.some((tab) => tab.title === 'Анализы')) {
            medTabs.value.push({
              title: 'Анализы',
              items: [...response[1].data.data]
            })
          }
        }

        if (response[1].data.status !== 'ok') {
          error.value = true
          errorMsg.value = response[1].data.message
        }

        if (medTabs.value.length !== 0) {
        // фильтрация по дате
          medTabs.value.forEach((tab) => {
            tab.items.sort((scheduleA, scheduleB) =>
              new Date(scheduleA.date) > new Date(scheduleB.date) ? -1 : 1
            )
          })
        }
        error.value = false
      } catch (err) {
        error.value = true
        errorMsg.value = 'Произошла ошибка, попробуйте позже'
      } finally {
        if (!medTabs.value.some((tab) => tab.title === 'Приемы')) {
          medTabs.value.push({ title: 'Приемы' })
        }
        if (!medTabs.value.some((tab) => tab.title === 'Диагнозы')) {
          medTabs.value.push({ title: 'Диагнозы' })
        }
        if (!medTabs.value.some((tab) => tab.title === 'Направления')) {
          medTabs.value.push({ title: 'Направления' })
        }
        currentTab.value = medTabs.value[0]
        loading.value = false
      }
    }

    const updateDoctors = async (favDocId, favDocStatus) => {
      const indexes = currentTab.value.items
        .map((d, i) => (d.ex_doctor_id === favDocId ? i : ''))
        .filter(String)
      indexes.forEach((i) => {
        currentTab.value.items[i].is_favorite = favDocStatus
      })
    }

    onActivated(async () => {
      if (getUserId.value) {
        await getTabs(getUserId.value)
      }
    })

    watch(
      () => getUserId.value,
      async () => {
        if (getUserId.value) {
          await getTabs(getUserId.value)
        }
      },
      {
        immediate: true
      }
    )

    return {
      loading,
      changeTab,
      currentTab,
      medTabs,
      error,
      errorMsg,
      reviewModalIsOpen,
      openReviewModal,
      selectedRate,
      selectedAppointment,
      selectedAppointmentDoctorInfo,
      doctorsData,
      closeModals,
      updateDoctors,
      fixedTabs
    }
  }
}
</script>

<style scoped lang="scss">
.my-appointment__text {
  margin-bottom: 10px;
}

.my-appointment__bottom {
  margin-bottom: 30px;

  & span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #f1842c;
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }
}

.appointment-list {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 14px;
  color: #7f8da9;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__time {
    margin-right: 10px;
    font-weight: 700;
    color: #222222;
  }

  &__date {
    margin-right: auto;
  }

  &__type {
    padding: 2px 8px;
    background: #002856;
    border-radius: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__picture {
  }

  &__info {
  }

  &__department {
    margin-bottom: 4px;
  }

  &__title {
    color: #002856;
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__filial {
    margin-right: auto;
  }

  &__calendar {
    text-decoration-line: underline;
    color: #002856;
  }
}
.appointment-wrapper--modal-open {
  height: 1200px;
}
.woman-doctor {
  bottom: 37% !important;
}
</style>
