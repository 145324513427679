<template>
  <TheLoader v-show="loading" />
  <li v-if="!loading" class="appointment-list__item">
    <div class="appointment-list__top">
      <img src="@/assets/uploads/images/home-page/my-appointment/clock.svg" alt="картинка часов" />
      <div class="appointment-list__time" v-if="timeAppointment">
        {{ timeAppointment }}
      </div>
      <div class="appointment-list__date">
        {{ dateAppointment }}
      </div>
      <div v-if="appointment?.is_telemed" class="appointment-list__telemed-wrapper">
        <div class="appointment-list__type appointment-list__telemed">
          Телемедицина
        </div>
        <div class="appointment-list__status">
          <div v-if="appointment?.status === 'paid'" class="appointment-list__paid">
            <img src="@/assets/uploads/icon/common/arrow-blue.svg" alt="галочка" />
            <p>Оплачено</p>
          </div>
          <div v-if="appointment?.status === 'new'" class="appointment-list__wait">
            Ожидает оплаты
          </div>
        </div>
      </div>
      <div v-else class="appointment-list__type">Прием</div>
    </div>
    <a :href="calendarLink" class="appointment-list__calendar btn-clickable" target="_blank">
      Добавить в календарь
    </a>
    <div class="appointment-list__middle">
      <div class="appointment-list__middle-col">
        <div class="appointment-list__picture">
          <img :src="
            'https://www.k31.ru/specialisty/photo/' +
            resourceId +
            '_295x295.jpg'
          " :alt="doctorName" />
        </div>
        <div class="appointment-list__info">
          <p class="appointment-list__department">
            {{ doctorSpecialization }}
          </p>
          <h4 class="appointment-list__title">
            {{ doctorName }}
          </h4>
        </div>
      </div>
      <div class="appointment-list__middle-col">
        <img v-if="user && !appointment.is_favorite" src="@/assets/uploads/images/favorite-empty.svg" alt=""
          @click.prevent="addDocToFavorite" />
        <img v-if="user && appointment.is_favorite" src="@/assets/uploads/images/favorite-active.svg" alt=""
          @click.prevent="removeDocFromFavorite" />
      </div>
    </div>
    <div class="appointment-list__bottom">
      <div v-if="appointment?.address && !appointment?.is_telemed" class="appointment-list__address">
        <img src="@/assets/uploads/images/home-page/my-appointment/marker.svg" alt="знак метки на карте" />
        <p class="appointment-list__filial">{{ appointment?.address }}</p>
      </div>
      <button type="button" class="appointment-list__delete" @click="isCancelModalOpen = true">
        Отмена записи
      </button>
    </div>

    <template v-if="templateVisible && !appointment?.is_telemed">
      <custom-button v-if="!QRInfo" white class="appointment-list__parking" :to="redirectObject" tag="router-link">
        Заказать пропуск на парковку
      </custom-button>
      <custom-button v-if="QRInfo" light-blue text-transform="initial" small tag="button" type="button"
        data-bs-toggle="modal" data-bs-target="#modal-QR">
        <template #img>
          <img src="@/assets/uploads/icon/common/qr.svg" alt="qr код" />
        </template>
        <template #default> QR-код пропуска на парковку</template>
      </custom-button>
    </template>

    <CustomButton red small v-if="appointment?.status === 'new' && appointment?.is_telemed"
      @click.prevent="openPaymentModal">
      <template #img> <img src="@/assets/uploads/icon/common/screp-red.svg" alt="знак ссылки" /></template>
      <template #default><span class="pay-text--red">Оплатить</span></template>
    </CustomButton>

    <TextBannerWarning v-if="!appointment?.is_zoom_ready && appointment?.status === 'paid'" text="Ссылка появится за 15 минут до приема"/>

    <CustomButton v-if="appointment?.zoom_url && appointment?.is_zoom_ready" transparent small target="_blank" :href="appointment?.zoom_url">
      <template #img> <img src="@/assets/uploads/icon/common/screp.svg" alt="знак ссылки" /></template>
      <template #default><span class="pay-text--blue">Ссылка на консультацию</span></template>
    </CustomButton>

    <TelemedUpload v-if="appointment?.is_telemed" :reserveId="appointmentId" :isHome="true"/>

    <p v-if="paymentError" class="help-block error">
      Не удалось провести оплату, попробуйте ещё раз
    </p>
    <TheText v-if="appointment?.comment"> {{ appointment?.comment }}</TheText>
  </li>
  <Teleport to=".main__content">
    <ModalPaymentTelemed class="payment-modal-telemed" :class="{ paymentModalActive: isModalPaymentOpened }" :appointmentId="appointmentId"
      @close-payment="closeModalPayment" />
  </Teleport>
  <ModalQR :img="QRInfo?.url" :number="QRInfo?.number" :name="QRInfo?.model" />

  <ModalBlueBgWrapper :isModalOpen="isCancelModalOpen" @closeModal="isCancelModalOpen = false">
    <div class="modal-appointment">
      <p class="modal-text">Вы точно хотите отменить запись?</p>
      <div class="appointment-list__item">
        <div class="appointment-list__top">
          <img src="@/assets/uploads/images/home-page/my-appointment/clock.svg" alt="картинка часов" />
          <div class="appointment-list__time" v-if="timeAppointment">
            {{ timeAppointment }}
          </div>
          <div class="appointment-list__date">
            {{ dateAppointment }}
          </div>
          <div v-if="appointment?.is_telemed" class="appointment-list__type appointment-list__telemed">
            Телемедицина
          </div>
          <div v-else class="appointment-list__type">Прием</div>
        </div>
        <a :href="calendarLink" class="appointment-list__calendar" target="_blank">
          Добавить в календарь
        </a>
        <div class="appointment-list__middle">
          <div class="appointment-list__picture">
            <img :src="
                'https://www.k31.ru/specialisty/photo/' +
                resourceId +
                '_295x295.jpg'
              " :alt="doctorName" />
          </div>
          <div class="appointment-list__info">
            <p class="appointment-list__department">
              {{ doctorSpecialization }}
            </p>
            <h4 class="appointment-list__title">
              {{ doctorName }}
            </h4>
          </div>
        </div>
        <div v-if="appointment?.address" class="appointment-list__bottom">
          <div class="appointment-list__address">
            <img class="appointment-list__mark" src="@/assets/uploads/images/home-page/my-appointment/marker.svg"
              alt="знак метки на карте" />
            <p class="appointment-list__filial">{{ appointment?.address }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-custom__bottom">
      <CustomButton @click="deleteAppointment" tag="button" type="button">
        Отменить
      </CustomButton>
    </div>
  </ModalBlueBgWrapper>

  <ModalBsWrapper :id="`remove-schedule-${appointmentId}`" title="Отмена записи">

  </ModalBsWrapper>
</template>

<script>
import { useDateStore } from '@/stores/date'
import { computed, ref, toRefs, watch } from 'vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import TheText from '@/components/ui/typography/TheText.vue'
import { authApi } from '@/api/authApi'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { gosuslugiListner } from '@/utilits/gosuslugiListner'
import { postLog } from '@/api/postLog'
import { useYandexMetrikaStore } from '@/stores/YandexMetrikaStore'
import ModalQR from '@/components/modals/ModalQR.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useTemplateVisibleAll } from '@/composables/useTemplateVisibleAll'
import { useDoctorStore } from '@/stores/DoctorStore'
import ModalPaymentTelemed from '@/components/modals/ModalPaymentTelemed.vue'
import { useModalPayment } from '@/composables/ui/useModalPayment.js'
import TextBannerWarning from '../../Common/TextBannerWarning.vue'
import TelemedUpload from '../online-consultation/TelemedUpload.vue'
import ModalBlueBgWrapper from '@/components/modals/ModalBlueBgWrapper.vue'

export default {
  name: 'AppointmentListItem',
  components: { TheLoader, ModalQR, TheText, ModalBlueBgWrapper, CustomButton, ModalPaymentTelemed, TextBannerWarning, TelemedUpload },
  emits: ['deleteAppointment', 'toggleFavoriteDoc'],
  props: {
    appointment: {
      type: Object,
      require: true,
      default: () => ({})
    },
    date: {
      type: String,
      require: true,
      default: null
    },
    doctorInfo: {
      type: String,
      require: true,
      default: null
    },
    doctorWorkTitle: {
      type: String,
      require: true,
      default: null
    },
    calendarLink: {
      type: String,
      require: true,
      default: null
    },
    resourceId: {
      type: Number,
      require: true,
      default: null
    },
    appointmentId: {
      type: Number,
      require: true,
      default: null
    },
    user: {
      type: Object,
      required: true,
      default: null
    }
  },

  setup (props, { emit }) {
    const metrikaStore = useYandexMetrikaStore()
    const userStore = useUserStore()
    const { getUserId } = storeToRefs(userStore)
    const { date, doctorInfo, appointmentId, appointment, doctorWorkTitle, resourceId } = toRefs(props)
    const doctorsStore = useDoctorStore()
    const { addDoctorToFavorites, removeDoctorToFavorites, setFavoriteDoctor } = doctorsStore
    setFavoriteDoctor(appointment.value)
    const dateStore = useDateStore()
    const dictMonthRod = dateStore.dictMonthRod
    const { templateVisible } = useTemplateVisibleAll('is_parking_qr')
    const {
      isModalPaymentOpened,
      closeModalPayment,
      openModalPayment
    } = useModalPayment()
    const QRInfo = ref(null)
    const modalIsOpen = ref(false)
    const urlPayment = ref(false)
    const paymentError = ref(false)
    const loading = ref(false)
    const isCancelModalOpen = ref(false)
    const timeAppointment = computed(() => {
      if (!date.value) return null

      return date.value.split('T')[1].slice(0, 5)
    })

    const dateAppointment = computed(() => {
      if (!date.value) return null

      const [year, month, day] = date.value.split('T')[0].split('-')
      const formatMonth = month.startsWith(0) ? month[1] : month

      return `${day} ${dictMonthRod[parseInt(formatMonth) - 1]} ${year} г.`
    })

    const doctorName = computed(() => {
      if (!doctorInfo.value) return null

      return doctorInfo.value.split('.')[0]
    })

    const redirectObject = computed(() => {
      return {
        name: 'parking-pass',
        params: {
          id: appointmentId.value
        }
      }
    })

    const doctorSpecialization = computed(() => {
      if (!doctorWorkTitle.value) return null

      return doctorWorkTitle.value
    })
    const deleteAppointment = () => {
      if (appointmentId) {
        emit('deleteAppointment', appointmentId)
        modalIsOpen.value = false
        isCancelModalOpen.value = false
      }
    }
    const openModal = () => {
      modalIsOpen.value = true
      window.scrollTo(0, 0)
    }

    const isPhone = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform === 'ios' || platform === 'android' ? 1 : 0
    })

    const payForReceiptPhone = () => {
      metrikaStore.sendGoal('tele_pay_process')
      const url = appointment.value?.payment_url || urlPayment.value
      gosuslugiListner(url)
        .then((event) => {
          let token

          postLog({
            msg: 'inside event'
          })
          if (event.data.match(/^oauth::/)) {
            token = JSON.parse(event.data.substring(7))
          }

          postLog({
            token: token
          })

          if (token === 'success') {
            window.location.reload()
          }
          if (token === 'failed') {
            paymentError.value = true
          }
        })
        .catch((err) => {
          postLog({
            err: err
          })
          paymentError.value = true
        })
    }

    const sendMetrika = () => {
      metrikaStore.sendGoal('tele_pay_process')
    }

    const getUserPass = async () => {
      try {
        loading.value = true
        const [err, response] = await authApi(
          `v1/car/pass/?user_id=${getUserId.value}`
        )
        if (err) {
          postLog(err)
          return
        }
        if (response.status === 'ok') {
          if (response.data.length !== 0) {
            QRInfo.value = response.data[0]
          }
        }
      } catch (err) {
        postLog(err)
      } finally {
        loading.value = false
      }
    }

    const addDocToFavorite = () => {
      addDoctorToFavorites(resourceId.value)
      emit('toggleFavoriteDoc', resourceId.value, 1)
    }
    const removeDocFromFavorite = () => {
      removeDoctorToFavorites(resourceId.value)
      emit('toggleFavoriteDoc', resourceId.value, 0)
    }

    const openPaymentModal = () => {
      paymentError.value = false
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      openModalPayment()
    }

    watch(
      () => date.value,
      async () => {
        if (!date.value) return
        const currentDate = new Date(date.value.split('T')[0])
        const today = new Date()
        const tomorrow = new Date()
        tomorrow.setDate(today.getDate() + 1)

        if (currentDate.toDateString() === today.toDateString() || currentDate.toDateString() === tomorrow.toDateString()) {
          await getUserPass()
        }
      },
      {
        immediate: true
      }
    )

    return {
      timeAppointment,
      dateAppointment,
      doctorName,
      doctorSpecialization,
      deleteAppointment,
      modalIsOpen,
      openModal,
      urlPayment,
      isPhone,
      payForReceiptPhone,
      paymentError,
      sendMetrika,
      redirectObject,
      QRInfo,
      loading,
      templateVisible,
      removeDocFromFavorite,
      addDocToFavorite,
      isModalPaymentOpened,
      closeModalPayment,
      isCancelModalOpen,
      openPaymentModal
    }
  }
}
</script>

<style scoped lang="scss">
.appointment-list__mark {
  max-width: 16px;
  max-height: 16px;
}

.modal-text {
  margin-bottom: 10px;
}

.btn-clickable {
  display: inline-block;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    display: block;
    left: -10px;
    top: 50%;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 200%;
    opacity: 0.5;
    transform: translateY(-50%);
  }
}

.appointment-list__parking {
  color: $blue;
  text-transform: none;

  &:hover {
    color: white;
  }
}

.modal-btn {
  color: #062e4f;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  text-transform: uppercase;
  border-radius: 30px;
  background: #bac7de;
  padding: 10px;
  width: 100%;
  border: none;
  margin-top: 32px;
}

.appointment-list__delete {
  margin-left: auto;
  display: inline-block;
  padding: 0;
  border: unset;
  justify-self: end;
  cursor: pointer;
  color: #002856;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  text-decoration-line: underline;
  white-space: nowrap;
  text-align: right;
}

.appointment-list {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 14px;
  color: #7f8da9;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    padding: 8px;
    background: #ffffff;
    //box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 13px;
    border-bottom: 1px solid $blue-extra-light;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__time {
    margin-right: 10px;
    font-weight: 700;
    color: #222222;
  }

  &__date {
    margin-right: auto;
  }

  &__type {
    padding: 2px 8px;
    background: #002856;
    border-radius: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  &__middle {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
  }

  &__picture {
    max-width: 45px;

    & img {
      border-radius: 50%;
    }
  }

  &__info {
  }

  &__department {
    margin-bottom: 4px;
  }

  &__title {
    color: #002856;
    text-align: left;
  }

  &__bottom {
    display: flex;
    gap: 8px;
    @include desktop {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__address {
    display: flex;
    justify-content: end;
  }

  &__filial {
    margin-right: auto;
  }

  &__calendar {
    text-decoration-line: underline;
    color: #002856;
    text-align: left;
  }
}

.appointment-list__address {
  gap: 5px;
  display: flex;
  align-items: center;
}

.modal-custom__bottom {
  padding-top: 20px;
}

.appointment-list__telemed-wrapper {
  text-align: center;
}

.appointment-list__telemed {
  margin-bottom: 4px;
  background: #025ab7;
}

.appointment-list__status {
}
.appointment-list__middle-col {
  display: flex;
  align-items: center;
  gap: 8px;
  &:last-child {
    img {
      cursor: pointer;
    }
  }
}
.appointment-list__paid {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  color: $blue;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 127.273% */
}

.appointment-list__wait {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $red;
  text-align: center;
  font-family: Circe;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 109.091% */
}

.appointment-list__urls {
  display: flex;
  flex-direction: column;
}

.appointment-list__url-btn {
  border: unset;
  padding: 0;
  display: flex;
  gap: 5px;
  color: $blue;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-decoration-line: underline;
}

.appointment-list__url-btn.red {
  color: $red;
}

.blue {
}

.payment-modal-telemed {
  display: none;
}

.paymentModalActive {
  display: block;
  top: 0;
  opacity: 1;
  height: 100%;

}

.appointment__payment-btn {
  color: $blue !important;
}
.pay-text--red {
  color: #FF1F00;
}
.pay-text--blue {
  color: #002856;
}
</style>
