<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { mobileMenuButtonsDoctor } from '@/constants/mobileMenuButtons.js'
import { ref, toRefs } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'MenuItemsSwiper',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup (props) {
    const router = useRouter()
    const { user } = toRefs(props)
    const swiperOptions = ref({
      spaceBetween: 5,
      breakpoints: {
        0: {
          slidesPerView: 2.9
        },
        400: {
          slidesPerView: 3.2
        },
        440: {
          slidesPerView: 3.4
        },
        480: {
          slidesPerView: 3.7
        },
        550: {
          slidesPerView: 4.2
        },
        710: {
          slidesPerView: 5.5
        },
        820: {
          slidesPerView: 6
        },
        991: {
          slidesPerView: 4,
          slidesPerGroup: 4
        },
        1200: {
          slidesPerView: 6,
          slidesPerGroup: 6
        }
      }
    })

    const goToLogin = (to) => {
      if (user.value) {
        router.push({ path: to })
      } else {
        router.push({ path: '/auth/phone' })
      }
    }

    return {
      swiperOptions,
      goToLogin,
      mobileMenuButtonsDoctor
    }
  }
}
</script>

<template>
  <div class="menu-item__swiper">
    <Swiper
      v-bind="swiperOptions"
      :loop="false"
      class="menu-item__swiper-wrapper">
      <swiper-slide v-for="item in mobileMenuButtonsDoctor" :key="item.title">
        <div class="menu-item__tile" @click="goToLogin(item.to)">
          <span class="menu-item__tile-text">{{ item.title }}</span>
          <div class="menu-item__tile-icon">
            <img
              :src="require(`@/assets/uploads/icon/common/${item.icon}.svg`)"
              :alt="item.title" />
          </div>
        </div>
      </swiper-slide>
    </Swiper>
  </div>
</template>

<style lang="scss" scoped>
.menu-item {
  padding: 0 0 10px 0;

  &__swiper {
    padding-left: 15px;
    overflow: hidden;
  }
}

.menu-item__tile {
  max-width: 116px;
  height: 116px;
  border-radius: 8px;
  background: #fff;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-item__tile-text {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  overflow: hidden;

  word-wrap: break-word;

}

.menu-item__tile-icon {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: #eef1f3;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
