<template>
  <ArrowTitle :isApp="isApp" title="Поддержка FAQ" />

  <SectionWrapper stretched>
    <div v-if="loading" class="loading-wrapper">
      <TheLoader />
    </div>

    <div class="faq" v-else>
      <UserMessage
        :text="messageText"
        fontWeight="700"
        fontSize="16px"
        bgColor="#D3E7EE" />

      <h2 class="faq__question-title">{{ questionTitle }}</h2>

      <div class="faq__questions-wrapper">
        <TransitionGroup name="fade">
          <FaqQuestionCard
            v-for="item in faqItems"
            :key="item.title"
            :text="item.title"
            :bgColor="colors[item.preset]"
            @click.prevent="selectedQuestion = item" />
        </TransitionGroup>
      </div>

      <div v-if="selectedQuestion?.description" class="faq__result-wrapper">
        <div
          class="faq__question-desc"
          v-html="selectedQuestion.description"></div>

        <CustomButton
          v-if="selectedQuestion.url"
          marginTop="16px"
          tag="router-link"
          :to="selectedQuestion.url">
          Записаться
        </CustomButton>

        <div class="faq__whatsapp-block">
          <h1>
            {{
              showWhatsapp
                ? 'Наш консультант решит вопрос:'
                : 'Ваш вопрос решен?'
            }}
          </h1>
          <div v-show="!showWhatsapp">
            <CustomButton transparent small @click.prevent="reset">
              Да
            </CustomButton>
            <CustomButton
              transparent
              small
              @click.prevent="showWhatsapp = true">
              Еще нет
            </CustomButton>
          </div>
          <div v-show="showWhatsapp">
            <img src="@/assets/uploads/icon/common/whatsapp-icon.svg" alt="" />
            <a target="_blank" :href="appInfo?.whatsapp">Перейти в WhatsApp</a>
          </div>
        </div>
      </div>

      <CustomButton
        v-show="selectedQuestion !== null"
        marginTop="16px"
        @click.prevent="reset">
        Вернуться к выбору раздела
      </CustomButton>
    </div>
  </SectionWrapper>
</template>

<script>
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import UserMessage from '@/components/pages/chat/messages/UserMessage.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import {
  computed,
  onActivated,
  onDeactivated,
  onMounted,
  ref,
  watch
} from 'vue'
import { getCallcenterCategories } from '../../api/callcenter/getCallcenterCategories'
import FaqQuestionCard from './FaqQuestionCard.vue'
import { useAppInfoStore } from '@/stores/AppInfoStore'

export default {
  name: 'FaqCallCenterView',
  components: {
    SectionWrapper,
    TheLoader,
    ArrowTitle,
    UserMessage,
    FaqQuestionCard,
    CustomButton
  },
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup () {
    let textAccumulationCount = 0
    const colors = { blue: '#BAC7DE', pink: '#F5E6FC', purple: '#FBCDCD', orange: '#FBE1CD', red: '#FBCDCD', yellow: '#F5FBCD', green: '#D1F9EF' }

    const error = ref(null)
    const loading = ref(false)
    const faqData = ref(null)
    const messageText = ref(null)
    const showWhatsapp = ref(false)
    const selectedQuestion = ref(null)

    const appInfoStore = useAppInfoStore()
    const { appInfo } = appInfoStore

    const faqItems = computed(() => {
      if (selectedQuestion.value === null) {
        return faqData.value
      } else if (selectedQuestion.value.items.length) {
        return selectedQuestion.value.items
      } else return []
    })

    const questionTitle = computed(() => {
      if (selectedQuestion.value === null) {
        return 'Разделы приложения'
      } else if (
        selectedQuestion.value.description &&
        !selectedQuestion.value.items.length
      ) {
        return selectedQuestion.value.title
      } else return ''
    })

    const reset = () => {
      textAccumulationCount = 0
      messageText.value = ''
      selectedQuestion.value = null
      showWhatsapp.value = false
    }

    watch(
      selectedQuestion,
      (prevQuestion) => {
        if (selectedQuestion.value !== null) {
          if (prevQuestion.title && textAccumulationCount > 0) {
            messageText.value = `${messageText.value} #${selectedQuestion.value.title}`
          } else {
            messageText.value = `Вы выбрали #${selectedQuestion.value.title}`
            textAccumulationCount += 1
          }
        } else {
          messageText.value =
            'Выберите интересующий раздел или воспользуйтесь поиском по приложению'
        }
      },
      { immediate: true }
    )

    onMounted(async () => {
      faqData.value = await getCallcenterCategories()
    })

    onActivated(() => {
      messageText.value =
        'Выберите интересующий раздел или воспользуйтесь поиском по приложению'
    })

    onDeactivated(() => {
      reset()
    })
    return {
      colors,
      loading,
      error,
      faqData,
      faqItems,
      questionTitle,
      selectedQuestion,
      messageText,
      showWhatsapp,
      appInfo,
      reset
    }
  }
}
</script>

<style scoped lang="scss">
.loading-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 122px);
  background: #fff;
  border-radius: 8px 8px 0 0;
  padding: 12px 0;
  z-index: 10;
}

.faq {
  display: flex;
  flex-direction: column;
}

.faq__question-title {
  margin-top: 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.faq__questions-wrapper {
  margin-top: 16px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.faq__whatsapp-block {
  padding: 16px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #d1f9ef;
  border-radius: 8px;

  > h1 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  > div {
    margin-top: 16px;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    width: 80%;

    > a {
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.02em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
}

.fade-enter-active {
  transition: all 0.4s ease-in-out;
  /* Slower transition for entering */
}

.fade-leave-active {
  transition: none;
}

.fade-enter-from {
  opacity: 0;
  transform: translateY(20px) scale(0.9);
  /* Start slightly below and scaled down */
}

.fade-enter-to {
  opacity: 1;
  transform: translateY(0) scale(1);
  /* End at full opacity and normal size */
}

.fade-leave-from {
  opacity: 1;
  transform: translateY(0) scale(1);
  /* Start at full opacity and size */
}

.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px) scale(0.95);
  /* End faded out and slightly smaller */
}

.fade-leave-active {
  position: absolute;
}
</style>

<style lang="scss">
.faq__question-desc {
  font-family: Circe;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  p {
    margin-bottom: 16px;
  }

  ul {
    list-style: disc;
  padding-left: 20px;
  li {
    list-style: disc;
  }
}
  }

</style>
