import axios from 'axios'

export const requestSmsCode = async (phone, isForcedSms) => {
  const response = await axios.post('/v2/element/user/request-otp', {
    phone: `+7${phone}`,
    device_uuid: isForcedSms ? '' : window?.device?.uuid
  })
  if (response.data.status !== 'ok') {
    const errorText =
    response.data?.message ?? 'Нет доступа к серверу. Попробуйте позже.'
    throw new Error(errorText)
  }
}
