import { authenticatedApiCall } from '@/api/authenticatedApiCall.js'

export const getPatientDiagnosis = async () => {
  const url = '/v6/element/event/diagnosis'

  const response = await authenticatedApiCall({ url })

  if (response.data.status !== 'ok') {
    throw new Error(response.data)
  }

  return response.data.data
}
