<template>
<div class="phone">
  <TheLoader class="form-loader" v-if="loading" />
  <form
    v-else
    action=""
    class="form-phone"
    @submit.prevent="submitPhone"
  >

  <TheTitle>
    <h3>Вход в личный кабинет пациента</h3>
  </TheTitle>

    <div
      class="form-phone__group form-phone__group-phone"
      :class="{ 'form-group--error': v$.phoneUser.$error || error }"
    >
      <OtpForm
        v-model="userInfo.phoneUser"
        :digit-count="10"
        title="Номер телефона"
        pre-code="+7"
        :error="v$.phoneUser.$error || error"
        @is-completed="error = false"
      />

      <div v-if="v$.phoneUser.$error || error" class="error help-block">
        Необходимо ввести телефон
      </div>

      <div v-if="responseError" class="error help-block">
        {{ responseErrorMsg }}
      </div>
      <h4 class="form-phone__label form-phone__sms">
        На этот номер придет смс с кодом
      </h4>
    </div>

    <CustomButton tag="button" type="submit" marginBottom="20px">
      Продолжить
    </CustomButton>

    <CustomButton
      v-if="showGosuslugi"
      to="/auth/gosuslugi"
      class="form-phone__gosuslugi"
      tag="router-link"
      white
      type="submit"
      marginBottom="20px"
    >
      <template #img>
        <img
          src="@/assets/uploads/icon/common/gosuslugi.svg"
          alt="логотип Госуслуг"
        />
      </template>
      Войти через Госуслуги
    </CustomButton>

    <a
      href="https://www.k31.ru/upload/doc/useterms.pdf"
      target="_blank"
      class="form-phone__rules"
    >
      Пользовательское соглашение
    </a>

    <router-link to="/">
      <p style="text-align: center"><br />Вернуться</p>
    </router-link>
  </form>
</div>
</template>

<script>
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import OtpForm from '@/components/pages/auth/OtpForm.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import TheTitle from '@/components/ui/typography/TheTitle.vue'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useAppInfoStore } from '@/stores/AppInfoStore'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { useYandexMetrikaStore } from '@/stores/YandexMetrikaStore'
import { requestSmsCode } from '@/api/authentication/requestSmsCode'
import { getIsPin } from '@/api/authentication/getIsPin'

export default {
  name: 'PhoneForm',
  emits: ['setCode'],
  components: { TheLoader, OtpForm, CustomButton, TheTitle },
  props: {
    savedPhone: String
  },
  setup (props) {
    const metrikaStore = useYandexMetrikaStore()
    const appStore = useAppInfoStore()
    const userStore = useUserStore()
    const { setUserPhone, setHasPinCode, setIsForcedSms, isForcedSms } = userStore
    const { appInfo } = storeToRefs(appStore)
    const loading = ref(false)
    const error = ref(false)
    const responseError = ref(false)
    const responseErrorMsg = ref('Произошла ошибка. Повторите попытку позже')
    const isBrowser = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform !== 'ios' && platform !== 'android'
    })

    const userInfo = reactive({
      phoneUser: ''
    })
    const rules = {
      phoneUser: { required }
    }
    const v$ = useVuelidate(rules, userInfo)

    const submitPhone = async () => {
      responseError.value = false
      v$.value.$touch()

      if (v$.value.$error || userInfo.phoneUser.length !== 10) {
        error.value = true
        return
      }

      error.value = false
      loading.value = true

      if (!isForcedSms) {
        try {
          const isPin = await getIsPin(userInfo.phoneUser)
          if (isPin && !isBrowser.value) {
            setHasPinCode(true)
          } else {
            setHasPinCode(false)
            setIsForcedSms(true)
          }
        } catch (error) {
          setHasPinCode(false)
          setIsForcedSms(true)
        }
      }

      try {
        metrikaStore.sendGoal('req_otp')
        await requestSmsCode(userInfo.phoneUser, isForcedSms)
        setUserPhone(userInfo.phoneUser)
      } catch (err) {
        responseError.value = true
        responseErrorMsg.value = err.message
      } finally {
        loading.value = false
      }
    }

    const showGosuslugi = computed(() => {
      const platform = window?.device?.platform.toLowerCase()

      if (!appInfo.value) return false

      if (appInfo.value.is_esia_web === 1 && platform === 'browser') {
        return true
      }
      if (appInfo.value.is_esia_ios === 1 && platform === 'ios') {
        return true
      }
      if (appInfo.value.is_esia_android === 1 && platform === 'android') {
        return true
      }

      return false
    })

    watch(
      () => userInfo.phoneUser,
      () => {
        if (userInfo?.phoneUser?.length === 12) {
          v$.value.$touch()
          error.value = false
        }
      }
    )

    onMounted(() => {
      userInfo.phoneUser = props.savedPhone
    })
    return {
      userInfo,
      submitPhone,
      v$,
      error,
      responseError,
      loading,
      responseErrorMsg,
      showGosuslugi
    }
  }
}
</script>

<style scoped lang="scss">
.form-phone {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__group {
    & .help-block {
      margin-bottom: 12px;
    }
  }

  &__label {
    color: $blue;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 5px;

    &.error {
      color: $red;
    }
  }

  &__inputs {
    margin-bottom: 12px;
  }

  &__group-phone {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
  }

  &__sms {
    margin-bottom: 25px;
    align-self: start;
  }

  &__rules {
    display: block;
    text-align: center;
    color: $blue;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.3px;
  }

  &__gosuslugi {
    min-height: 40px;
    padding-top: 0;
    padding-bottom: 0;

    & img {
      margin-right: 4px;
    }
  }
}

.form-loader {
  align-self: center;
  justify-self: center;
  flex: 1;
}
</style>
