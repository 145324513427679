import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'

export const authenticatedApiCall = async ({
  url,
  method = 'get',
  data = {},
  params = {},
  headers = {}
}) => {
  const userStore = useUserStore()
  const defaultParams = { user_id: userStore.getUserId }

  return await axios({
    method,
    url,
    data,
    params: { ...defaultParams, ...params },
    headers: {
      Authorization: `Bearer ${userStore.userToken}`,
      ...headers
    }
  })
}
