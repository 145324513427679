<template>
    <div class="hidden-div" v-show="scrollY"></div>
    <ul class="med-tabs" :class="{ 'med-tabs--fixed': scrollY }">
        <li class="med-tabs__item" v-for="tab in tabs" :key="tab.title">
            <button type="button" class="med-tabs__links" :class="{ active: currentTab.title === tab.title }"
                @click="changeActiveTab(tab)">
                {{ tab.title }}
            </button>
        </li>
    </ul>
</template>

<script>
import { onMounted, ref } from 'vue'

export default {
  name: 'TheTabs',
  emits: ['changeTab'],
  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => []
    },
    currentTab: {
      type: Object,
      required: true,
      default: () => ({})
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup (props, { emit }) {
    const scrollY = ref(false)

    onMounted(async () => {
      const onScroll = () => {
        scrollY.value = window.scrollY > 100
      }
      window.addEventListener('scroll', onScroll)
    })
    const changeActiveTab = (tab) => {
      emit('changeTab', tab)
    }
    return {
      changeActiveTab,
      scrollY
    }
  }
}
</script>

<style scoped lang="scss">
.med-tabs::-webkit-scrollbar {
    display: none;
}

.med-tabs {
  -ms-overflow-style: none;
  scrollbar-width: none;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  top: 0px;
  margin-bottom: 15px;
  padding-top: 5px;
  transition: top 0.1s linear;
  @media (max-width: 767px) {
    overflow: scroll;
    margin-left: -14px;
    padding-left: 14px;
    margin-right: -14px;
    padding-right: 14px;
  }

  &.med-tabs--fixed {
    @include tablets-md {
      position: fixed;
      top: 40px;
      width: 100%;
      left: 0;
      background: #fff;
      padding: 13px 14px 5px;
      z-index: 100;
      border-radius: 9px 9px 0 0;
      margin: 0;
    }
  }

    &__item {}

    &__links {
        width: 100%;
        margin: 0;
        text-transform: uppercase;
        border: unset;
        display: flex;
        justify-content: center;
        background: #e1e8f4;
        color: #002856;
        font-size: 12px;
        font-weight: 400;
        line-height: 11px;
        text-align: left;
        border-radius: 30px;

        padding: 11px 16px;
        white-space: nowrap;

        &.active {
            font-weight: 700;
            color: #fff;
            background: #002856;
        }
    }
}

.hidden-div {
  height: 38px;
  }
</style>
