import axios from 'axios'

export const getCallcenterCategories = async () => {
  const response = await axios('/v6/callcenter/')

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error()
  }
}
