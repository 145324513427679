<template>
  <div class="home-wrapper">

    <div class="violet-block">
      <UserBar />
      <MenuItemsSwiper :user="user"/>
    </div>

    <div ref="whiteBlock" class="white-block">
      <AnnounceComponent />

      <UpdateAppBanner
        class="container"
        v-if="bannerUpdateIsOpen"
        :bannerLink="bannerLink"
        @closeBanner="bannerUpdateIsOpen = false" />

      <ActualSection />

      <BannersSection :banners="bannersFiltered" class="container" />

      <PopularServicesSection
        :enabledServices="enabledServices"
        :areServicesLoading="areServicesLoading"
        :areSettingsOpen="areSettingsOpen"
        @openModal="isServicesModalOpen = true"
        @openSettings="areSettingsOpen = true"
        @closeSettings="closeSettings"
        @deleteOneService="deleteOneService"
        @saveSorting="saveSorting"
        class="container" />

      <ActivityBanner
        class="container" />

      <PopularServicesModal
        :isServicesModalOpen="isServicesModalOpen"
        :allServices="allServices"
        :enabledServices="enabledServices"
        @updateServices="updateServices"
        @closeModal="closeModal" />

      <MyAppointmentSection :user="user" />
    </div>
  </div>
</template>

<script>
import ActivityBanner from '@/components/pages/home/ActivityBanner.vue'
import PopularServicesSection from '@/components/pages/home/PopularServicesSection.vue'
import PopularServicesModal from '@/components/pages/home/PopularServicesModal.vue'
import MyAppointmentSection from '@/components/pages/home/MyAppointmentSection.vue'
import ActualSection from '@/components/pages/home/ActualSection.vue'
import BannersSection from '@/components/pages/home/BannersSection.vue'
import UpdateAppBanner from '@/components/pages/home/UpdateAppBanner.vue'
import { useUpdateBanner } from '@/composables/useUpdateBanner'
import { computed, onActivated, onDeactivated, ref, watch, watchEffect } from 'vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import axios from 'axios'
import { useToast } from 'vue-toastification'
import CustomToast from '@/components/ui/CustomToast.vue'
import { checkIfAvailable, checkIfAuthorized } from '@/utilits/getHealth.js'
import UserBar from '../components/pages/home/UserBar.vue'
import MenuItemsSwiper from '../components/pages/home/MenuItemsSwiper.vue'
import AnnounceComponent from '../components/pages/home/AnnounceComponent.vue'

export default {
  name: 'HomeView',
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {
    UpdateAppBanner,
    ActualSection,
    MyAppointmentSection,
    PopularServicesSection,
    BannersSection,
    PopularServicesModal,
    ActivityBanner,
    MenuItemsSwiper,
    UserBar,
    AnnounceComponent
  },
  setup () {
    const platform = window?.device?.platform.toLowerCase()
    const isServicesModalOpen = ref(false)
    const areSettingsOpen = ref(false)
    const banners = ref(null)
    const whiteBlock = ref(null)
    const whiteBlockPosition = ref(null)
    const allServices = ref(null)
    const enabledServices = ref(null)
    const areServicesLoading = ref(true)
    const { bannerLink } = useUpdateBanner()
    const bannerUpdateIsOpen = ref(null)
    const userStore = useUserStore()
    const {
      hasPinCode,
      userToken,
      getUserId,
      hasHealth,
      isActivityBannerAvailable
    } = storeToRefs(userStore)
    const toast = useToast()

    const bannersFiltered = computed(() => {
      if (banners.value && banners.value.some((b) => b.type === 'version')) {
        return banners.value.filter((b) => b.type !== 'version')
      } else {
        return banners.value
      }
    })

    const toastOptions = {
      position: 'bottom-center',
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.38,
      timeout: 2000,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: false,
      rtl: false,
      toastClassName: 'custom-toast'
    }

    const appVersion = computed(() => {
      if (platform === 'ios') {
        return process.env.VUE_APP_IOS_VERSION_RAW
      } else if (platform === 'android') {
        return process.env.VUE_APP_ANDROID_VERSION_RAW
      } else {
        return null
      }
    })

    const getBanners = async () => {
      const params = new URLSearchParams({
        user_id: getUserId.value,
        device_uuid: window?.device?.uuid,
        v: appVersion.value,
        device: platform
      }).toString()
      try {
        const response = await axios.get(`/v1/info/banner/?${params}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })
        if (response.data.success === 'ok') {
          banners.value = response.data.data
        } else {
          banners.value = null
        }
      } catch (err) {
        throw new Error()
      }
    }

    const getServices = async () => {
      const param = new URLSearchParams({
        user_id: getUserId.value
      }).toString()
      areServicesLoading.value = true
      try {
        const response = await axios.get(`/v6/popular/?${param}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })

        if (response.data.status === 'ok') {
          allServices.value = response.data.data.all.filter((s) => s.icon_code)
          enabledServices.value = response.data.data.enabled.filter(
            (s) => s.icon_code
          )
        }
      } catch (err) {
        throw new Error()
      } finally {
        areServicesLoading.value = false
      }
    }

    const deleteOneService = async (id) => {
      const remainingIds = enabledServices.value
        .filter((s) => s.id !== id)
        .map((s) => s.id)
        .toSorted((a, b) => a - b)

      const param = new URLSearchParams({
        user_id: getUserId.value
      })

      try {
        const response = await axios.post(
          '/v6/popular/save/',
          {
            services: remainingIds
          },
          {
            params: param,
            headers: {
              Authorization: `Bearer ${userToken.value}`,
              'Content-Type': 'application/json'
            }
          }
        )

        if (response.data.status === 'ok') {
          enabledServices.value = enabledServices.value.filter(
            (s) => s.id !== id
          )
        } else {
          toast.success(
            {
              component: CustomToast,
              props: {
                title: 'Не удалось удалить сервис',
                text: 'Попробуйте еще раз',
                icon: 'error'
              }
            },
            toastOptions
          )
        }
      } catch (err) {
        throw new Error()
      }
    }

    const saveSorting = async () => {
      const sortedServices = enabledServices.value.map((s) => s.id)

      const param = new URLSearchParams({
        user_id: getUserId.value
      })

      const response = await axios.post(
        '/v6/popular/save/',
        {
          services: sortedServices
        },
        {
          params: param,
          headers: {
            Authorization: `Bearer ${userToken.value}`,
            'Content-Type': 'application/json'
          }
        }
      )
      if (response.data.status !== 'ok') {
        toast.success(
          {
            component: CustomToast,
            props: {
              title: 'Не удалось сохранить сервисы',
              text: 'Попробуйте еще раз',
              icon: 'error'
            }
          },
          toastOptions.value
        )
      }
    }

    const closeModal = () => {
      areSettingsOpen.value = false
      isServicesModalOpen.value = false
    }

    const closeSettings = async () => {
      areSettingsOpen.value = false
      await saveSorting()
    }

    const updateServices = async () => {
      await getServices()
    }

    watch(
      () => getUserId.value,
      async () => {
        await getServices()
        await getBanners()
      },
      { immediate: true }
    )

    watch(whiteBlockPosition, () => {
      if (whiteBlockPosition.value > -10) {
        window?.StatusBar?.backgroundColorByHexString('#C993F2')
      } else {
        window?.StatusBar?.backgroundColorByHexString('#FFF')
      }
    })

    watchEffect(() => {
      isServicesModalOpen.value
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = '')

      if (banners.value && banners.value.some((b) => b.type === 'version')) {
        bannerUpdateIsOpen.value = true
      }
    })

    onActivated(async () => {
      window?.StatusBar?.backgroundColorByHexString('#C993F2')
      window.addEventListener('scroll', updateWhiteBlockPosition)

      areSettingsOpen.value = false
      if (!isActivityBannerAvailable.value) {
        const isAvailable = await checkIfAvailable()
        const isAuth = await checkIfAuthorized()
        if (platform === 'android' && isAuth && isAvailable) {
          isActivityBannerAvailable.value = true
        } else if (platform === 'ios' && isAvailable) {
          isActivityBannerAvailable.value = true
        }
      }
    })

    onDeactivated(() => {
      window?.StatusBar?.backgroundColorByHexString('#FFF')
      window.removeEventListener('scroll', updateWhiteBlockPosition)
    })

    const updateWhiteBlockPosition = () => {
      if (whiteBlock.value) {
        const domRect = whiteBlock.value?.getBoundingClientRect()
        whiteBlockPosition.value = domRect.y
      }
    }

    return {
      whiteBlock,
      platform,
      bannerLink,
      hasPinCode,
      isServicesModalOpen,
      isActivityBannerAvailable,
      userToken,
      getUserId,
      hasHealth,
      allServices,
      enabledServices,
      areServicesLoading,
      bannerUpdateIsOpen,
      bannersFiltered,
      areSettingsOpen,
      saveSorting,
      updateServices,
      deleteOneService,
      closeModal,
      closeSettings
    }
  }
}
</script>

<style scoped lang="scss">
.home-wrapper {
  padding-top: 220px;
  @include desktop {
    padding-top: 0;
  }
  position: relative;
}
.violet-block {
  position: fixed;
  height: 60vh;
  top: 0;
  width: 100%;
  background: linear-gradient(345.05deg, #8cbfff 12.78%, #cc91f2 91.07%);;
  padding-top: 16px;
  display: block;
  @include desktop {
    display: none;
  }
}
.white-block {
  box-shadow:
  -10px -10px 8px -8px rgba(0, 0, 0, 0.12),
  10px -10px 8px -8px rgba(0, 0, 0, 0.12);
  padding-top: 16px;
  background: #fff;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transform: translateY(-16px);

  @include desktop {
    box-shadow: none;
    background: transparent;
  }
}
.modal-wrapper {
  position: fixed;
  background: #00285680;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 203;
}
</style>
