import { dictMonthRod } from '@/constants/dates.js'

export const formatDateToRod = (dateString, omitYear) => {
  if (!dateString) return ''
  const date = new Date(dateString)

  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  const monthName = dictMonthRod[month]

  if (omitYear) {
    return `${day} ${monthName.toLowerCase()}`
  } else {
    return `${day} ${monthName.toLowerCase()} ${year}`
  }
}

export const getDayWord = (number) => {
  const absNumber = Math.abs(number)
  const lastTwoDigits = absNumber % 100
  const lastDigit = absNumber % 10
  let dayWord = ''

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    dayWord = ' дней'
  } else if (lastDigit === 1) {
    dayWord = ' день'
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    dayWord = ' дня'
  } else {
    dayWord = ' дней'
  }

  return number + dayWord
}

export const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)
