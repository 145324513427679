<template>
  <div class="modal-payment">
    <div class="container">
      <div class="modal-payment__title">
        <a class="section-title__back" @click.prevent="closePayment">
          <img src="@/assets/uploads/icon/common/arrow-back.svg" alt="стрелка" />
        </a>
        <h3>Подтверждение оплаты</h3>
      </div>
      <div class="modal-payment__content">
        <img src="@/assets/uploads/images/finance.svg" alt="" />
        <p class="modal-payment__content-head">Оплата счета</p>
        <p class="modal-payment__content-clinic">{{ paymentData.clinic }}</p>
        <p class="modal-payment__content-sum">
          на сумму
          {{
            paymentData.summ
          }}
          рублей
        </p>
        <p class="modal-payment__content-payment-method">
          Выберите способ оплаты
        </p>
        <div class="modal-payment__content-payment-banners">
          <div class="modal-payment__content-mir-pay" @click="isMir = 1" :class="{ active: isMir === 1 }">
            <img src="@/assets/uploads/images/payment/mir-logo.svg" alt="мир" />
          </div>
          <div class="modal-payment__content-other-pay" @click="isMir = 0" :class="{ active: isMir === 0 }">
            <img src="@/assets/uploads/images/payment/visa-logo.svg" alt="виза" />
            <img src="@/assets/uploads/images/payment/mastercard-logo.svg" alt="мастеркард" />
            <img src="@/assets/uploads/images/payment/unionPay-logo.svg" alt="юнионпэй" />
          </div>
        </div>
        <div class="modal-payment__btns-container">
          <CustomButton v-if="isPhone" @click.prevent="goPay" class="btn" :disabled="paymentLink === null"
            :small="false">
            Оплатить
          </CustomButton>
          <CustomButton v-else :href="paymentLink" :target="'_blank'" class="btn" :disabled="paymentLink === null"
            :small="false">
            Оплатить
          </CustomButton>

          <CustomButton class="btn" :white="true" :small="false"
            :disabled="paymentLink === null" @click.prevent="copyLink(paymentLink)" :class="{noBorder: paymentLink === null}">
            Скопировать ссылку
          </CustomButton>
        </div>

        <ErrorText :showBtn="false" :error="error" :textAlign="'center'" />

      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs, watch } from 'vue'
import { gosuslugiListner } from '@/utilits/gosuslugiListner'
import { postLog } from '@/api/postLog'
import { useRouter } from 'vue-router'
import { useYandexMetrikaStore } from '@/stores/YandexMetrikaStore'
import { showToast } from '@/utilits/showToast'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { getFinancePaymentLink } from '@/api/payment/getFinancePaymentLink.js'
import ErrorText from '@/components/ui/ErrorText.vue'

export default {
  name: 'ModalPayment',
  emits: ['closePayment'],
  components: { CustomButton, ErrorText },
  props: {
    paymentData: {
      type: [Object, null],
      required: true,
      default: null
    },
    currentFilial: {
      type: Number,
      required: true
    },
    isDeposit: {
      type: Boolean,
      required: false
    }
  },
  setup (props, { emit }) {
    const metrikaStore = useYandexMetrikaStore()

    const { paymentData, currentFilial, isDeposit } = toRefs(props)
    const isMir = ref(null)
    const error = ref('')
    const paymentLink = ref(null)
    const router = useRouter()

    const isPhone = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform === 'ios' || platform === 'android' ? 1 : 0
    })

    const formatDeposit = (sum) => {
      return new Intl.NumberFormat('ru-RU', {
        maximumSignificantDigits: 6
      })
        .format(Math.abs(sum) / 100)
        .toLocaleString('en', { useGrouping: true })
        .replaceAll(',', ' ')
    }

    const goPay = () => {
      metrikaStore.sendGoal('finance_pay_submit', {
        filial: currentFilial.value,
        summ: paymentData.value.summ
      })

      // if (!isPhone.value) {
      //   window.location = paymentLink.value
      //   return
      // }

      gosuslugiListner(paymentLink.value)
        .then((event) => {
          let token

          if (event.data.match(/^oauth::/)) {
            token = JSON.parse(event.data.substring(7))
          }

          postLog({
            token: token.result
          })

          postLog({
            token
          })

          if (token === 'success') {
            showToast({
              title: isDeposit.value ? 'Аванс внесен' : 'Счета оплачены',
              text: '',
              icon: 'success'
            })
            router.push('/finance')
          }
          if (token === 'failed') {
            showToast({
              title: isDeposit.value
                ? 'Не удалось внести аванс'
                : 'Не удалось оплатить счета',
              text: 'Попробуйте еще раз',
              icon: 'error'
            })
            router.push('/finance')
          }
        })
        .catch(() => {
          showToast({
            title: isDeposit.value
              ? 'Не удалось внести аванс'
              : 'Не удалось оплатить счета',
            text: 'Попробуйте еще раз',
            icon: 'error'
          })
          router.push('/finance')
        })
        .finally(() => {
          closePayment()
        })
    }

    const closePayment = () => {
      isMir.value = null
      paymentLink.value = null
      emit('closePayment')
    }

    const copyLink = (link) => {
      showToast({
        title: 'Ссылка скопирована',
        icon: 'success'
      })
      navigator.clipboard.writeText(link)
    }

    watch(isMir, async () => {
      if (isMir.value !== null) {
        paymentLink.value = null
        try {
          paymentLink.value = await getFinancePaymentLink({
            isPhone: isPhone.value,
            isMir: isMir.value,
            summ: paymentData.value.summ * 100,
            legalEntityId: paymentData.value.legalEntityId
          })
        } catch (err) {
          console.log(err)
          error.value = err
        }
      }
    })

    return {
      isMir,
      goPay,
      closePayment,
      formatDeposit,
      copyLink,
      paymentLink,
      error,
      isPhone
    }
  }
}
</script>

<style scoped lang="scss">
.modal-payment {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  padding: 12px 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: 100;
}

.modal-payment__title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin-bottom: v-bind('mb');

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  img {
    filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg) brightness(102%) contrast(103%);
  }
}

.modal-payment__content {
  text-align: center;

  img {
    margin-bottom: 32px;
  }

  margin-top: 24px;
}

.modal-payment__content-head {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.modal-payment__content-clinic {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: #002856;
}

.modal-payment__content-sum {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  margin-bottom: 16px;
}

.modal-payment__content-payment-method {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #002856;
}

.section-title__back {
  cursor: pointer;
}

.modal-payment__content-payment-banners {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 24px 0;

  img {
    margin: 0;
  }
}

.modal-payment__content-mir-pay {
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  border: 2px solid #e1e8f4;
  border-radius: 8px;
  max-width: 200px;

  &.active {
    border: 2px solid #002856;
  }
}

.modal-payment__content-other-pay {
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  height: 54px;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid #e1e8f4;
  border-radius: 8px;
  max-width: 200px;

  &.active {
    border: 2px solid #002856;
  }
}

.btn-copy {
  background-color: #fff;
}

.modal-payment__btns-container {
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  a {
    max-width: 410px;
  }
}

.modal-payment__copy-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: $blue;
  text-align: center;
}

.noBorder {
  border: none;
}
</style>
