<script>
import { computed, inject, onMounted, ref, toRefs, watchEffect } from 'vue'
import { useUserGlucoseStore } from '@/stores/UserGlucoseStore'
import { authApi } from '@/api/authApi'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'

export default {
  name: 'GlucoseListItem',
  emits: ['updateGlucose', 'openAddForm'],
  props: {
    glucose: {
      type: Object,
      required: true
    }
  },

  setup (props, { emit }) {
    const typeToColor = {
      1: '#D1F9EF',
      2: '#F5FBCD',
      3: '#FBE1CD',
      4: '#E1E8F4'
    }
    const typeToIcon = {
      1: 'glucose-icon-sm',
      2: 'meal-icon-sm',
      3: 'activity-icon-sm',
      4: 'drugs-icon-sm'
    }
    const platform = window?.device?.platform.toLowerCase()
    const isPhone = computed(() => {
      return platform === 'ios' || platform === 'android' ? 1 : 0
    })
    const dayjs = inject('dayjs')
    const imagesListOpen = ref(false)
    const router = useRouter()
    const userGlucoseStore = useUserGlucoseStore()
    const { glucoseMeasureUnit } = storeToRefs(userGlucoseStore)
    const { glucose } = toRefs(props)
    const glucoseStatus = computed(() => {
      return glucose.value.status_id
    })
    const userStore = useUserStore()
    const { getUserId } = storeToRefs(userStore)

    const disabledBtn = () => {
      return new Date() < new Date(glucose.value.time_at.split(' ').join('T'))
    }

    // 1 - Внести измерения / Пропустить
    // 5 - Измерено / Отменить
    // 8 - Пропущено / Внести данные
    const btnConfirmText = (status) => {
      if (status === 1) {
        return 'Внести измерения'
      } else if (status === 5) {
        return 'Измерено'
      } else {
        return 'Пропущено'
      }
    }

    const btnCancelText = (status) => {
      if (status === 1) {
        return 'Пропустить'
      } else if (status === 5) {
        return 'Отменить'
      } else {
        return 'Внести данные'
      }
    }

    const changeCurrentGlucose = (typeId) => {
      userGlucoseStore.setCurrentGlucose(glucose.value)
      if (typeId === 1) router.push('calendar-glucose-measurment')
      if (typeId === 2) router.push('calendar-glucose-meal')
      if (typeId === 3) router.push('calendar-glucose-activity')
      if (typeId === 4) router.push('calendar-medications')
    }

    const setGlucose = async (statusId) => {
      const path = `/v6/events/diabet/set/?user_id=${getUserId.value}&id=${glucose.value.id}`
      const sugarValue = glucoseMeasureUnit.value.id === 1 ? 18.01 : 1
      const [err, response] = await authApi(path, 'post', {
        status_id: statusId,
        sugar: sugarValue,
        when_id: 1
      })
      return [err, response]
    }

    const changeGlucoseInfo = (typeId) => {
      if (glucoseStatus.value === 1) {
        changeCurrentGlucose(typeId)
      }
    }

    const changeStatusGlucose = async (typeId) => {
      if (glucoseStatus.value === 5) {
        await setGlucose(1)
        emit('updateGlucose')
      }

      if (glucoseStatus.value === 1) {
        await setGlucose(8)
        emit('updateGlucose')
      }

      if (glucoseStatus.value === 8) {
        changeCurrentGlucose(typeId)
      }
    }

    const checkIfPastCurrentTime = (glucoseTime) => {
      return dayjs() > dayjs(glucoseTime)
    }

    const goToImage = (url) => {
      if (isPhone.value === 1) {
        if (platform === 'ios') {
          window.cordova.InAppBrowser.open(
            url,
            '_blank',
            'location=no,zoom=no'
          )
        } else {
          window.cordova.InAppBrowser.open(
            url,
            '_blank',
            'location=no,zoom=no,toolbar=no'
          )
        }
      } else {
        window.open(url, '_blank', 'noopener,noreferrer')
      }
    }

    onMounted(async () => {
      // менять статус на прошедший, если прошло 2часа
      if (glucoseStatus.value === 1 && glucose.value.type_id !== 4) {
        const currentDateBeforeTwoHours = dayjs().subtract(2, 'hour')
        if (currentDateBeforeTwoHours > dayjs(glucose.value.time_at)) {
          await setGlucose(8)
          emit('updateGlucose')
        }
      }
    })

    watchEffect(() => {
      // console.log('glucoseStatus', glucoseStatus.value)
      // console.log('glucose.type_id', glucose.value)
      // console.log('glucoseMeasureUnit', glucoseMeasureUnit.value)
    })

    return {
      glucoseStatus,
      btnConfirmText,
      btnCancelText,
      changeCurrentGlucose,
      disabledBtn,
      changeStatusGlucose,
      changeGlucoseInfo,
      glucoseMeasureUnit,
      typeToColor,
      typeToIcon,
      checkIfPastCurrentTime,
      goToImage,
      imagesListOpen
    }
  }
}
</script>

<template>
  <div
    v-if="checkIfPastCurrentTime(glucose.time_at)"
    class="glucose-item__wrapper"
  >
    <div class="glucose-item__icon-wrapper">
      <div :style="{ background: typeToColor[glucose.type_id] }">
        <img
          :src="
            require(`@/assets/uploads/images/calendar/glucose/${
              typeToIcon[glucose.type_id]
            }.svg`)
          "
          alt="измерение"
        />
      </div>
    </div>

    <div
      class="glucose-item"
      :style="{ background: typeToColor[glucose.type_id] }"
      @click="changeCurrentGlucose(glucose.type_id)"
    >
      <div class="glucose-item__top">
        <div class="glucose-item__info">
          <div class="glucose-item__glucose">
            <div v-if="glucose.type_id === 1" class="glucose-item__number">
              <p v-if="glucose.status_id === 5">
                {{ glucose.sugar }} {{ glucoseMeasureUnit.name }}
              </p>
              <p v-else>Не измерено</p>
            </div>
            <div v-if="glucose.type_id === 2" class="glucose-item__number">
              Питание
            </div>
            <div v-if="glucose.type_id === 3" class="glucose-item__number">
              Активность
            </div>
            <div v-if="glucose.type_id === 4" class="glucose-item__number">
              Лекарства
            </div>
            <div
              v-if="
                glucose.when_id &&
                glucose.type_id === 1 &&
                glucose.status_id !== 8
              "
              class="glucose-item__desc"
            >
              {{ glucose.when_id === 1 ? 'Натощак' : 'После приема пищи' }}
            </div>
          </div>
        </div>

        <div class="glucose-item__date">
          <img
            src="@/assets/uploads/images/home-page/my-appointment/clock.svg"
            alt="картинка часов"
          />
          <h5>{{ glucose.time }}</h5>
        </div>
      </div>

      <div
        v-if="glucose.hint && glucose.status_id === 5"
        class="glucose-item__bottom"
      >
        {{ glucose.hint }}
      </div>
      <div
        v-if="glucose.type_id === 4"
        class="glucose-item__bottom"
      >
        {{ glucose.hint }}
        <div class="glucose-item__pills-warning">
          <img
            src="@/assets/uploads/icon/common/blue-warning.svg"
            alt="важно"
          />
          <p>Данные из календаря «Прием лекарств»</p>
        </div>
      </div>

      <div v-if="glucose.files.length">
        <div
          v-show="glucose.files.length > 3 && !imagesListOpen"
          class="glucose-item__files"
        >
          <a
            v-for="file in glucose.files.slice(2)"
            :key="file.id"
            @click.stop="goToImage(file.url)"
          >
            <img :src="file.url" alt="превью приема пищи" />
          </a>
          <div
            @click.stop="imagesListOpen = true"
            class="glucose-item__files-number"
          >
            +{{ glucose.files.length - 3 }}
          </div>
        </div>
        <div
          v-show="!(glucose.files.length > 3 && !imagesListOpen)"
          class="glucose-item__files"
        >
          <a
            v-for="file in glucose.files"
            :key="file.id"
            @click.stop="goToImage(file.url)"
          >
            <img :src="file.url" alt="превью приема пищи" />
          </a>
        </div>
      </div>

      <div class="glucose-item__btns" v-if="glucose.type_id === 1">
        <button
          v-if="glucoseStatus !== 8"
          type="button"
          @click.stop="changeGlucoseInfo(glucose.type_id)"
          class="glucose-item__confirm"
          :disabled="
            glucoseStatus === 5 || glucoseStatus === 8 || disabledBtn()
          "
          :class="{
            active: glucoseStatus === 1,
            cancel: glucoseStatus === 8 || disabledBtn(),
            complete: glucoseStatus === 5
          }"
        >
          <img
            v-if="glucoseStatus === 5"
            src="@/assets/uploads/icon/calendar/confirm-icon.svg"
            alt="галочка успеха"
          />
          <span>{{ btnConfirmText(glucoseStatus) }}</span>
        </button>
        <button
          @click.stop="changeStatusGlucose(glucose.type_id)"
          class="glucose-item__cancel"
          type="button"
          :disabled="disabledBtn()"
          :class="{
            cancel: disabledBtn()
          }"
        >
          {{ btnCancelText(glucoseStatus) }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.glucose-item__wrapper {
  display: flex;
  width: 100%;
}

.glucose-item__icon-wrapper {
  margin-right: 10px;
  z-index: 10;

  > div {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
}

.glucose-item {
  cursor: pointer;
  padding: 16px;
  border-radius: 8px;
  position: relative;
  flex: 1;
}

.glucose-item::after {
  content: '';
  position: absolute;
  background-color: #bac7de;
  width: 1px;
  height: 100%;
  top: 16px;
  left: -26px;
}

.glucose-item__top {
  display: flex;
  align-items: flex-start;
  min-height: 40px;
}

.glucose-item__info {
  display: flex;
  gap: 16px;
}

// .glucose-item__glucose {
// }

.glucose-item__btn {
  margin-left: auto;
  padding: 5px;
  background: unset;
  border: unset;
}

.glucose-item__number {
  color: #002856;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}

.glucose-item__desc {
  text-align: end;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

// .glucose-item__minute {
// }

.glucose-item-feel {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  display: flex;
  align-items: center;
}

// .glucose-item__minute-desc {
// }
.glucose-item__arrow-feel-wrapper {
  display: flex;
}

.glucose-item__bottom {
  margin-top: 16px;
}

.glucose-item__date {
  margin-left: auto;
  gap: 5px;
  color: #222;
  display: flex;
  align-items: center;
  line-height: 1;

  > img {
    filter: brightness(0) saturate(100%) invert(14%) sepia(92%) saturate(1155%)
      hue-rotate(188deg) brightness(90%) contrast(109%);
  }

  & h5 {
    margin-top: 2px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-align: left;
  }

  & p {
    font-size: 14px;
  }
}

.glucose-item__arrow {
  margin-top: 4px;
  rotate: -90deg;
}

.glucose-item__bottom {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #002856;
}

.glucose-item__btns {
  padding-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;

  z-index: 10;

  & button {
    padding: 8px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    min-width: 102px;
    width: 100%;
    max-width: 152px;
  }
}

.glucose-item__confirm {
  border: unset;
  display: flex;
  gap: 8px;

  &.active {
    color: $blue;
    background: $blue-light;
    border: 1px solid $blue-light;
  }

  &.cancel {
    color: #fff;
    border: 1px solid #c4c4c4;
    background: #c4c4c4;
  }

  &.complete {
    border: 1px solid $blue;
    background: $blue;
    color: #fff;
  }
}

.glucose-item__more {
  border: unset;
  color: $blue;
  text-decoration-line: underline;
}

.glucose-item__cancel {
  border: unset;
  border: 1px solid $blue;

  &.cancel {
    color: #fff;
    border: 1px solid #c4c4c4;
    background: #c4c4c4;
  }
}

// .feel-red {
//     color: #FF1F00
// }
// .feel-green {
//     color: #43D854;
// }

.glucose-item__icon {
  margin-left: auto;
  margin-right: 20px;
}

.glucose-item__files {
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  a {
    z-index: 10;
    cursor: pointer;
  }

  img {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    object-fit: cover;
  }
}

.glucose-item__files-number {
  z-index: 50;
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.glucose-item__pills-warning {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;

  p {
    font-family: Circe;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
</style>
