import { ref } from 'vue'

const isModalPaymentOpened = ref(false)

export const useModalPayment = () => {
  const closeModalPayment = () => {
    isModalPaymentOpened.value = false
  }
  const openModalPayment = () => {
    isModalPaymentOpened.value = true
  }
  return {
    isModalPaymentOpened,
    closeModalPayment,
    openModalPayment
  }
}
