<script>
import cloneDeep from 'lodash.clonedeep'
import GlucoseListItem from '@/components/pages/calendar/glucose/GlucoseListItem.vue'
import { computed, inject, ref, toRefs } from 'vue'
import { useDateStore } from '@/stores/date'
import TheBanner from '@/components/ui/announce/TheBanner.vue'

export default {
  name: 'GlucoseList',
  components: { TheBanner, GlucoseListItem },
  emits: ['updateGlucose', 'openAddForm'],
  props: {
    glucoseList: {
      type: Array,
      required: true
    }
  },

  setup (props) {
    const bannerIsActive = ref(true)
    const { glucoseList } = toRefs(props)
    const storeDate = useDateStore()
    const dictMonth = storeDate.dictMonthRod
    const dayjs = inject('dayjs')

    const nearDayOfRescipt = (date) => {
      const nearDateOfDoctor = dayjs(date)
      const dayOfMonth = nearDateOfDoctor.date()
      const month = dictMonth[nearDateOfDoctor.month()].toLowerCase()
      return `${dayOfMonth} ${month}`
    }

    const checkIfPastCurrentTime = (glucoseTime) => {
      return glucoseTime.find(g => dayjs() > dayjs(g.time_at))
    }

    const glucoseListFiltred = computed(() => {
      const mergedDaysList = {}
      glucoseList.value.forEach(item => {
        const date = item.date

        if (mergedDaysList[date]) {
          mergedDaysList[date].time = mergedDaysList[date].time.concat(item.time)

          if (item.id === 1) {
            mergedDaysList[date].id = item.id
            mergedDaysList[date].hint = item.hint
          }
        } else {
          mergedDaysList[date] = { ...item }
        }
      })

      const result = Object.values(mergedDaysList)

      result.forEach(item => {
        item.time.sort((a, b) => a.time.localeCompare(b.time))
      })

      const glucoseListClone = cloneDeep(result)
      return glucoseListClone.sort((pressureA, pressureB) =>
        new Date(pressureA.date) > new Date(pressureB.date) ? -1 : 1
      )
    })

    return {
      nearDayOfRescipt,
      glucoseListFiltred,
      bannerIsActive,
      dayjs,
      checkIfPastCurrentTime
    }
  }
}
</script>

<template>
  <div class="glucose-list">
    <div v-for="(glucose, index) in glucoseListFiltred" :key="glucose.id">
      <div v-if="checkIfPastCurrentTime(glucose.time)" class="glucose-list__wrapper">
        <div v-if="glucoseListFiltred.length > 1" class="glucose-list__date">
          {{ nearDayOfRescipt(glucose.date) }}
        </div>
        <div class="glucose-list__list">
          <GlucoseListItem
            v-for="glucoseItem in glucose.time.toReversed()"
            :key="glucoseItem.id"
            :glucose="glucoseItem"
            @updateGlucose="$emit('updateGlucose')"
            @openAddForm="$emit('openAddForm')"
          />
        </div>
      </div>

      <TheBanner
        v-if="index === 0 && bannerIsActive"
        class="glucose-list__banner"
        title="Календарь приема лекарств"
        text="Незаменимый помощник в контроле за здоровьем"
        link="/calendar-medications"
        @closeBanner="bannerIsActive = false"
      >
        <template #img>
          <img
            src="@/assets/uploads/images/calendar/pills.png"
            alt="календарь лекарств"
          />
        </template>
      </TheBanner>
    </div>
  </div>
</template>

<style scoped lang="scss">

.glucose-list__wrapper {
    margin-top: 20px;
}

.glucose-list__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;

}

.glucose-list__date {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  padding-bottom: 16px;
}

.glucose-list__banner {
  padding-top: 20px;
}
</style>
