<script>

export default {
  name: 'PregnancyBanner',
  emits: ['closeBanner'],
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup () {

  }
}
</script>

<template>
  <div  class="banner">
    <div class="banner__wrapper">
      <div class="banner-text">
          <h4 class="banner-text__title">{{ title }}</h4>
          <div class="banner-text__desc-wrapper">
          <p class="banner-text__desc">
            Предполагаемая дата родов
          </p>
          </div>
      </div>
      <div class="banner__picture">
        <img class="banner__picture-img" src="@/assets/uploads/sprite/pregnancy-banner.svg" alt="картинка баннера">
      </div>
      <div class="banner__btm-wrapper">
        <button @click="$emit('closeBanner')" class="banner__close btn-close"></button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner {
  margin-bottom: 24px;
}
.banner__wrapper {
  background-color: #BAC7DE;
  color: #002856;
  height: 80px;
  display: flex;
  border-radius: 6px;
  border: 2px solid #00000000;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.5px #F1842C;

}

.banner-text {
  padding-top: 12px;
  padding-left: 10px;
  position: relative;
  width: 55%;
  flex: 1;
}

.banner-text__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  margin-bottom: 8px;
}

.banner-text__desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  vertical-align: baseline;
}

.banner__picture {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}

.banner__picture-img {

}

.banner-text__desc-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}
.banner__btm-wrapper {
    padding: 8px 8px 0 0;
}
</style>
