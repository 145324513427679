<template>
  <div class="banners-wrapper">
    <Swiper v-bind="swiperOptions">
      <swiper-slide v-for="banner in banners" :key="banner.title">
        <BannerItem
          :title="banner.title"
          :subtitle="banner.preview"
          :color="banner.preset"
          :img="banner.image_url"
          :action="getBannerAction(banner)"
          :isActionExternal="checkIfActionExternal(banner)"
          :type="banner.type"
        />
      </swiper-slide>
      <div class="swiper-banner__pagination"></div>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { ref } from 'vue'
import BannerItem from '@/components/pages/home/BannerItem.vue'
import { Pagination } from 'swiper'

export default {
  name: 'BannerSection',
  props: {
    banners: [Object]
  },
  components: { Swiper, SwiperSlide, BannerItem },
  setup () {
    const swiperOptions = ref({
      initialSlide: 0,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-banner__pagination',
        clickable: true
      },
      breakpoints: {
        0: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: 2
        },
        991: {
          slidesPerView: 1
        },
        1145: {
          slidesPerView: 2
        }
      },
      modules: [Pagination]
    })

    const getBannerAction = (links) => {
      if (links.external_url === '/' || !links.external_url) {
        return links.router_url
      } else {
        return links.external_url
      }
    }

    const checkIfActionExternal = (links) => {
      if (links.external_url === '/' || !links.external_url) {
        return false
      } else {
        return true
      }
    }

    return {
      swiperOptions,
      checkIfActionExternal,
      getBannerAction
    }
  }
}
</script>

<style scoped lang="scss">
.banners-wrapper {
 margin-bottom: 16px;
}
.swiper-banner__pagination {
  margin-top: 24px;
  text-align: center;
}

</style>

<style>
.swiper-banner__pagination .swiper-pagination-bullet {
    background-color: #00000000;
    border: 1px solid #002856
}
.swiper-banner__pagination .swiper-pagination-bullet-active {
    background-color: #002856
}
</style>
