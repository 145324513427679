<template>
  <ArrowTitle :is-app="isApp" title="Налоговый вычет"></ArrowTitle>
  <SectionWrapper stretched>
    <div class="patient-form">
      <h2>Запрос справки для налогового вычета</h2>
      <p>Для предзаполнения данных войдите через Госуслуги</p>
      <div class="alert-msg">
        <img
          src="@/assets/uploads/icon/calendar/period-alert.svg"
          alt="знак внимания"
          class="alert-msg__picture"
        />
        <p class="alert-msg__text">
          Справка о расходах за текущий календарный год будет выдана в следующем
          календарном году.
        </p>
      </div>
      <form @submit.prevent="submitForm" @keyup.enter.prevent="submitForm">
        <h3>Клиника</h3>
        <div class="patient-form__clinic">
          <div class="form-group arrow">
            <select
              id="patientprofile-filialid"
              class="form-control input"
              name="filials"
              v-model="selectFilial"
            >
              <option value="" disabled>
                {{
                  filteredFilials.length > 0
                    ? 'Выберите'
                    : 'Выбраны все филиалы'
                }}
              </option>
              <option
                v-for="(filial, index) in filteredFilials"
                :key="index"
                :value="filial.id"
              >
                {{ filial.name }}
              </option>
            </select>
          </div>
          <ul class="clinics" v-if="selectedFilials.length > 0">
            <li
              class="clinics-item"
              v-for="(filial, index) in selectedFilials"
              :key="index"
            >
              <p>{{ filial.name }}</p>
              <button
                class="clinics-item__delete"
                type="button"
                @click="deselectClinic(filial.id)"
              >
                <img
                  src="@/assets/uploads/icon/calendar/close.svg"
                  alt="кнопка закрытия"
                />
              </button>
            </li>
          </ul>
          <p class="clinics-help-block">
            Вы можете выбрать более одной клиники
          </p>
          <small
            id="inputNameError"
            v-if="v$.filial_id.$error"
            class="form-text text-error help-block error"
            >Необходимо выбрать клинику</small
          >
        </div>

        <h3>Налогоплательщик</h3>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Фамилия</label>
              <input
                type="text"
                id=""
                class="form-control"
                name=""
                v-model="userFormData.last_name"
              />
              <small
                id="inputNameError"
                v-if="v$.last_name.$error"
                class="form-text text-error help-block error"
                >Необходимо ввести фамилию</small
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Имя</label>
              <input
                type="text"
                id=""
                class="form-control"
                name=""
                v-model="userFormData.name"
              />
              <small
                id="inputNameError"
                v-if="v$.name.$error"
                class="form-text text-error help-block error"
                >Необходимо ввести имя</small
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Отчество</label>
              <input
                type="text"
                id=""
                class="form-control"
                name=""
                v-model="userFormData.middle_name"
              />
              <small
                id="inputNameError"
                class="form-text text-error help-block"
              ></small>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Дата рождения</label>
              <DatePicker
                class="text-input login"
                v-model:value="userFormData.birthday"
                value-type="YYYY-MM-DD"
                format="DD.MM.YYYY"
                :monday-first="true"
                :full-month-name="true"
                :lang="'ru'"
                :max-date="new Date()"
              />
              <small
                id="inputNameError"
                v-if="v$.birthday.$error"
                class="form-text text-error help-block error"
                >Необходимо указать дату рождения</small
              >
            </div>
          </div>
        </div>
        <h3>Контакты и документы</h3>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Телефон</label>
              <input
                type="text"
                id=""
                class="form-control"
                name=""
                v-model="userFormData.phone"
              />
              <small
                id="inputNameError"
                v-if="v$.phone.$error"
                class="form-text text-error help-block error"
                >Необходимо ввести телефон</small
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Email</label>
              <input
                type="text"
                id=""
                class="form-control"
                name=""
                v-model="userFormData.email"
              />
              <small
                id="inputNameError"
                v-if="v$.email.$error"
                class="form-text text-error help-block error"
                >Необходимо ввести Email</small
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">ИНН</label>
              <input
                type="text"
                id=""
                class="form-control"
                name=""
                v-model="userFormData.inn"
              />
              <small
                id="inputNameError"
                v-if="v$.inn.$error"
                class="form-text text-error help-block error"
                >Необходимо заполнить «Номер ИНН»</small
              >
            </div>
          </div>
        </div>

        <div class="patient-form__switch">
          <div class="patient-form__switch-body">
            <h3>Налогоплательщик не является пациентом</h3>
          </div>
          <ToggleSwitch v-model="userFormData.is_one_person"></ToggleSwitch>
        </div>
        <div class="row" v-if="userFormData.is_one_person">
          <h3>Пациент</h3>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Фамилия</label>
              <input
                type="text"
                id=""
                class="form-control"
                name=""
                v-model="userFormData.patient_last_name"
              />
              <small
                id="inputNameError"
                v-if="v$.patient_last_name.$error"
                class="form-text text-error help-block error"
                >Необходимо заполнить «Фамилия»</small
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Имя</label>
              <input
                type="text"
                id=""
                class="form-control"
                name=""
                v-model="userFormData.patient_name"
              />
              <small
                id="inputNameError"
                v-if="v$.patient_name.$error"
                class="form-text text-error help-block error"
                >Необходимо заполнить «Имя»</small
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Отчетство</label>
              <input
                type="text"
                id=""
                class="form-control"
                name=""
                v-model="userFormData.patient_middle_name"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Дата рождения</label>
              <DatePicker
                class="text-input login"
                v-model:value="userFormData.patient_birthday"
                value-type="YYYY-MM-DD"
                format="DD.MM.YYYY"
                :monday-first="true"
                :full-month-name="true"
                :lang="'ru'"
                :max-date="new Date()"
              />
              <small
                id="inputNameError"
                v-if="v$.patient_birthday.$error"
                class="form-text text-error help-block error"
                >Необходимо заполнить «Дата рождения»</small
              >
            </div>
          </div>
        </div>
        <div class="row" v-if="userFormData.is_one_person">
          <h3>Кем приходится пациент</h3>
          <div class="form-group arrow-patient">
            <select
              id="patientprofile-filialid"
              class="form-control input"
              name=""
              v-model="userFormData.relation"
            >
              <option value="">Выберите</option>
              <option value="Супруг/Супруга">Супруг/Супруга</option>
              <option value="Сын/дочь">Сын/дочь</option>
              <option value="Мать/отец">Мать/отец</option>
            </select>
            <small
              id="inputNameError"
              v-if="v$.relation.$error"
              class="form-text text-error help-block error"
              >Необходимо заполнить «Степень родства»</small
            >
          </div>
          <!--          <div class="patient-form__radio">-->
          <!--            <FormRadio-->
          <!--              :label="'Родитель несовершеннолетнего'"-->
          <!--              :name="'relation'"-->
          <!--              :value="0"-->
          <!--              v-model="userFormData.relation"/>-->
          <!--            <FormRadio-->
          <!--              :label="'Опекун'"-->
          <!--              :name="'relation'"-->
          <!--              :value="1"-->
          <!--              v-model="userFormData.relation"/>-->
          <!--          </div>-->
        </div>

        <h3>Период для налогового вычета</h3>
        <div class="patient-form__clinic">
          <div class="form-group arrow">
            <select
              id="patientprofile-filialid"
              class="form-control input"
              name="filials"
              v-model="selectYear"
            >
              <option value="" disabled>
                {{
                  filteredYears.length > 0
                    ? 'Выберите'
                    : 'Выбраны все доступные периоды'
                }}
              </option>
              <option
                v-for="(year, index) in filteredYears"
                :key="index"
                :value="year.year"
              >
                {{ year.year }}
              </option>
            </select>
          </div>
          <ul class="clinics" v-if="selectedYears.length > 0">
            <li
              class="clinics-item"
              v-for="(year, index) in selectedYears"
              :key="index"
            >
              <p>{{ year.year }}</p>
              <button
                class="clinics-item__delete"
                type="button"
                @click="deselectYears(year.year)"
              >
                <img
                  src="@/assets/uploads/icon/calendar/close.svg"
                  alt="кнопка закрытия"
                />
              </button>
            </li>
          </ul>
          <p class="clinics-help-block">
            Вы можете выбрать несколько лет
          </p>
          <small
            id="inputNameError"
            v-if="v$.tax_year.$error"
            class="form-text text-error help-block error"
            >Необходимо выбрать год</small
          >
        </div>
        <div class="patient-form__switch">
          <div class="patient-form__switch-body">
            <h3>
              Договор на оказание медицинских услуг утерян (необходима копия)
            </h3>
          </div>
          <ToggleSwitch v-model="userFormData.is_copy_agreement"></ToggleSwitch>
        </div>
        <div class="checkbox-wrapper">
          <FormInputCheckbox
            class="ndfl-checkbox"
            id="register-checkbox"
            v-model="userFormData.is_terms"
            label="Я согласен на"
            linkLabel="обработку моих персональных данных"
            href="https://www.k31.ru/info/soglasie/"
          />
          <small
            id="inputNameError"
            v-if="v$.is_terms.$error"
            class="form-text text-error help-block error"
            >Необходимо согласие на обработку данных</small
          >
        </div>
        <div class="checkbox-wrapper">
          <FormInputCheckbox
            class="ndfl-checkbox"
            id="profile-rules"
            v-model="userFormData.is_rules"
            label="Я ознакомлен с"
            linkLabel="правилами внутреннего распорядка"
            href="https://www.k31.ru/upload/doc/useterms.pdf"
          />
          <small
            id="inputNameError"
            v-if="v$.is_rules.$error"
            class="form-text text-error help-block error"
            >Необходимо согласие</small
          >
        </div>

        <CustomButton tag="button" type="submit" marginBottom="20px">
          Отправить
        </CustomButton>
      </form>
      <ErrorText v-if="error" :text-align="'center'" :error="error" :showBtn="false"/>
      <ModalWrapper
        :modal-is-open="modalIsOpen"
        @modal-close="modalIsOpen = false"
        title="Запрос справки для налогового вычета"
      >
        <img src="@/assets/uploads/images/modal-call.svg" alt="" />
        <p class="modal-text--success">
          Спасибо, Ваша заявка принята. <br />
          Справка готовится в течение 30 календарных дней. Мы приложим все
          усилия, чтобы сделать её раньше, ожидайте пожалуйста.
        </p>
      </ModalWrapper>
    </div>
  </SectionWrapper>
</template>

<script>
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import { computed, nextTick, reactive, ref, toRefs, watch } from 'vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { useUserStore } from '@/stores/UserStore'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import 'vue-datepicker-next/locale/ru'
import FormInputCheckbox from '@/components/ui/form/FormInputCheckbox.vue'
import { storeToRefs } from 'pinia'
import { required, sameAs } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import ToggleSwitch from '@/components/ui/buttons/ToggleSwitch.vue'
import axios from 'axios'
import ModalWrapper from '@/components/Common/ModalWrapper.vue'
import { scrollToFirstError } from '@/utilits/scrollToFirstError'
import { useYandexMetrikaStore } from '@/stores/YandexMetrikaStore'
import ErrorText from '@/components/ui/ErrorText.vue'
// import FormRadio from '@/components/ui/form/FormRadio.vue'

export default {
  name: 'TaxFormView',
  components: {
    // FormRadio,
    ModalWrapper,
    FormInputCheckbox,
    CustomButton,
    SectionWrapper,
    ArrowTitle,
    DatePicker,
    ToggleSwitch,
    ErrorText
  },
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup (props) {
    const error = ref(null)
    const metrikaStore = useYandexMetrikaStore()
    const nowYear = new Date().getFullYear()
    const y = []
    for (let i = nowYear - 1; i >= nowYear - 3; i--) {
      y.push({ year: i, selected: false })
    }
    const years = ref(y)
    const { user } = toRefs(props)
    const userStore = useUserStore()
    const modalIsOpen = ref(false)
    const errorMessage = ref(false)
    const { getUserId, userToken } = storeToRefs(userStore)
    const filials = ref([
      { id: 1, selected: false, name: 'К+31 на Лобачевского' },
      { id: 3, selected: false, name: 'К+31 Петровские Ворота' },
      { id: 4, selected: false, name: 'К+31 Запад Оршанская' }
    ])

    const filteredFilials = computed(() => {
      return filials.value.filter((filial) => !filial.selected)
    })
    const filteredYears = computed(() => {
      return years.value.filter((year) => !year.selected)
    })
    const selectedFilials = computed(() => {
      return filials.value.filter((filial) => filial.selected)
    })
    const selectedYears = computed(() => {
      return years.value.filter((year) => year.selected)
    })
    const selectFilial = ref('')
    const selectYear = ref('')

    const filialIDs = computed(() => {
      return selectedFilials.value.map((filial) => filial.id)
    })

    const yearsToAdd = computed(() => {
      return selectedYears.value.map((year) => year.year)
    })

    const userBirthday = computed(() => {
      if (!user.value) return

      if (!user.value.birthday) {
        return 'не указана'
      }
      return user.value.birthday
    })

    const userPhone = computed(() => {
      if (!user.value) return
      return user.value.username
    })

    const userEmail = computed(() => {
      if (!user.value) return
      return user.value.email
    })
    const userFirstName = computed(() => {
      if (!user.value) return
      return user.value.first_name
    })
    const userLastName = computed(() => {
      if (!user.value) return
      return user.value.last_name
    })
    const userMiddleName = computed(() => {
      if (!user.value) return
      return user.value.middle_name
    })

    const updateComputedProps = () => {
      userFormData.filial_id = filialIDs.value
      userFormData.tax_year = yearsToAdd.value
    }

    const userFormData = reactive({
      filial_id: filialIDs.value,
      name: userFirstName.value,
      last_name: userLastName.value,
      middle_name: userMiddleName.value,
      phone: userPhone.value,
      email: userEmail.value,
      birthday: userBirthday.value,
      inn: '',
      tax_year: yearsToAdd.value,
      is_one_person: false,
      is_agreement: false,
      is_copy_agreement: false,
      patient_name: '',
      patient_last_name: '',
      patient_middle_name: '',
      patient_birthday: '',
      relation: '',
      file: '',
      is_terms: false,
      is_rules: false
    })

    watch(selectFilial, (filialId) => {
      if (filialId !== '') {
        const i = filials.value.findIndex((item) => item.id === filialId)
        filials.value[i].selected = true
        setTimeout(() => {
          selectFilial.value = ''
        }, 100)
      }
    })
    watch(selectYear, (year) => {
      if (year) {
        const i = years.value.findIndex((item) => item.year === year)
        years.value[i].selected = true
        setTimeout(() => {
          selectYear.value = ''
        }, 100)
      }
    })

    const deselectClinic = (id) => {
      const i = filials.value.findIndex((item) => item.id === id)
      filials.value[i].selected = false
    }
    const deselectYears = (year) => {
      const i = years.value.findIndex((item) => item.year === year)
      years.value[i].selected = false
    }
    const handleFilesUpload = (f) => {
      userFormData.file = f
    }

    const rules = computed(() => {
      const alwaysRequiredRules = {
        filial_id: { required },
        inn: { required },
        tax_year: { required },
        is_terms: { sameAs: sameAs(true) },
        is_rules: { sameAs: sameAs(true) },
        birthday: { required },
        name: { required },
        last_name: { required },
        phone: { required },
        email: { required }
      }

      const extraRules = {
        patient_name: { required },
        patient_last_name: { required },
        patient_birthday: { required },
        relation: { required }
        // file: { required }
      }

      return userFormData.is_one_person
        ? { ...alwaysRequiredRules, ...extraRules }
        : alwaysRequiredRules
    })

    const v$ = useVuelidate(rules, userFormData)

    const submitForm = async () => {
      updateComputedProps()
      v$.value.$touch()
      if (v$.value.$invalid) {
        await nextTick()
        scrollToFirstError(140)
        errorMessage.value = true
      } else {
        metrikaStore.sendGoal('form_ndfl')
        const fullName =
          userFormData.last_name +
          ' ' +
          userFormData.name +
          ' ' +
          userFormData.middle_name
        const patientFullName =
          userFormData.patient_last_name +
          ' ' +
          userFormData.patient_name +
          ' ' +
          userFormData.patient_middle_name
        const taxInfo = {
          user_id: getUserId.value,
          filial_id: userFormData.filial_id,
          is_filials: Number(userFormData.filial_id.length > 1),
          is_terms: Number(userFormData.is_terms),
          is_rules: Number(userFormData.is_rules),
          full_name: fullName,
          email: userFormData.email,
          phone: userFormData.phone,
          birthday: userFormData.birthday,
          tax_year: userFormData.tax_year,
          inn: userFormData.inn,
          is_one_person: Number(userFormData.is_one_person),
          is_agreement: Number(userFormData.is_agreement),
          is_copy_agreement: Number(userFormData.is_copy_agreement),
          patient_name: patientFullName,
          patient_birthday: userFormData.patient_birthday,
          file: userFormData.file,
          relation: userFormData.relation
        }
        try {
          const formData = new FormData()
          if (taxInfo.file) {
            formData.append('file', userFormData.file)
          }
          formData.append('user_id', taxInfo.user_id)
          formData.append('filial_id', taxInfo.filial_id)
          formData.append('is_filials', taxInfo.is_filials)
          formData.append('is_terms', taxInfo.is_terms)
          formData.append('is_rules', taxInfo.is_rules)
          formData.append('full_name', taxInfo.full_name)
          formData.append('email', taxInfo.email)
          formData.append('phone', taxInfo.phone)
          formData.append('birthday', taxInfo.birthday)
          formData.append('tax_year', taxInfo.tax_year)
          formData.append('inn', taxInfo.inn)
          formData.append('is_one_person', taxInfo.is_one_person)
          formData.append('is_agreement', taxInfo.is_agreement)
          formData.append('is_copy_agreement', taxInfo.is_copy_agreement)
          formData.append('patient_name', taxInfo.patient_name)
          formData.append('patient_birthday', taxInfo.patient_birthday)
          formData.append('relation', taxInfo.relation)
          console.log(formData)
          const postSupport = await axios.post(
            `/v6/form/ndfl/?user_id=${getUserId.value}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${userToken.value}`
              }
            }
          )
          if (postSupport.data.status === 'ok') {
            modalIsOpen.value = true
            window.scrollTo(0, 0)
            v$.value.$reset()
            userFormData.filial_id = null
            userFormData.is_filials = false
            userFormData.is_terms = false
            userFormData.is_rules = false
            userFormData.inn = ''
            userFormData.phone = ''
            userFormData.email = ''
            userFormData.is_one_person = true
            userFormData.is_agreement = false
            userFormData.is_copy_agreement = false
            userFormData.patient_name = ''
            userFormData.patient_birthday = ''
            userFormData.relation = ''
            userFormData.tax_year = []
            userFormData.file = ''
          }
        } catch (err) {
          error.value = err
          console.log(err)
        }
      }
    }

    return {
      filteredFilials,
      filteredYears,
      selectedFilials,
      selectFilial,
      selectYear,
      deselectClinic,
      selectedYears,
      deselectYears,
      nowYear,
      years,
      userFormData,
      userBirthday,
      userPhone,
      userEmail,
      handleFilesUpload,
      v$,
      submitForm,
      modalIsOpen,
      error
    }
  }
}
</script>

<style scoped lang="scss">
.alert-msg {
  position: relative;
  display: flex;
  align-items: start;
  gap: 12px;
  border-radius: 5px;
  background: $blue-extra-light;
  padding: 16px 32px 16px 14px;
  color: $blue;
  margin-bottom: 16px;
}
.alert-msg__picture {
  margin-top: 4px;
}
.alert-msg__text {
  font-size: 13px;
  line-height: 18px;
}

.support__file-label {
  position: relative;
  max-width: 100%;
  margin: 14px 0;

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    color: #002856;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.26px;
    text-transform: uppercase;
    border-radius: 30px;
    border: 1px solid #002856;
    padding: 10px;
    display: block;
    width: 100%;
  }
  p {
    margin-bottom: 4px;
  }
}

.patient-form__text {
  text-align: center;
  color: #002856;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;

  p:not(:last-child) {
    margin-bottom: 5px;
  }

  strong {
    font-weight: 700;
  }
}

.patient-form {
  padding-top: 10px;
  color: $blue;
  h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    margin-bottom: 16px;
    + p {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      margin-bottom: 16px;
    }
  }
  h3 {
    color: #002856;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 1px;
  }

  label {
    color: #002856;
    font-size: 14px;
    font-weight: 350;
    line-height: 14px;
    text-align: left;
  }

  small {
    color: #ed3319;
  }

  .form-group__radio {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  form {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .row {
    row-gap: 6px;
  }
  .form-control {
    padding: 10px 12px;
    border-color: $blue-light;
    font-size: 14px;
    font-weight: 350;
    line-height: 14px;
    text-align: left;
    color: #002856;
    border: 1px solid #bac7de;
    border-radius: 8px;
    &:focus {
      box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0.25);
    }
  }
  &__radio {
    display: flex;
    align-items: center;
    gap: 16px 8px;
    line-height: 18px;
  }
  &__clinic {
    margin-bottom: 16px;
    .form-group {
      margin-bottom: 4px;
    }
    .clinics {
      padding: 4px 0 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;
      &-item {
        display: inline-flex;
        align-items: center;
        padding: 10px 12px;
        gap: 10px;
        border-radius: 17px;
        border: 1px solid $blue-light;
        background: $blue-extra-light;
        color: #323232;
        font-size: 14px;
        line-height: 1;
        button {
          padding: 0;
          border: none;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.patient-form__switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 6px;
  &-body {
    width: calc(100% - 52px);
  }
  p {
    color: #002856;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.modal-text--success {
  color: #062e4f;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;

  span {
    display: block;
    color: #002856;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 400;
  }
}

.form-group.arrow {
  position: relative;
}
.form-group.arrow-patient {
  position: relative;
}

.form-group.arrow:before {
  position: absolute;
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/uploads/icon/common/arrow-down.svg');
  background-repeat: no-repeat;
  top: 50%;
  right: 7px;
  background-position: center;
  transform: translateY(-50%);
}

.form-group.arrow-patient:before {
  position: absolute;
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/uploads/icon/common/arrow-down.svg');
  background-repeat: no-repeat;
  top: 50%;
  right: 20px;
  background-position: center;
  transform: translateY(-50%);
}

.checkbox-wrapper {
  margin-bottom: 6px;
}
.clinics-help-block {
  font-size: 12px;
  line-height: 12px;
}
</style>
<style>
.ndfl-checkbox .checkbox__rules {
  margin-bottom: 6px;
}
</style>
