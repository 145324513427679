<script>
import {
  ref,
  reactive,
  watch,
  toRefs,
  computed,
  onMounted,
  nextTick
} from 'vue'
import TheText from '@/components/ui/typography/TheText.vue'
import MenuItem from '@/components/layout/menu/MenuItem.vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import WhiteBlockRounded from '@/components/ui/WhiteBlockRounded.vue'
import UpdateAppBanner from '@/components/pages/home/UpdateAppBanner.vue'
import { useUpdateBanner } from '@/composables/useUpdateBanner'
import { useAppInfoStore } from '@/stores/AppInfoStore'
import PatientFormBanner from '@/components/pages/patient-form/PatientFormBanner.vue'
import { useMenuStore } from '@/stores/MenuStore'
import { getPlatform } from '@/utilits/getPlatform'

export default {
  name: 'TheMenu',
  components: {
    PatientFormBanner,
    UpdateAppBanner,
    WhiteBlockRounded,
    MenuItem,
    TheText
  },
  emits: ['toggleMenu', 'close'],
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  setup (props, { emit }) {
    const menuStore = useMenuStore()
    const { setMenuHeight } = menuStore
    const { show } = toRefs(props)
    const userStore = useUserStore()
    const { userToken } = storeToRefs(userStore)
    const menuContainer = ref(null)
    const menuIsActive = ref(false)
    const isCopied = ref(false)
    const { user } = storeToRefs(userStore)
    const scrollY = ref(false)
    const { bannerUpdateIsOpen, bannerLink } = useUpdateBanner()
    const appStore = useAppInfoStore()
    const { appInfo } = storeToRefs(appStore)
    const platform = getPlatform()
    const appVersionIos = process.env.VUE_APP_IOS_VERSION
    const appVersionAndroid = process.env.VUE_APP_ANDROID_VERSION

    const hideNdfl = computed(() => {
      return appInfo.value?.is_form_ndfl !== 1
    })

    const bannerPatientVisible = computed(() => {
      if (!user.value) return false
      if (!appInfo.value) return false

      return (
        Boolean(user.value.is_form_patient) &&
        Boolean(appInfo.value.is_banner_patient)
      )
    })

    const hideCallHome = computed(() => {
      return appInfo.value?.is_form_home !== 1
    })

    const hideParking = computed(() => {
      return appInfo.value?.is_parking !== 1
    })

    onMounted(async () => {
      const onScroll = () => {
        scrollY.value = window.scrollY > 100
      }
      window.addEventListener('scroll', onScroll)
    })
    const containerMenuHeight = ref('auto')
    watch(
      show,
      async () => {
        if (menuContainer.value) {
          containerMenuHeight.value = 'auto'
          await nextTick()
          let height = menuContainer.value.getBoundingClientRect().height
          if (window.scrollY > 0) {
            height += 32
          }

          setMenuHeight(height)

          if (height < 920) {
            containerMenuHeight.value = '100%'
          }

          if (show.value) {
            window.scrollBy(0, -10000)
          }
        }
      },
      {
        flush: 'post'
      }
    )

    const isAdmin = computed(() => {
      if (!user.value) return true
      return user.value.is_admin
    })

    const isLocked = computed(() => {
      if (!userToken.value) return true
      return !userToken.value
    })

    const sidebarLinks1 = reactive([
      {
        imgName: 'home-page',
        title: 'Главная',
        pathName: '/',
        hidden: false,
        hiddenOnMobile: true
      },

      {
        imgName: 'appointment',
        title: 'Запись на прием',
        pathName: '/online-appointment',
        hidden: false,
        locked: isLocked
      },
      {
        imgName: 'telemed',
        title: 'Телемедицина',
        pathName: '/agreement-appointment',
        hidden: false,
        locked: isLocked
      },
      {
        title: computed(() => {
          if (user.value === undefined || user.value === null) return 'Чат с врачом'
          return user.value.is_doctor ? 'Чаты с пациентами' : 'Чат с врачом'
        }),
        imgName: 'chat',
        pathName: computed(() => {
          if (user.value === undefined || user.value === null) return '#'
          return user.value.is_doctor ? '/chats-for-doctor' : '/chats'
        }),
        hidden: computed(() => {
          return !appInfo.value?.is_doctor_chat
        })
      },
      {
        imgName: 'call-doctor',
        title: 'Вызов врача на дом',
        pathName: '/call-doctor',
        hidden: hideCallHome,
        locked: isLocked
      },
      {
        imgName: 'services',
        title: 'Услуги',
        pathName: '/services',
        hidden: false
      },
      {
        imgName: 'checklist',
        title: 'Подготовка к исследованию',
        pathName: '/preparation',
        hidden: false
      },
      {
        imgName: 'document',
        title: 'Медкарта',
        pathName: '/med-card',
        hidden: false,
        locked: isLocked,
        hiddenOnMobile: true
      },
      {
        imgName: 'doctor',
        title: 'Врачи',
        pathName: '/doctors',
        hidden: false,
        hiddenOnMobile: true
      }
    ])

    const sidebarLinks3 = reactive([
      {
        title: 'Мой профиль',
        imgName: 'profile',
        pathName: '/profile/details',
        hidden: false,
        locked: isLocked
      },
      {
        imgName: 'family',
        title: 'Моя семья',
        pathName: '/profile/family',
        hidden: false,
        locked: isLocked
      },
      {
        imgName: 'favorites',
        title: 'Избранное',
        pathName: '/favorites',
        hidden: false,
        locked: isLocked
      },
      {
        imgName: 'calendar',
        title: 'Календари',
        pathName: '/calendar',
        hidden: false,
        locked: isLocked
      },
      {
        imgName: 'folder',
        title: 'Мои файлы',
        pathName: '/files',
        hidden: false,
        locked: isLocked
      },
      // {
      //   imgName: 'folder',
      //   title: 'Мои устройства',
      //   pathName: '/devices',
      //   hidden: false,
      //   locked: isLocked
      // },
      {
        imgName: 'wallet-filled-money',
        title: 'Финансы',
        pathName: '/finance',
        hidden: false,
        locked: isLocked
      },
      {
        imgName: 'ndfl',
        title: 'Форма возврата НДФЛ',
        pathName: '/tax-form',
        hidden: hideNdfl,
        locked: isLocked
      },
      {
        imgName: 'checklist',
        title: 'Онлайн-регистратура',
        pathName: '/patient-member',
        secondActivePath: '/patient-form-gosuslugi',
        hidden: computed(() => {
          return appInfo.value?.is_form_agreement !== 1
        }),
        locked: isLocked
      }
    ])
    const sidebarLinks4 = computed(() => {
      return [
        {
          title: 'Чат с колл-центром',
          imgName: 'chat-call-center',
          pathName: '/call-center-faq',
          hidden: isLocked.value
        },
        {
          title: 'Ассистент',
          imgName: 'doctor',
          pathName: '/chat-assistant',
          hidden: !isAdmin.value
        },
        {
          imgName: 'support',
          title: 'Техническая поддержка',
          pathName: '/support',
          hidden: false,
          locked: isLocked.value
        },
        {
          title: 'Паркинг',
          imgName: 'parking',
          pathName: '/parking',
          hidden: hideParking,
          locked: isLocked.value
        },
        {
          title: 'Оставить отзыв',
          imgName: 'review',
          pathName: '',
          hidden: false,
          url: 'https://www.k31.ru/about/review/rate/?utm_medium=lk',
          locked: isLocked.value
        },
        {
          title: 'Написать в Whatsapp',
          imgName: 'whats-app',
          pathName: '#',
          hidden: false,
          whatsApp: true
        }
      ]
    })

    const toggleMenu = () => {
      emit('toggleMenu')
    }
    const logOut = () => {
      userStore.logOut()
    }
    const getCardsLength = computed(() => {
      if (!user.value) return null

      return user.value.family_cards.length
    })

    const copyContent = async (message) => {
      try {
        await navigator.clipboard.writeText(message)
        isCopied.value = true
      } catch (err) {
        throw new Error(err)
      }
    }

    return {
      menuIsActive,
      toggleMenu,
      sidebarLinks1,
      sidebarLinks3,
      sidebarLinks4,
      userToken,
      user,
      getCardsLength,
      logOut,
      scrollY,
      isAdmin,
      bannerUpdateIsOpen,
      bannerLink,
      bannerPatientVisible,
      menuContainer,
      containerMenuHeight,
      appInfo,
      copyContent,
      platform,
      appVersionIos,
      appVersionAndroid
    }
  }
}
</script>

<template>
  <Transition name="modal">
    <div v-show="show" ref="menuContainer" class="modal-mask">
      <div class="menu__title" :class="{ 'menu__title--fixed': scrollY }">
        <div class="menu__back" @click="$emit('close')">
          <img
            src="@/assets/uploads/icon/common/arrow-back.svg"
            alt="стрелка"
          />
        </div>
        <h3>Меню</h3>
        <WhiteBlockRounded v-show="scrollY" />
      </div>

      <div class="modal-container">
        <div class="modal-header">
          <TheText v-if="!userToken">
            Часть функционала доступна только после входа
          </TheText>
        </div>

        <div class="modal-body">
          <slot name="body">
            <div class="menu__user" v-if="user">
              <div class="menu__user-info">
                <div class="menu__user-name">
                  <p>{{ user.last_name }} {{ user.first_name }}</p>
                  <span v-if="user.card">Медкарта {{ user.card }} </span>
                </div>
              </div>
              <a class="menu__user-logout" @click.prevent="logOut">
                Выйти
                <img src="@/assets/uploads/images/sidebar/logout.svg" alt="" />
              </a>
            </div>

            <UpdateAppBanner
              v-if="bannerUpdateIsOpen"
              :bannerLink="bannerLink"
              @closeBanner="bannerUpdateIsOpen = false"
            />

            <PatientFormBanner
              v-if="bannerPatientVisible"
              @click="$emit('close')"
            />

            <ul class="menu__list">
              <template v-for="link in sidebarLinks1" :key="link.title">
                <li
                  v-if="!link.hidden"
                  class="menu__item"
                  @click="$emit('close')"
                  :class="{ hidden: link.hiddenOnMobile }"
                >
                  <MenuItem
                    :img-name="link.imgName"
                    :path-name="link.pathName"
                    :locked="link.locked"
                    :title="link.title"
                  />
                </li>
              </template>
            </ul>

            <ul class="menu__list">
              <template v-for="link in sidebarLinks3" :key="link.title">
                <li
                  v-if="!link.hidden"
                  class="menu__item"
                  @click="$emit('close')"
                  :class="{ hidden: link.hiddenOnMobile }"
                >
                  <MenuItem
                    :whatsApp="link.whatsApp"
                    :img-name="link.imgName"
                    :path-name="link.pathName"
                    :locked="link.locked"
                    :title="link.title"
                    :url="link.url"
                  />
                </li>
              </template>
            </ul>
            <div class="menu__offers" @click="$emit('close')">
              <div class="menu__offer">
                <router-link to="/offers" class="menu__offer-wrapepr">
                  <h5>Акции</h5>
                  <img
                    src="@/assets/uploads/images/sidebar/sales.png"
                    alt="Акции"
                  />
                </router-link>
              </div>
              <router-link to="/program/all" class="menu__programm">
                Комплексные программы
              </router-link>
            </div>
            <ul class="menu__list">
              <template v-for="link in sidebarLinks4" :key="link.title">
                <li
                  v-if="!link.hidden "
                  class="menu__item"
                  @click="$emit('close')"
                  :class="{ hidden: link.hiddenOnMobile }"
                >
                  <MenuItem
                    :whatsApp="link.whatsApp"
                    :img-name="link.imgName"
                    :path-name="link.pathName"
                    :locked="link.locked"
                    :title="link.title"
                    :url="link.url"
                  >
                  </MenuItem>
                </li>
              </template>
            </ul>
            <div
              class="menu__version"
              v-if="appInfo && (platform === 'ios' || platform === 'android')"
            >
              <p v-if="platform === 'ios'">
                Версия приложения {{ appVersionIos }} {{ platform }}
              </p>
              <p v-if="platform === 'android'">
                Версия приложения {{ appVersionAndroid }} {{ platform }}
              </p>
              <div
                class="menu__version-copy"
                @click="copyContent(`${appInfo.ios_version} ${platform}`)"
              >
                <img src="@/assets/uploads/icon/copy.svg" alt="знак ссылки" />
              </div>
            </div>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
.menu__item.hidden {
  display: none;
  @include desktop {
    display: block;
  }
}

.menu__user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  @include desktop {
    margin-bottom: 12px;
  }
}

.menu__version {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  p {
    color: #7f8da9;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

.menu__version-copy {
  cursor: pointer;
}

.menu__user-name {
  color: $blue;
  font-size: 18px;
  font-weight: 700;

  & p {
    margin-bottom: 2px;
  }

  & span {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}

.menu__user-cards {
  color: #002856;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.28px;
  text-decoration: underline !important;
}

.menu__user-logout {
  position: relative;
  align-self: start;
  cursor: pointer;
  color: #002856;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.28px;
  @include desktop {
    //display: none;
  }

  img {
    margin-left: 8px;
  }

  &:before {
    position: absolute;
    display: block;
    content: "";
    left: -20%;
    top: -20%;
    width: 120%;
    height: 200%;
  }
}

.menu__title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  padding-bottom: 8px;
  background: #082b53;
  transition: all 0.2s linear;
  @media (max-width: 991px) {
    &.menu__title--fixed {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 100;
      background: #082b53;
      padding-top: 10px;
      padding-bottom: 10px;
      left: 0;

      h3 {
        color: #fff;
      }

      .arrow-title__img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
          brightness(102%) contrast(102%);
      }

      .arrow-title {
        margin-bottom: 0;
      }
    }
  }
  @include desktop {
    display: none;
  }

  & h3 {
    margin-right: 25px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.36px;
  }
}

.menu__list {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &:not(:last-child) {
    margin-bottom: 20px;
    @include desktop {
      margin-bottom: 12px;
    }
  }

  @include desktop {
    gap: 8px;
  }
}

.menu__offers {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
  @include desktop {
    margin-bottom: 12px;
  }
}

.menu__offer {
  flex: 1 1 45%;
  border-radius: 6px;
  border: 1px solid #f1842c;
  padding: 4px;
}

.menu__offer-wrapepr {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  padding: 6px 10px;
  border-radius: 6px;
  background: #bac7de;

  color: $blue;
  font-family: Circe;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.32px;
}

.menu__programm {
  color: $blue;
  font-family: Circe;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.32px;
  padding: 10px 16px;
  border-radius: 6px;
  background: #bac7de;
  flex: 1 1 45%;
  background: linear-gradient(125deg, #e1e8f4 0%, #bfcde3 100%);
}

.menu__back {
  & img {
    padding-left: 15px;
    filter: invert(97%) sepia(1%) saturate(501%) hue-rotate(197deg)
      brightness(116%) contrast(100%);
  }
}

.modal-mask {
  position: absolute;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 100%;
  height: v-bind(containerMenuHeight);
  display: flex;
  // transition: opacity 0.3s ease;
  flex-direction: column;
  @include desktop {
    height: 100%;
    display: flex;
    position: relative;
    grid-column: 1;
    grid-row: 1/4;
  }

  &:before {
    position: absolute;
    display: none;
    content: "";
    width: 100%;
    height: 100%;
    background: white;
    top: 50%;
    left: 0;

    @media (max-width: 991px) {
      display: block;
    }
  }
}

.modal-container {
  //flex: 1 1 auto;
  height: 95%;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  @include desktop {
    position: sticky;
    top: 15px;
    height: auto;
    border-radius: 8px;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: translateY(100vh);
  transform: translateY(100vh);
}
</style>
