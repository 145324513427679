<template>
  <router-link
    @click="goToNotify"
    to="/notifications"
    class="notification-bell"
  >
    <div class="notification-bell__counter" v-if="notifyCountLocal">
      {{ notifyCountLocal }}
    </div>
    <IconInCircle :bg-color="colorNotify" width="32" height="32">
      <img
        src="@/assets/uploads/images/header/bell-notify.svg"
        alt="картинка колокольчика"
      />
    </IconInCircle>
  </router-link>
</template>

<script>
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import IconInCircle from '@/components/ui/IconInCircle.vue'
import { computed } from 'vue'
import { useNotifyStore } from '@/stores/NotifyStore'
import { useRoute } from 'vue-router'

export default {
  name: 'NotificationBell',
  components: { IconInCircle },
  setup () {
    const route = useRoute()
    const notifyStore = useNotifyStore()
    const { notificationList, fromNotifyView, notifyCount } = storeToRefs(notifyStore)
    const userStore = useUserStore()
    const { userToken } = storeToRefs(userStore)

    const notifyCountLocal = computed(() => {
      const notifyNative = notificationList.value.filter(
        (notify) => !notify.wasChecked
      ).length
      return notifyCount.value + notifyNative
    })

    const colorNotify = computed(() => {
      if (notifyCountLocal.value) return '#F1842C'

      return '#E1E8F4'
    })

    const goToNotify = () => {
      notifyCount.value = null
      if (route.name === 'notifications' && notifyCountLocal.value) {
        fromNotifyView.value = true
      }
    }

    return {
      userToken,
      colorNotify,
      notifyCountLocal,
      goToNotify
    }
  }
}
</script>

<style scoped lang="scss">
.notification-bell {
  position: relative;
  width: 32px;
  height: 32px;
  display: block;

  &__counter {
    right: -3px;
    top: -4px;
    width: 14px;
    height: 14px;
    font-family: "Open Sans", serif;
    position: absolute;
    background: #d60000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
  }
}
</style>
