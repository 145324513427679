import { authenticatedApiCall } from '@/api/authenticatedApiCall.js'

export const uploadFilesTelemed = async (reserveId, data) => {
  const url = '/v1/user-file/attach'

  const params = { rec_id: reserveId }

  const response = await authenticatedApiCall({ url, params, data, method: 'post' })

  if (response.data.status === 'ok') {
    return response.data.message
  } else {
    throw new Error(response.data.message)
  }
}
