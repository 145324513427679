<template>
  <div class="pop-services">
    <div
      class="pop-services__wrapper"
      :class="{ 'pop-services__wrapper-expanded': areSettingsOpen }"
    >
      <TheLoader class="pop-services__loader" v-if="areServicesLoading">
        Загрузка ваших сервисов
      </TheLoader>
      <div v-else>
        <div class="pop-services__header">
          <h3>Популярные сервисы</h3>
          <Transition name="gear">
            <img
              v-if="!areSettingsOpen && getUserId"
              @click="openSettings"
              class="pop-services__header-configure-btn"
              src="@/assets/uploads/icon/common/gear-icon.svg"
              alt="настроить"
            />
          </Transition>
          <Transition name="cross">
            <img
              v-if="areSettingsOpen && getUserId"
              @click="$emit('closeSettings')"
              class="pop-services__header-configure-btn"
              src="@/assets/uploads/icon/common/cross-icon.svg"
              alt="настроить"
            />
          </Transition>
        </div>

        <Swiper
          v-if="!areSettingsOpen"
          v-bind="swiperOptions"
          class=".swiper-pop-services"
        >
          <swiper-slide
            class="pop-services__swiper-slide"
            v-for="service in enabledServices"
            :key="service.id"
          >
            <li
              class="pop-services__item"
            >
              <PopularServiceIcon
                @click="navigateToService(service.router_url)"
                :bg-color="colors[service.preset]"
                :isPermanent="service.is_permanent"
                class="pop-services__item-icon-wrapper"
                width="64"
                height="64"
              >
                <img
                  class="pop-services__item-type-icon"
                  :src="
                    require(`@/assets/uploads/images/home-page/popular-services/${service.icon_code}.svg`)
                  "
                  :alt="service.title"
                />
              </PopularServiceIcon>
              <div class="pop-services__text" @click="navigateToService(service.router_url)">
                <p>{{ service.title }}</p>
              </div>
            </li>
          </swiper-slide>
          <div class="pop-services__swiper-pagination"></div>
        </Swiper>
        <Transition name="settings">
        <draggable
          v-if="areSettingsOpen"
          ref="settings"
          v-bind="dragOptions"
          :list="enabledServices"
          :swapThreshold="1"
          item-key="id"
          class="pop-services__settings-list"
          ghost-class="ghost"
          @start="dragging = true"
          @end="dragging = false"
        >
          <template #header>
            <div
              class="pop-services__item pop-services__open-modal-btn"
              @click="$emit('openModal')"
            >
              <IconInCircle
                width="64"
                height="64"
                bgColor="#FFFF"
                class="pop-services__add-btn"
              >
                <img
                  src="@/assets/uploads/icon/common/plus-sign.svg"
                  alt="добавить сервис"
                />
              </IconInCircle>
              <div class="pop-services__text">
                <p>Добавление функционала</p>
              </div>
            </div>
            <div
              v-for="element in enabledServices.slice(0, 2)"
              :key="element.id"
              class="pop-services__item"
            >
              <IconInCircle
                :bg-color="colors[element.preset]"
                class="pop-services__item-icon-wrapper"
                width="64"
                height="64"
              >
                <img
                  class="pop-services__item-type-icon"
                  :src="
                    require(`@/assets/uploads/images/home-page/popular-services/${element.icon_code}.svg`)
                  "
                  :alt="element.title"
                />
              </IconInCircle>
              <div class="pop-services__text">
                <p>{{ element.title }}</p>
              </div>
            </div>
          </template>
          <template #item="{ element, index }">

            <div
              v-if="index > 1"
              class="pop-services__item"
              :class="{
                'pop-services__item-delete': serviceToDeleteId === element.id,
              }"
            >
              <IconInCircle
                :bg-color="colors[element.preset]"
                class="pop-services__item-icon-wrapper"
                width="64"
                height="64"
              >
                <img
                  v-if="!element.is_permanent"
                  @click="removeService(element.id)"
                  class="pop-services__item-remove-icon pop-services__item-remove-icon--active"
                  src="@/assets/uploads/icon/common/remove-service-icon.svg"
                  alt="удалить"
                />
                <img
                  class="pop-services__item-type-icon"
                  :src="
                    require(`@/assets/uploads/images/home-page/popular-services/${element.icon_code}.svg`)
                  "
                  :alt="element.title"
                />
              </IconInCircle>
              <div class="pop-services__text">
                <p>{{ element.title }}</p>
              </div>
            </div>
          </template>

        </draggable>
      </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination } from 'swiper'
import PopularServiceIcon from '@/components/pages/home/PopularServiceIcon.vue'
import { ref, computed, nextTick } from 'vue'
import router from '@/router'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import IconInCircle from '@/components/ui/IconInCircle.vue'
import draggable from 'vuedraggable'

export default {
  name: 'PopularServicesSection',
  components: {
    PopularServiceIcon,
    IconInCircle,
    Swiper,
    SwiperSlide,
    TheLoader,
    draggable
  },
  props: {
    areSettingsOpen: Boolean,
    areServicesLoading: Boolean,
    enabledServices: {
      type: [Object, Array, null],
      required: true
    }
  },
  emits: [
    'activateRemoveMode',
    'openModal',
    'deleteOneService',
    'saveSorting',
    'openSettings',
    'closeSettings'
  ],
  setup (props, { emit }) {
    const userStore = useUserStore()
    const { getUserId } = storeToRefs(userStore)
    const serviceToDeleteId = ref(null)
    const settings = ref(null)
    const colors = {
      blue: '#EFF4FD',
      beige: '#FBE1CD',
      yellow: '#F5FBCD',
      mint: '#E4FCE6',
      green: '#E4FCE6',
      orange: '#F1842C',
      oasis: '#FFEDC5',
      pink: '#FBCDCD',
      purple: '#F5E6FC',
      gray: '#E4E4E4'
    }

    const dragOptions = computed(() => ({
      animation: 200,
      disabled: false,
      ghostClass: 'ghost'
    }))

    const swiperOptions = ref({
      initialSlide: 0,
      spaceBetween: 16,
      pagination: {
        el: '.pop-services__swiper-pagination',
        clickable: true
      },
      breakpoints: {
        0: {
          slidesPerView: 4,
          slidesPerGroup: 4
        },
        768: {
          slidesPerView: 5
        }
      },
      modules: [Pagination]
    })

    const navigateToService = (path) => {
      router.push({ name: path.substring(1) })
    }

    const openSettings = async () => {
      emit('openSettings')
      await nextTick()
      settings.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    const removeService = (id) => {
      // serviceToDeleteId.value = id
      emit('deleteOneService', id)
    }

    return {
      navigateToService,
      removeService,
      openSettings,
      serviceToDeleteId,
      settings,
      swiperOptions,
      dragOptions,
      getUserId,
      colors
    }
  }
}
</script>

<style scoped lang="scss">
.pop-services {
  margin-bottom: 16px;
  height: fit-content;
}

.pop-services__wrapper-expanded {
  height: 561px !important;
  @media (min-width: 765px) {
    height: 420px !important;
  }
}

.pop-services__wrapper {
  background-color: #FFF;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 16px 8px 0 8px;
  border-radius: 8px;
  height: 208px;
  transition: height 0.35s;
}

.pop-services__header {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #002856;
  display: flex;
  justify-content: space-between;
}

.pop-services__link {
}

.pop-services__item {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pop-services__item-delete {
  transition: all 0.25s;
  transform: scale(0);
  opacity: 0;
}

.pop-services__text {
  margin-top: 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  text-align: center;
  color: #002856;
}

.pop-services__swiper-slide {
  margin-bottom: 20px;
  transition: all 0.3s;
}

.pop-services__swiper-pagination {
  margin-bottom: 12px;
  text-align: center;
}
.pop-services__item-remove-icon {
  position: absolute;
  transform: translateY(-100%);
  right: 0;
  &--active {
    display: block;
  }
  &--disabled {
    display: none;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.pop-services__loader {
  padding-bottom: 16px;
}
.pop-services__item-icon-wrapper {
  cursor: pointer;
}
.pop-services__header-configure-btn {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.pop-services__add-btn {
  border: 1px solid #002856;
}
.pop-services__settings-list {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(82.25px, 1fr));
  padding-bottom: 33px;
}

.pop-services__item-icon-wrapper {
  position: relative;
}

.pop-services__open-modal-btn:hover {
  cursor: pointer;

  .icon-in-circle {
    background-color: #002856 !important;
  }
  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
      hue-rotate(109deg) brightness(103%) contrast(104%);
  }
}

.gear-enter-active,
.gear-leave-active {
  transition: all 0.15s ease-in;
}

.gear-enter-from,
.gear-leave-to {
  transform: translateY(200%);
  opacity: 0;
}

.cross-enter-active,
.cross-leave-active {
  transition: all 0.15s ease-in;
}

.cross-enter-from,
.cross-leave-to {
  transform: translateY(-200%);
  opacity: 0;
}

.settingsList-enter-active,
.settingsList-leave-active {
  transition: all 0.5s ease;
}
.settingsList-enter-from,
.settingsList-leave-to {
  opacity: 0;
}

.ghost {
  opacity: 0;
}

.settings-enter-active,
.settings-leave-active {
  transition: all 0.15s ease-in;
}

.settings-enter-from,
.settings-leave-to {
  transform: scale(0%);
  opacity: 0;
}

</style>
